<template>
  <header>
    <!-- TOP HEADER -->
    <div class="top-header">
      <div class="flex flex-row justify-between px-2 md:px-20">
        <div class="contenedor">
          <router-link to="/">
            <img
              src="@/assets/img/home/logo.png"
              alt="logo credil"
              class=" h-10 md:h-12 my-5"
            />
          </router-link>
        </div>
        <div class="contenedor my-auto  ">
          <a
            href="https://credilenlinea.web.app/login"
            target="_blank"
            class="inline-block bg-blue-700 rounded-md rounded-bl-none font-bold text-white px-4 md:px-8 py-2  hover:bg-blue-600 transition duration-700 ease-in-out"
            >Banca en Línea</a
          >
        </div>
      </div>
    </div>

    <!-- MENU -->
    <nav
      class="flex items-center justify-between flex-wrap p-2 m-0 sm:px-20"
      style="background-color: #0a5595; position:relative; z-index:10;"
    >
      <div class="block lg:hidden">
        <button
          id="boton"
          @click="menu"
          class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        id="menu"
        class="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden"
      >
        <div class="text-sm lg:flex-grow flex justify-around">
          <router-link
            to="/"
            class="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400  mr-4"
          >
            <img
              src="@/assets/img/home/home.png"
              alt="home"
              class="w-5 h-5 hover:opacity-50"
            />
          </router-link>
          <drop-nosotros />
          <mega-menu />
          <drop-simuladores />
          <router-link
            to="/contactos"
            class="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400  mr-4"
          >
            Contactos
          </router-link>
        </div>
      </div>
      <div class="contenedor-redes flex">
        <p>
          <a href="https://www.facebook.com/coac.credil.ltda/" target="_blank">
            <img
              src="@/assets/img/home/social/facebook.png"
              alt="facebook"
              class="w-5 h-5 hover:opacity-50"
            />
          </a>
        </p>
        <p>
          <a
            href="https://www.youtube.com/channel/UC6yALcY1GChgv6S7lIPPOEQ"
            target="_blank"
            ><img
              src="@/assets/img/home/social/youtube.png"
              alt="youtube"
              class="w-5 h-5 hover:opacity-50"
          /></a>
        </p>
        <!-- <p>
          <a
            href="https://twitter.com/CoopCrediYa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            ><font-awesome-icon :icon="['fab', 'twitter']"
          /></a>
        </p>
        <p>
          <a href="https://whatsapp.com/" target="_blank"
            ><font-awesome-icon :icon="['fab', 'whatsapp']"
          /></a>
        </p> -->
        <p>
          <a href="https://www.instagram.com/coop_credil_ltda" target="_blank"
            ><img
              src="@/assets/img/home/social/instagram.png"
              alt="instagram"
              class="w-5 h-5 hover:opacity-50"
          /></a>
        </p>
      </div>
    </nav>
  </header>
</template>

<script>
import Popper from "popper.js";
import MegaMenu from "./menu/MegaMenu.vue";
import DropNosotros from "./menu/DropNosotros.vue";
import DropSimuladores from "./menu/DropSimuladores.vue";

export default {
  components: {
    MegaMenu,
    DropNosotros,
    DropSimuladores
  },
  name: "HeaderC",
  data: () => ({
    isOpen: false,
    dropdownPopoverShow: false
  }),
  methods: {
    menu() {
      console.log("dio click");
      const menu = document.querySelector("#menu");
      menu.classList.toggle("hidden");
    },
    toggleDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    }
  }
};
</script>

<style scoped>
/******************** HEADER *******************/
/* TOP HEADER */
.top-header {
  background-color: #fff;
}
.top-header p,
.top-header a {
  color: #f6e6e6;
}

.contenedor-redes p {
  font-size: 0.85rem;
  margin: 10px 20px;
  color: white;
}
@media screen and (max-width: 690px) {
  .top-header p {
    margin: 5px;
  }
  .contenedor-info {
    justify-content: space-around;
  }
  .contenedor-redes {
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-bottom: 10px;
  }
}
</style>
