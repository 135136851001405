<template>
  <div style="width:100%;height:500px">
    <slider ref="slider" :options="options" @slide="slide">
      <!-- SLIDE 1 -->
      <slideritem
        class="bg-blue-600 banner-slider"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/home/banner2.jpg') + ')'
        }"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="show"
            class="animar container w-10/12 md:w-1/3 overflow-hidden p-3"
          >
            <h2
              class="text-white text-left flex-wrap font-semibold leading-10 mb-5"
            >
              Seguridad y Confianza
              <br />
              con Nosotros
            </h2>
            <p class="text-white text-sm whitespace-normal text-justify">
              Somos una entidad financiera con 13 años de experiencia en el
              Ecuador, contamos con el respaldo de nuestros Socios y somos
              regulados por todas las entidades reguladoras.
            </p>
          </div>
        </transition>
      </slideritem>

      <!-- SLIDE 2 -->
      <slideritem
        class="bg-blue-600 banner-slider"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/home/banner1.jpg') + ')'
        }"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="show2"
            class="animar container w-10/12 md:w-1/3 overflow-hidden p-3"
          >
            <h2 class="text-white text-left font-semibold leading-10 mb-5">
              En DIOS
              <br />
              está nuestra Fortaleza
            </h2>
            <p class="text-white text-sm whitespace-normal text-justify">
              Crecemos junto a nuestros Socios, haciendo sus sueños posibles.
            </p>
          </div>
        </transition>
      </slideritem>
    </slider>
  </div>
</template>
<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  data() {
    return {
      // someList
      someList: [],
      options: {
        effect: "fade",
        currentPage: 0,
        thresholdDistance: 100,
        thresholdTime: 300,
        speed: 300,
        timingFunction: "ease",
        loop: true,
        autoplay: 6000
      },
      show: false,
      show2: false
    };
  },

  components: {
    slider,
    slideritem
  },
  methods: {
    slide(data) {
      if (data.currentPage == 0) {
        this.show = true;
        this.show2 = false;
      }
      if (data.currentPage == 1) {
        this.show = false;
        this.show2 = true;
      }

      // else {
      // }
    }
  }
};
</script>

<style>
.banner-slider {
  background-position: center;
  background-size: contain;
}

/* animar */
.animar {
  animation-name: derecha;
  animation-duration: 4s;
  transform: translateX(-60%);
}
@media screen and (max-width: 690px) {
  .animar {
    transform: translateX(0);
    animation: none;
  }
}
@keyframes derecha {
  0% {
    transform: translateX(-70%);
    opacity: 0;
  }
  100% {
    transform: translateX(-60%);
    opacity: 1;
  }
}
</style>
