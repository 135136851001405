<template>
  <main
    id="bkg"
    :style="{
      'background-image':
        'url(' + require('@/assets/img/formularios/fondoFORMULARIO.jpg') + ')'
    }"
  >
    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <div class="mx-auto" data-aos="fade-up" data-aos-duration="1000">
        <h4 class="titulo text-white font-bold block text-center">
          Invierte con los mejores
        </h4>

        <p
          class="text-white  text-center text-sm w-full md:w-3/4 mx-auto mb-10"
        >
          Obtén hasta el 11% de interés en tus inversiones.
        </p>
      </div>

      <div
        class="container mx-auto w-full md:w-1/2  bg-white "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="col-span-2 md:col-span-1">
          <img src="@/assets/img/home/logo.png" class="imgcss bg-white logo" />
        </div>
      </div>
      <!-- FORMULARIO -->
      <div
        class="container mx-auto w-full md:w-1/2  bg-blue rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <form class="p-4 md:p-6">
          <div class="  grid grid-cols-1">
            <label for="names" class="text-white text-2xl font-semibold"
              >Inversiones</label
            >
          </div>
          <!-- col 1 -->
          <div class="  grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <label for="names" class="text-white  block"
                >Nombres y Apellidos</label
              >
              <input
                type="text"
                id="names"
                v-model="names"
                placeholder="Nombres Completos"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="phone" class="text-white  block"
                >Cédula de identidad</label
              >
              <input
                type="text"
                v-model="identification"
                id="cedula"
                placeholder="0600012312"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
          </div>

          <!-- col 2 -->
          <div class=" grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-white  block"
                >Correo Electrónico
              </label>
              <input
                type="email"
                v-model="email"
                id="email"
                placeholder="email@email.com"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white  block"
                >Teléfono Celular</label
              >
              <input
                type="text"
                v-model="phone"
                id="phone"
                placeholder="0991234567"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
          </div>

          <!--col 4 -->
          <div class="grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-white  block">
                Provincia
              </label>

              <select
                v-model="province"
                @change="onChangeParroquia($event)"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccione Provincia</option>
                <option
                  :value="pro.provincia"
                  v-for="pro in provincias_totales"
                  :key="pro.provincia"
                  >{{ pro.provincia }}</option
                >
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white  block">
                Canton
              </label>
              <select
                v-model="parish"
                @change="onChangeCiudad($event)"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccione un cantón</option>
                <option
                  :value="cant.canton"
                  v-for="cant in cantones_totales"
                  :key="cant.canton"
                  >{{ cant.canton }}</option
                >
              </select>
            </div>
          </div>

          <!-- col 5 -->
          <div class="grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white  block">
                Ciudad
              </label>
              <select
                v-model="city"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccionar una Ciudad</option>
                <option
                  :value="ciudad.parroquia"
                  v-for="ciudad in ciudades_totales"
                  :key="ciudad.parroquia"
                  >{{ ciudad.parroquia }}</option
                >
              </select>
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white  block">
                Agencia
              </label>
              <select
                v-model="agencyNear"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                aria-label="Default select example"
              >
                <option selected>Agencia</option>
                <option value="salcedo">Matriz Salcedo</option>
                <option value="izamba">Oficina Izamba</option>
                <option value="ambato-centro">Oficina Ambato Centro</option>
                <option value="mercado-mayorista"
                  >Oficina Mercado Mayorista</option
                >
                <option value="riobamba">Oficina Riobamba</option>
                <option value="riobamba">Oficina Machala</option>
                <option value="riobamba">Oficina Pasaje</option>
                <option value="riobamba">Oficina Quito-Cotocollao</option>
                <option value="riobamba">Oficina Piñas</option>
              </select>
            </div>
          </div>

          <!-- col 6 -->
          <div class=" grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />

                <a
                  href="#"
                  class="form-check-label text-white py-1 px-2 rounded w-full mx-2 items-center"
                  @click.prevent="show"
                >
                  Acepto los términos y condiciones
                </a>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <!-- botontes -->
              <div class="mt-8 flex justify-around">
                <router-link
                  to="/solicitudes"
                  class="bg-gray-500 hover:bg-gray-600 text-white px-6 py-1 rounded"
                  >Regresar</router-link
                >

                <button
                  type="button"
                  id="guardar"
                  class="bg-gray-500 hover:bg-gray-600 text-white px-6 py-1 rounded"
                  @click="saveInversiones"
                  @keydown.tab.exact.prevent=""
                >
                  Solicitar
                </button>
              </div>
            </div>
          </div>

          <modal
            name="modal-terminos"
            :adaptive="true"
            transition="pop-out"
            :focus-trap="true"
            height="auto"
            :width="700"
            :scrollable="true"
            @opened="opened"
          >
            <div class="px-10 py-6">
              <h3 class="mb-2 text-xl">Acepta los terminos y condiciones</h3>
              <form>
                <div class="form-group mb-2">
                  <p for="nombre" class="block font-normal text-xs mb-1">
                    Autorizo(amos) expresa e irrevocablemente a la Cooperativa
                    de Ahorro y Crédito “Credil Ltda.”, o a quién sea en el
                    futuro el cesionario, beneficiario, o acreedor del crédito
                    solicitado o el documento o título cambiario que lo
                    respalde, para que obtenga cuantas veces sean necesarias de
                    cualquier fuente de información, incluidos los burós de
                    créditos, mi información de riesgos crediticio, de igual
                    forma o a quién sea el futuro cesionario, beneficiario, o
                    acreedor del crédito solicitado o el documento o título
                    cambiario que lo respalde, queda expresamente autorizado
                    para que pueda transferir o entregar dicha información a los
                    burós de crédito y/o a la Central de Riesgos si fuera
                    pertinente.
                  </p>
                </div>
                <div class="form-group mb-2">
                  <p for="lorem" class="block font-normal text-xs mb-1">
                    Declaro que conozco que la información de riesgos
                    crediticios que pudiere ser transferida a los burós de
                    crédito, constará en las bases de datos de dichas entidades
                    por el plazo señalado en las leyes vigentes y servirá para
                    que terceros puedan evaluar mi riesgo crediticio. Declaro
                    que conozco la Ley del Buró de información crediticia, así
                    como los derechos que me ampara.
                  </p>
                </div>
              </form>
            </div>
          </modal>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
import api from "@/api/formularios";
import { provincias } from "@/api/provincias";
import validar_cedula from "@/api/validar_cedula";
import validar_celular from "@/api/validar_celular";
import validar_email from "@/api/validar_email";

export default {
  name: "inversiones",
  data() {
    return {
      names: "",
      identification: "",
      email: "",
      phone: "",
      province: "",
      city: "",
      parish: "",
      agencyNear: "",
      provincias_totales: [],
      cantones_totales: [],
      ciudades_totales: []
    };
  },

  mounted() {
    this.call_provincias();
  },

  methods: {
    // LLAMA A TODAS LAS PROVINCIAS
    call_provincias() {
      this.provincias_totales = provincias;
    },

    // CAMBIA A LA CANTONES
    onChangeParroquia(event) {
      let provincia_selecciona = event.target.value;
      this.provincias_totales.forEach(prov => {
        if (provincia_selecciona == prov.provincia) {
          this.cantones_totales = prov.cantones;
        }
      });
    },

    // CAMBIA A LA CIUDADES
    onChangeCiudad(event) {
      let canton_seleccionado = event.target.value;
      this.cantones_totales.forEach(cant => {
        if (canton_seleccionado == cant.canton) {
          this.ciudades_totales = cant.parroquias;
        }
      });
    },

    show() {
      this.$modal.show("modal-terminos");
    },
    opened() {
      console.log("se abrio");
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    saveInversiones() {
      if (
        this.names == "" ||
        this.identification == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.province == "" ||
        this.city == "" ||
        this.parish == "" ||
        this.agencyNear == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        if (validar_cedula(this.identification)) {
          if (validar_celular(this.phone)) {
            if (validar_email(this.email)) {
              api
                .saveInversiones(
                  this.names,
                  this.identification,
                  this.email,
                  this.phone,
                  this.province,
                  this.city,
                  this.parish,
                  this.agencyNear
                )
                .then(resp => {
                  if (resp.status == "ok") {
                    this.$swal(
                      "Formulario Inversiones Guardado",
                      "",
                      "success"
                    ).then(() => {
                      this.names = "";
                      this.identification = "";
                      this.email = "";
                      this.phone = "";
                      this.province = "";
                      this.city = "";
                      this.parish = "";
                      this.agencyNear = "";
                    });
                  } else {
                    this.$swal("Error", "", "error");
                  }
                });
            } else {
              this.$swal("Email no válido", "", "error");
            }
          } else {
            this.$swal("Celular no válido", "", "error");
          }
        } else {
          this.$swal("Cédula no válida", "", "error");
        }
      }
    }
  }
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.titulo {
  padding-top: 30px;
  font-size: 20pt;
}
.margin {
  margin: 2px;
}
.tituloformularios {
  padding-top: 10px;
}

.logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.imgcss {
  width: auto;
  height: 90px;
}
.bg-blue {
  background-color: #1c4992;
}
</style>
