<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/services/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <div class="contenedor-imagen pt-20">
          <img
            src="@/assets/img/services/iconoPRINCIPAL.png"
            alt="icono simulador"
            class="mx-auto"
            style="height: 200px; width:auto;"
          />
        </div>
        <h2 class="text-white text-3xl font-bold text-center">
          Pago de Servicios
        </h2>
        <p class="text-gray-100 text-center">
          Todos los pagos más cerca y más fácil
        </p>
      </div>
    </div>

    <section class="container py-5 px-4 md:px-32 mx-auto">
      <h2 class="text-center azul rounded py-1 px-5 font-bold text-4xl">
        Beneficios
      </h2>
      <p class="text-center azul mx-2 md:mx-32">
        Ahora puedes realizar todos tus pagos en todas nuestras agencias sin
        complicaciones.
      </p>

      <brands class="mt-8" />

      <div class="grid grid-cols-3 mt-10 gap-4">
        <!-- SERVICE 1 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Servicios Básicos"
            img="iconoBASICOS.png"
            text="AGUA (EMAPA -EPMAPS AGUA QUITO - Etc.),LUZ (ERSA RIOBAMBA - EASA AMBARO - Etc.),TELÉFONO (CNT)"
          />
        </div>

        <!-- SERVICE 2 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Servicios Estatales"
            img="iconoESTATALES.png"
            text="MIES (BONO DE DESARROLLO HUMANO),RISE,SRI,IESS,PRESTAMOS IESS,ANT (AGENCIA DE TRÁNSITO)"
          />
        </div>

        <!-- SERVICE 3 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Catálogos"
            img="iconoCATALOGOS.png"
            text="YANBAL,AVON,BELCORP,NETLIFE,HERBALIFE,PYCCA"
          />
        </div>
      </div>

      <div class="grid grid-cols-3 mt-10 gap-4">
        <!-- SERVICE 4 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Depósitos"
            img="iconoDEPOSITOS.png"
            text="DEPÓSITOS PRODUBANCO,DEPÓSITOS BANCO RUMIÑAHUI,DEPÓSITOS INSOTEC,DEPÓSITOS BANCO PICHINCHA"
          />
        </div>

        <!-- SERVICE 5 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Tarjetas"
            img="iconoTARJETA.png"
            text="PAGO DE TARJETAS BANCO PICHINCHA DINERS/VISA INTERDIN/DISCOVES,PAGO DE TARJETAS PACIFICARD,PAGO TARJETA D'PRATI"
          />
        </div>

        <!-- SERVICE 6 -->
        <div class="col-span-3 md:col-span-1">
          <service
            title="Telefónica"
            img="iconoTELEFONICA.png"
            text="RECARGAS MÓVIL (DIFERENTES OPERADORAS),FACTURAS CLARO,RECARGAS CNT"
          />
        </div>
      </div>

      <div class="grid grid-cols-3 mt-10 gap-4 ">
        <!-- SERVICE 7 -->
        <div class=" col-start-1 md:col-start-2 col-span-3 md:col-span-1">
          <service
            title="ANT"
            img="iconoUNIVERSIDAD.png"
            text="MATRICULA VEHICULAR,TRANSFERENCIA DE DOMINIO,SUPA -PENSIONES ALIMENTICIAS"
          />
        </div>
      </div>
    </section>

    <!-- exterior -->
    <section class="container py-5 px-4 md:px-32 mx-auto">
      <h2 class="text-center azul rounded py-1 px-5 font-bold text-2xl">
        Para depósitos y transferencias desde el exterior
      </h2>
      <p class="text-center azul mx-2 md:mx-32 text-sm">
        Datos que debe proporcionar el socio al corresponsal bancario para el
        depósito o transferencia.
      </p>
      <table class="table-auto border-collapse mx-auto my-5 text-sm">
        <tbody>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              Nombre Banco a depositar o transferir:
            </td>
            <td class="border border-blue-700 p-1">Banco del Austro</td>
          </tr>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              Nombre beneficiario:
            </td>
            <td class="border border-blue-700 p-1">Cooperativa CREDIL Ltda.</td>
          </tr>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              RUC / C.I. Beneficiario:
            </td>
            <td class="border border-blue-700 p-1">1891735002001</td>
          </tr>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              Nro. Cuenta Beneficiario:
            </td>
            <td class="border border-blue-700 p-1">18858363</td>
          </tr>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              Tipo Cuenta: Ahorro / Corriente
            </td>
            <td class="border border-blue-700 p-1">Corriente</td>
          </tr>
          <tr>
            <td class="border border-blue-700 font-semibold p-1">
              Código SWIFT:
            </td>
            <td class="border border-blue-700 p-1">AUSTECEQ</td>
          </tr>
        </tbody>
      </table>

      <h2 class="text-center azul rounded py-1 px-5 font-bold text-2xl">
        ¿Desde qué dirección puede realizar envíos?
      </h2>
      <p class="text-center azul mx-2 md:mx-32 text-sm mb-4">
        A continuación, se detalla los corresponsales que dispone el Banco del
        Austro en el Exterior,
        <br />
        en los que pueden realizar los depósitos en efectivo o a su vez realizar
        transferencias.
      </p>
      <h2 class="text-center azul rounded py-1 px-5 font-semibold text-xl">
        España
      </h2>
      <table class="table-auto border-collapse mx-auto my-1 text-sm w-8/12">
        <tbody>
          <tr>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">Madrid</h6>
              <p>
                Calle Bravo Murillo 255, Metro Tetuan, Lunea Uno
                <br />
                Telfs.: 349-1572-2448 / 349-1571-9273
              </p>
            </td>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">Llorca</h6>
              <p>
                Avenida Juan Carlos I Número 16 Edif. Almanzora
                <br />
                Telfs.: 349-6847-8469 / 349-6840-7169
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- USA -->
      <h2 class="text-center azul rounded py-1 px-5 font-semibold text-xl">
        EEUU
      </h2>
      <table class="table-auto border-collapse mx-auto my-1 text-sm w-8/12">
        <tbody>
          <tr>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">New York</h6>
              <p>
                8008 Rooselt Ave. Jackson Hts., NY 11372
                <br />
                Telf.: 718-899-7851
              </p>
            </td>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">West New York</h6>
              <p>
                5707 Bergenline Av. West New York NJ 07093
                <br />
                Telf.: 201-392-3861
              </p>
            </td>
          </tr>
          <tr>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">Newark</h6>
              <p>
                Edificio Ginaarte junto al Consulado Ecuatoriano
                <br />
                Telf.: 973589-1501
              </p>
            </td>
            <td class="border border-blue-700 p-1">
              <h6 class="font-semibold azul">Chicago</h6>
              <p>
                3760 W. Lawrence Chicago. Il. 60625
                <br />
                Telf.: 773-463-8694
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="text-justify mt-10 text-sm">
        <strong class="azul">Nota:</strong> Los socios deberán regirse a las
        políticas y formularios establecidos por cada corresponsal.
      </p>
      <p class="text-sm mb-4 mt-2">
        Una vez realizado el depósito o transferencia el socio deberá
        comunicarse con el asesor o Responsable Operativo y enviar el
        comprobante de la transacción realizada para verificar la acreditación
        con éxito en nuestra cuenta, para posterior acreditación en la cuenta
        del socio solicitante.
        <br />
        <strong class="azul"> Jorge Lagua Celular 0958932703</strong>
      </p>
      <p class="text-sm mb-12">
        <strong class="azul">Nota importante:</strong>
        <br />
        Es importante mencionar que las transferencias llegan en un plazo de 24
        horas, sin embargo, han existido casos que por cuestión de la remesadora
        en el exterior se han demorado hasta 48 horas.
      </p>
    </section>
  </main>
</template>

<script>
import Brands from "../../components/services/Brands.vue";
import Service from "../../components/services/Service.vue";

export default {
  name: "Services",
  components: { Service, Brands }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  position: relative;
}

.bg-azul-fuerte {
  background-color: #063a79;
}
</style>
