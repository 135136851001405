<template>
  <table>
    <thead>
      <tr class="bg-gray-100 border-b-2 border-gray-400">
        <th>Nº</th>
        <th>Nombre y Apellido</th>
        <th>Correo</th>
        <th>Cédula</th>
        <th>Celular</th>
        <th>Ciudad</th>
        <th>Agencia</th>
        <th>Tipo</th>
        <td class="hidden sm:block"></td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(a, i) in list"
        :key="a._id"
        class="border-b border-gray-200 hover:bg-gray-100 hover:bg-orange-100"
      >
        <td>{{ i + 1 }}</td>
        <td>{{ a.nombre }} {{ a.apellido }}</td>
        <td>{{ a.email }}</td>
        <td>{{ a.ci }}</td>
        <td>{{ a.celular }}</td>
        <td>{{ a.ciudad }}</td>
        <td>{{ a.agencia }}</td>
        <td>{{ a.type }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ListUser",

  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
td {
  padding: 20px 0px;
  font-size: 0.6rem;
  text-align: center;
}

th {
  padding: 5px;
  font-size: 0.6rem;
}

@media (min-width: 640px) {
  td,
  th {
    padding: 20px;
    font-size: 1rem;
  }

  th {
    padding: 12px;
  }
}
</style>
