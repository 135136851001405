<template>
  <main>
    <!-- Selectores de consulta -->
    <div class="flex justify-between px-2 md:px-48 my-10 w-full md:w-7/12">
      <date-picker
        class=""
        v-model="time3"
        type="date"
        format="YYYY-MM-DD"
        range
      ></date-picker>
      <select
        v-model="selectedValue"
        name="LeaveType"
        class="bg-gray-200 border border-gray-600 rounded px-2"
      >
        <!-- @change="onChange($event)" -->
        <option value="" selected>seleccione</option>
        <option value="creditos">Créditos</option>
        <option value="ahorros">Ahorros</option>
        <option value="inversiones">Inversiones</option>
      </select>
      <button
        type="button"
        id="guardar"
        class=" bg-blue-500 hover:bg-blue-600 text-white rounded px-5 focus:outline-none"
        @click="consultar(time3)"
        @keydown.tab.exact.prevent=""
      >
        Consultar
      </button>
    </div>

    <!-- TABLE -->
    <div class="px-2 md:px-20 mb-10 overflow-x-auto">
      <table
        id="example"
        class="w-full table border-collapse border border-blue-800 ..."
      >
        <thead>
          <tr>
            <th class="center border border-blue-600">N°</th>
            <th class="center border border-blue-600">nombres</th>
            <th class="center border border-blue-600">cédula</th>
            <th class="center border border-blue-600">correo</th>
            <th class="center border border-blue-600">celular</th>
            <th v-if="montShow" class="center border border-blue-600">monto</th>
            <th v-if="activEcoShow" class="center border border-blue-600">
              act econo
            </th>
            <th class="center border border-blue-600">provincia</th>
            <th class="center border border-blue-600">ciudad</th>
            <th class="center border border-blue-600">parroquia</th>
            <th class="center border border-blue-600">agencia</th>
            <th class="center border border-blue-600">f_creacion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a, i) in variable" :key="a.id">
            <td class="border border-blue-600 px-2">{{ i + 1 }}</td>
            <td class="border border-blue-600 px-2">{{ a.nombres }}</td>
            <td class="border border-blue-600 px-2">{{ a.cedula }}</td>
            <td class="border border-blue-600 px-2">{{ a.correo }}</td>
            <td class="border border-blue-600 px-2">{{ a.celular }}</td>
            <td class="border border-blue-600 px-2" v-if="a.monto">
              {{ a.monto }}
            </td>
            <td class="border border-blue-600 px-2" v-if="a.economicActivity">
              {{ a.economicActivity }}
            </td>
            <td class="border border-blue-600 px-2">{{ a.provincia }}</td>
            <td class="border border-blue-600 px-2">{{ a.ciudad }}</td>
            <td class="border border-blue-600 px-2">{{ a.parroquia }}</td>
            <td class="border border-blue-600 px-2">{{ a.agenciacercana }}</td>
            <td class="border border-blue-600 px-2">
              {{ a.f_creacion | moment("YYYY-MM-DD") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h6 v-if="json_data.length == 0" class="px-20 -mt-10 text-orange-600 mb-10">
      No hay datos para ese rango de fechas
    </h6>

    <!-- BTN DOWNLOAD -->
    <div class=" flex justify-center" v-if="json_data.length > 0">
      <download-excel
        class="bg-blue-600 rounded py-1 px-10 shadow hover:bg-blue-500 focus:outline-nones mb-10 text-white font-bold cursor-pointer"
        :data="json_data"
        type="xls"
        name="consulta.xls"
      >
        Descargar a Excel
      </download-excel>
    </div>
  </main>
</template>
<script>
// import XLSX from "xlsx";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import api from "@/api/formularios";
export default {
  components: { DatePicker },
  data() {
    return {
      activEcoShow: false,
      montShow: false,
      time3: "",
      selectedValue: "",
      array: [],
      arrayconsulta: [],
      data: "",
      variable: [],
      fechaInicio: "",
      fechaFin: "",

      json_fields: {
        Nombres: "",
        Cedula: "",
        Correo: "",
        Celular: "",
        provincia: "",
        ciudad: "",
        parroquia: "",
        agenciacerca: "",
        creacion: ""
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },

  methods: {
    onChange: function(event) {
      console.log("event", event);
      console.log("selectedvalue", this.selectedValue);

      if (this.selectedValue == "creditos") {
        api.getCreditos().then(resp => {
          this.variable = [];
          this.array = [];
          console.log("lista de creditos", resp);
          this.array = resp;
          this.variable = this.array;
        });
      } else {
        if (this.selectedValue == "ahorros") {
          this.variable = "array";
          api.getAhorros().then(resp => {
            console.log("lista de ahorros", resp);
            this.array = resp;
            this.variable = this.array;
            this.json_data = this.variable;
            this.variable = this.array;
            this.json_data = [];
          });
        } else {
          if (this.selectedValue == "inversiones") {
            this.variable = "array";
            api.getinversiones().then(resp => {
              console.log("lista de inversiones", resp);
              this.array = resp;
              this.variable = this.array;
              this.variable = this.array;
              this.json_data = [];
            });
          } else {
            console.log("ninguno");
          }
        }
      }
    },
    consultar: function(time3) {
      this.array = [];
      this.variable = [];
      this.json_data = [];
      if (this.selectedValue == "creditos") {
        this.fechaInicio = time3[0];
        this.fechaFin = time3[1];
        api
          .apiCompararFechasCreditos(this.fechaInicio, this.fechaFin)
          .then(resp => {
            console.log("creditos de la fecha establecida:", resp.docs);
            this.arrayconsulta = resp.docs;
            this.variable = this.arrayconsulta;
            this.variable.forEach(element => {
              element.economicActivity
                ? (this.activEcoShow = true)
                : (this.activEcoShow = false);
              element.monto ? (this.montShow = true) : (this.montShow = false);
              this.json_data.push({
                Nombres: element.nombres,
                Cedula: element.cedula,
                Correo: element.correo,
                Celular: element.celular,
                provincia: element.provincia,
                ciudad: element.ciudad,
                parroquia: element.parroquia,
                agenciacerca: element.agenciacercana,
                creacion: element.f_creacion
              });
            });
          });
      } else {
        if (this.selectedValue == "inversiones") {
          this.array = this.arrayconsulta;
          this.fechaInicio = time3[0];
          this.fechaFin = time3[1];
          api
            .apiCompararFechasInversiones(this.fechaInicio, this.fechaFin)
            .then(resp => {
              console.log("inversiones de la fecha establecida", resp.docs);

              this.arrayconsulta = resp.docs;
              this.variable = this.arrayconsulta;
              this.variable.forEach(element => {
                element.economicActivity
                  ? (this.activEcoShow = true)
                  : (this.activEcoShow = false);
                element.monto
                  ? (this.montShow = true)
                  : (this.montShow = false);
                this.json_data.push({
                  Nombres: element.nombres,
                  Cedula: element.cedula,
                  Correo: element.correo,
                  Celular: element.celular,
                  provincia: element.provincia,
                  ciudad: element.ciudad,
                  parroquia: element.parroquia,
                  agenciacerca: element.agenciacercana,
                  creacion: element.f_creacion
                });
                console.log("nuevo", this.json_data);
              });
            });
        } else {
          if (this.selectedValue == "ahorros") {
            this.array = this.arrayconsulta;
            this.fechaInicio = time3[0];
            this.fechaFin = time3[1];
            api
              .apiCompararFechasAhorros(this.fechaInicio, this.fechaFin)
              .then(resp => {
                console.log("ahorros de la fecha establecida", resp.docs);
                this.arrayconsulta = resp.docs;
                this.variable = this.arrayconsulta;
                this.variable.forEach(element => {
                  element.economicActivity
                    ? (this.activEcoShow = true)
                    : (this.activEcoShow = false);
                  element.monto
                    ? (this.montShow = true)
                    : (this.montShow = false);
                  this.json_data.push({
                    Nombres: element.nombres,
                    Cedula: element.cedula,
                    Correo: element.correo,
                    Celular: element.celular,
                    provincia: element.provincia,
                    ciudad: element.ciudad,
                    parroquia: element.parroquia,
                    agenciacerca: element.agenciacercana,
                    creacion: element.f_creacion
                  });
                  console.log("nuevo", this.json_data);
                });
              });
          }
        }
      }
    }
  }
};
</script>
<style scoped>
.marginselect {
  width: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: 50px;
}
.marginboton {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 100px;
  margin-right: auto;
}
.margins {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: auto;
  width: 300px;
  background: rgb(214, 224, 226);
}

.marginbotonc {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: 100px;
  width: 200px;
}
.margintable {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 300px;
  margin-right: auto;
}
.center {
  text-align: center;
  color: darkblue;
}
.descargar {
  text-align: center;
  background: #4899e1;
  color: white;
  margin: 25px;
}
.descargar:hover {
  background: blue;
}
@media screen and (max-width: 640px) {
  #div1 {
    overflow: scroll;
    height: auto;
    width: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .descargar {
    text-align: center;
    background: #4899e1;
    color: white;
    margin: 10px;
  }
  .marginselect {
    width: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .marginbotonc {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 50px;
    margin-right: 100px;
    width: 200px;
  }
  .margins {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 50px;
    margin-right: 100px;
    width: 200px;
    background: rgb(214, 224, 226);
  }
}
</style>
