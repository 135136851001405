<template>
  <main id="main" :style="{
    'background-image': 'url(' + require('@/assets/img/home/fondo.jpg') + ')'
  }">
    <div class="container mx-auto my-8 lg:px-40 sm:px-10">
      <header class="w-full container mx-auto">
        <div class="flex flex-col items-center pt-12">
          <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
            Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
          </a>
          <p class="text-gray-600 text-center">
            Los derechos ARCO son un conjunto de derechos que protegen y controlan los datos e información personal. A
            continuación, se detalla la definición de cada uno de ellos:
          </p>
          <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
        </div>
      </header>
    </div>
    <div class="max-w-screen-xl mx-auto px-5 pb-4">
      <div class="grid sm:grid-cols-2 md:grid-cols-2 mt-8 gap-8">
        <div class="flex gap-4 items-start">
          <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
              viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-briefcase">
              <symbol id="ai:bx:bxs-briefcase">
                <path
                  d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v3h20V8c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm5 10h-4v-2H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-8v2z"
                  fill="currentColor"></path>
              </symbol>
              <use xlink:href="#ai:bx:bxs-briefcase"></use>
            </svg> </div>
          <div>
            <h3 class="font-semibold text-lg">Derecho de acceso</h3>
            <p class="text-slate-500 mt-2 leading-relaxed">Derecho a acceder a la información y datos persinales del
              titular que posea la empresa.</p>
          </div>
        </div>
        <div class="flex gap-4 items-start">
          <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
              viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-bot">
              <symbol id="ai:bx:bxs-bot">
                <path
                  d="M21 10.975V8a2 2 0 0 0-2-2h-6V4.688c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 2 12v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632zM7 12c0-1.104.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2S7 13.104 7 12zm8.998 6c-1.001-.003-7.997 0-7.998 0v-2s7.001-.002 8.002 0l-.004 2zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2z"
                  fill="currentColor"></path>
              </symbol>
              <use xlink:href="#ai:bx:bxs-bot"></use>
            </svg> </div>
          <div>
            <h3 class="font-semibold text-lg">Derecho de rectificación</h3>
            <p class="text-slate-500 mt-2 leading-relaxed">Derecho a obtener la rectificación y/o actualización de los
              datos e información personal que sea inexacta e incompleta.</p>
          </div>
        </div>
        <div class="flex gap-4 items-start">
          <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
              viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-user">
              <symbol id="ai:bx:bxs-user">
                <path
                  d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                  fill="currentColor"></path>
              </symbol>
              <use xlink:href="#ai:bx:bxs-user"></use>
            </svg> </div>
          <div>
            <h3 class="font-semibold text-lg">Derecho de cancelación</h3>
            <p class="text-slate-500 mt-2 leading-relaxed">Derecho a la supresión de los datos e información personal
              que incumpla con los principios, derechos, obligaciones y mecanismos de tutela establecidos en la LOPDP y
              demás leyes vigentes.</p>
          </div>
        </div>
        <div class="flex gap-4 items-start">
          <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
              viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-window-alt">
              <symbol id="ai:bx:bxs-window-alt">
                <path
                  d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-3 3h2v2h-2V6zm-3 0h2v2h-2V6zM4 19v-9h16.001l.001 9H4z"
                  fill="currentColor"></path>
              </symbol>
              <use xlink:href="#ai:bx:bxs-window-alt"></use>
            </svg> </div>
          <div>
            <h3 class="font-semibold text-lg">Derecho de Oposición</h3>
            <p class="text-slate-500 mt-2 leading-relaxed"> Derecho de oponerse o negarse al tratamiento de los datos e
              información personal que incumpla con los principios, derechos, obligaciones y mecanismos de tutela
              establecidos en la LOPDP y demás leyes vigentes. Para ejercer cualquiera de los derechos ARCO es
              fundamental tener en cuenta que el derecho a la protección de datos personales es de carácter
              estrictamente personal. Por lo tanto, solo el titular o, en su defecto, su representante legal debidamente
              autorizado, podrá presentar la solicitud correspondiente.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-gray-200">
      <div class="container mx-auto my-8 lg:px-40 sm:px-10">
        <header class="w-full container mx-auto">
          <div class="flex flex-col items-center pt-12">
            <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
              Requisitos para la presentación de una solicitud de ejercicio de derechos ARCO
            </a>
            <p class="text-gray-600 text-center">
              A continuación, se presenta una guía sobre los pasos y requisitos para ejercer derechos ARCO:
            </p>
            <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
          </div>
        </header>
        <section>
          <div class="border-b">
            <div class="accordion-header p-4 text-dark hover:text-red border-b-2 border-gray-900"
              @click="index = 0, toggleAccordion(index)">
              <span class="text-xl font-bold" :class="isOpen && index == 0 ? 'azul' : 'text-dark'">1. Presentación de la
                solicitud</span>
            </div>
            <div v-show="activeIndex === 0" class="accordion-content p-4 text-gray-700">
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                El titular debe ingresar su solicitud dirigida al responsable de Datos Personales a través de los
                siguientes medios:</span>
              <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Correo electrónico:</b>&nbsp;dpo@coopcredil.com.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Trámite presencial en ventanilla al Servicio al Cliente
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Plataforma en línea:</b>&nbsp;https://coopcredil.com/
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-b">
            <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
              @click="index = 1, toggleAccordion(index)">
              <span class="text-xl font-bold" :class="isOpen && index == 1 ? 'azul' : 'text-dark'">2. Información
                general</span>
            </div>
            <div v-show="activeIndex === 1" class="accordion-content p-4 text-gray-700">
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Toda solicitud debe contener los siguientes datos, sobre la información del titular de los datos
                personales:</span>
              <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Nombre y apellido completo del titular.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Número de identificación del titular (cédula, pasaporte, etc).
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicar si es una persona jurídica o natural.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Correo electrónico del titular.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Teléfono celular del titular.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Dirección domiciliaria del titular.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicar el medio preferifo para recibir notificaciones (correo electrónico, WhatsApp o físico por
                    ventanilla)
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicación del derecho ARCO que se desea ejercer o la solicitud específica que el titular requiera.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Descripción clara y específica de los datos que se desean rectificar, cancelar u oponerse a su
                    tratamiento.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-b">
            <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
              @click="index = 2, toggleAccordion(index)">
              <span class="text-xl font-bold" :class="isOpen && index == 2 ? 'azul' : 'text-dark'">3. Información del
                Representante Legal titular de los datos (según sea su caso):</span>
            </div>
            <div v-show="activeIndex === 2" class="accordion-content p-4 text-gray-700">
              <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Nombre y apellido completo del representante legal.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Número de identificación del representante legal (cédula, pasaporte, etc.).
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicar si es persona jurídica o natural.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Correo electrónico del representante legal.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Teléfono celular del representante legal.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Numero de notaría y numero de escritura.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Dirección domiciliaria del representante legal.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicar el medio preferido para recibir notificaciones (correo electrónico, WhatsApp o físico por
                    ventanilla).
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Indicación del derecho ARCO que se desea ejercer o la solicitud específica que el representante
                    legal requiera.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Descripción clara y específica de los datos que se desean rectificar, cancelar u oponerse a su
                    tratamiento.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-b">
            <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
              @click="index = 3, toggleAccordion(index)">
              <span class="text-xl font-bold" :class="isOpen && index == 3 ? 'azul' : 'text-dark'">4. Información
                adicional específica:</span>
            </div>
            <div v-show="activeIndex === 3" class="accordion-content p-4 text-gray-700">
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                Dependiendo del derecjo que se debe ejercer, será necesario incluir información complementaria:</span>
              <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Derecho de acceso:</b>&nbsp;Solicitud de información sobre el tratamiento de los datos personales
                    e información.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Derecho de rectificación:</b>&nbsp;Detalle de los datos incorrectos y/o inexactos y la
                    información que debe ser corregida y/o actualizada.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Derecho de cancelación:</b>&nbsp;Especificar los datos que deben ser eliminados.
                  </span>
                </li>
                <li class="flex space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    <b>Derecho de oposición:</b>&nbsp;Explicar los motivos por los cuales se solicita la interrupción
                    del tratamiento de los datos.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-b">
            <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
              @click="index = 4, toggleAccordion(index)">
              <span class="text-xl font-bold" :class="isOpen && index == 4 ? 'azul' : 'text-dark'">5. Acreditación de
                identidad:</span>
            </div>
            <div v-show="activeIndex === 4" class="accordion-content p-4 text-gray-700">
              <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                El solicitante y/o representante legal (si aplica) deberá demostrar su identidad, es por ello, que es
                obligatorio proporcionar los documentos de identidad original y/o una copia verificable. Entre los
                documentos aceptados están: cédula de ciudadanía o identidad, pasaporte, licencia de conducir, o
                documento migratorio.</span>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="container mx-auto my-8 lg:px-40 sm:px-10">
      <header class="w-full container mx-auto">
        <div class="flex flex-col items-center pt-12">
          <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
            Plazos y procedimiento para la atención de las solicitudes de derechos ARCO
          </a>
          <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
        </div>
      </header>
    </div>
    <div
      class="mt-8 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-3 lg:divide-y-0 xl:grid-cols-3">
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341134.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <p class="text-gray-600 dark:text-gray-300">
              <b>1. </b>&nbsp;Una vez presentada la solicitud y habiendo cumplido correctamente con todos los
              requisitos, el responsable a cargo deberá seguir los plazos establecidos en la normativa vigente, con un
              plazo de 15 días para dar respuesta.
            </p>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-gray-50 dark:bg-gray-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div
          class="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white dark:group-hover:bg-gray-800">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341025.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <p class="text-gray-600 dark:text-gray-300">
              <b>2. </b>&nbsp;En caso de que la solicitud no incluya toda la información requerida, la Cooperativa podrá
              solicitar los datos faltantes para proceder con el trámite.
            </p>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341139.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->
          <div class="space-y-2">
            <p class="text-gray-600 dark:text-gray-300">
              <b>3. </b>&nbsp;Finalmente, si las disposiciones aplicables a una determinada base de datos o tratamiento
              estipulan un procedimiento específico para ejercer los derechos ARCO, se deberá acatar lo dispuesto en
              aquellas normativas que ofrezcan mayores garantías al titular, siempre y cuando no contravengan lo
              establecido por la Ley de Protección de Datos Personales.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-gray-200">
      <div class="container mx-auto my-8 lg:px-40 sm:px-10">
        <header class="w-full container mx-auto">
          <div class="flex flex-col items-center pt-12">
            <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
              Procedimiento para Solicitar la Portabilidad
            </a>
            <p class="text-gray-600 text-center">
              Para ejercer el derecho de portabilidad, el socio deberá seguir el siguiente procedimiento:
            </p>
            <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
          </div>
        </header>
      </div>
      <div class="owners py-4">
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <img src="@/assets/img/governance/iconoh.png" alt="icono hombre" class="w-20 h-20" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">1. Solicitud escrita</span>
                <span class="gris font-thin -mt-1 block">El titular debe presentar una solicitud formal dirigida al EPDP
                  (Encargado de Protección de Datos Personales), indicando los datos que desea portar y la entidad
                  receptora de los mismos. La cual, deberá cumplir con lo siguiente:</span>
              </div>
            </div>
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              <img src="@/assets/img/governance/iconoh.png" alt="icono hombre" class="w-20 h-20 inline-block" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">2. Presentación de la solicitud</span>
                <span class="gris font-thin -mt-1 block">
                  El titular debe dirigir su solicitud al responsable de Datos Personales a través de los siguientes
                  medios:</span>
                <ul role="list" class="pt-3 space-y-1 border-t border-gray-200 my-7 dark:border-gray-700">
                  <li class="flex space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                      viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-xs leading-tight text-gray-900 dark:text-white">
                      a) Correo electrónico a dpo@coocredil.com
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                      viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-xs leading-tight text-gray-900 dark:text-white">
                      b) Trámite presencial en ventanilla de Servicio al Cliente
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                      viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-xs leading-tight text-gray-900 dark:text-white">
                      c) Plataforma en línea: https://coopcredil.com/
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <img src="@/assets/img/governance/iconom.png" alt="icono mujer" class="w-20 h-20 inline-block" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">3. Información general</span>
                <span class="gris font-thin -mt-1 block">Toda solicitud debe contener los datos:</span>
                <div class="col-span-2 md:col-span-1">
                  <span class="gris font-thin -mt-1 block"><b>Información del Titular de los Datos
                      Personales:</b></span>
                  <ul role="list" class="pt-2 space-y-1 border-t border-gray-200 my-7 dark:border-gray-700">
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        a) Nombre y apellido completo del titular.
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        b) Número de identificación del titular (cédula, pasaporte, etc.).
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        c) Indicar si es persona jurídica o natural.
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        d) Correo electrónico del titular
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        e) Teléfono celular del titular
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        f) Dirección domiciliaria del titular
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        g) Indicar el medio preferido para recibir notificaciones (correo electrónico, WhatsApp o físico
                        por ventanilla).
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        h) Descripción clara y especifica indicando los datos que desea portar y la entidad receptora de
                        los mismos.
                      </span>
                    </li>
                  </ul>
                  <span class="gris font-thin mt-3 block"><b>Información del Representante Legal de los Datos
                      Personales:</b></span>
                  <ul role="list" class="pt-2 space-y-1 border-t border-gray-200 my-7 dark:border-gray-700">
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        a) Nombre y apellido completo del representante legal.
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        b) Número de identificación del representante legal (cédula, pasaporte, etc.).
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        c) Indicar si es persona jurídica o natural.
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        d) Correo electrónico del representante legal
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        e) Teléfono celular del representante legal
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        f) Numero de notaria y numero de escritura
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        g) Dirección domiciliaria del representante legal
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        h) Indicar el medio preferido para recibir notificaciones (correo electrónico, WhatsApp o físico
                        por ventanilla).
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        i) Indicación del derecho ARCO que se desea ejercer o la solicitud específica que el
                        representante
                        legal requiera.
                      </span>
                    </li>
                    <li class="flex space-x-3">
                      <!-- Icon -->
                      <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-xs leading-tight text-gray-900 dark:text-white">
                        j) Descripción clara y especifica indicando los datos que desea portar y la entidad receptora de
                        los mismos.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <div class="col-span-2 md:col-span-1">
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
              <img src="@/assets/img/governance/iconom.png" alt="icono mujer" class="w-20 h-20 inline-block" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">4. Verificación de identidad</span>
                <span class="gris font-thin -mt-1 block">El solicitante y/o representante legal (si aplica) deberá
                  demostrar su identidad, es por ello, que es obligatorio proporcionar los documentos de identidad
                  original y/o una copia verificable. Entre los documentos aceptados están: cédula de ciudadanía o
                  identidad, pasaporte, licencia de conducir, o documento migratorio.</span>
              </div>
            </div>
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <img src="@/assets/img/governance/iconoh.png" alt="icono hombre" class="w-20 h-20 inline-block" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">5. Plazos de respuesta</span>
                <span class="gris font-thin -mt-1 block">Según la LOPDP, la Cooperativa tiene un plazo máximo de 15 días
                  hábiles para responder a la solicitud, con la posibilidad de una prórroga de 15 días adicionales en
                  casos justificados.</span>
              </div>
            </div>
            <div class="flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <img src="@/assets/img/governance/iconoh.png" alt="icono hombre" class="w-20 h-20 inline-block" />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase">6. Entrega de los datos</span>
                <span class="gris font-thin -mt-1 block">Una vez aprobada la solicitud, la Cooperativa deberá entregar
                  los datos en el formato acordado o transferirlos directamente a la entidad indicada por el
                  titular.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "DataProtection",
  data: function () {
    return {
      activeIndex: null,
      index: 0,
      isOpen: false
    }
  },
  methods: {
    toggleAccordion(index) {
      // Cambiar el índice activo para mostrar u ocultar el acordeón
      if (this.activeIndex === index) {
        this.activeIndex = null; // Si ya está abierto, cerramos el acordeón
        this.isOpen = false;
      } else {
        this.activeIndex = index; // Abrir el acordeón
        this.isOpen = true;
      }
    }
  }
};
</script>

<style scoped>
/* main */
#main {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 1;
}

#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}

.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}

/* ICONS */
.icono {
  width: 180px;
  height: 180px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  border-radius: 50%;
  background-color: white;
}

.icono:hover {
  position: relative;
  top: 0px;
  box-shadow: 0 0 7px #999;
  border-radius: 50%;
  transform: translateY(-0.4px);
}
</style>
