<template>
  <div>
    <div
      class="container w-full flex justify-center mt-32 mb-32"
      v-if="isLoading"
    >
      <fade-loader :loading="isLoading" :color="'#0a5595'" :size="100" />
    </div>
    <main style="background-color: #f5f5f5;" v-if="!isLoading">
      <!-- BANNER -->
      <div
        id="banner"
        :style="{
          'background-image': `url('${url}${product.banner}')`
        }"
      >
        <div class="texto-banner">
          <h2
            class="text-white text-3xl font-bold"
            style="text-shadow: 2px 2px 4px rgb(0 0 0 / 10%);"
          >
            {{ product.name }}
          </h2>
          <p class="text-gray-100">Home / {{ product.name }}</p>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <div
        class="container mt-12  px-4 md:px-20"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="grid grid-cols-7 relative  bg-azul ml-0 md:ml-32">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-2 rounded-full  bg-white -ml-32 my-2"
            style="width:320px; height:320px;"
          >
            <img
              :src="`${url}${product.imgbeneficio}`"
              alt="imagen credil"
              class="p-0"
            />
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-4 py-16 ">
            <h6 class="text-white text-xl">Producto</h6>
            <hr class="w-1/3 text-white mb-3" />
            <h4 class="text-white text-3xl mb-2 font-bold">
              {{ product.name }}
            </h4>
            <p class="text-white text-justify">
              {{ product.description }}
            </p>
            <div class="flex mt-5">
              <router-link
                v-if="product.simulator === 'credito'"
                class="bg-white text-blue-700 rounded font-semibold px-4 py-1 mr-4 hover:bg-blue-100"
                to="/simulador-credito"
                target="_blank"
                >Simulador</router-link
              >
              <router-link
                v-if="product.simulator === 'inversiones'"
                class="bg-white text-blue-700 rounded font-semibold px-4 py-1 mr-4 hover:bg-blue-100"
                to="/simulador-inversiones"
                target="_blank"
                >Simulador</router-link
              >

              <!-- SOLICITUDES -->
              <router-link
                v-if="product.simulator === 'credito'"
                to="/formulario-credito"
                class="bg-white text-blue-700 rounded font-semibold px-4 py-1 mr-4 hover:bg-blue-100"
              >
                Solicita
              </router-link>
              <router-link
                v-if="product.simulator === 'inversiones'"
                to="/formulario-inversiones"
                class="bg-white text-blue-700 rounded font-semibold px-4 py-1 mr-4 hover:bg-blue-100"
              >
                Solicita
              </router-link>
              <router-link
                v-if="product.simulator === '0'"
                to="/formulario-ahorro"
                class="bg-white text-blue-700 rounded font-semibold px-4 py-1 mr-4 hover:bg-blue-100"
              >
                Solicita
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- PLAN FUTUTO SLIDER -->
      <div
        class="container"
        data-aos="fade-up"
        data-aos-duration="1000"
        v-if="product.slug == 'ahorro-plan-futuro'"
      >
        <ahorro-plan-futuro-slider class="mt-8" />
        <h5 class="azul text-xl font-semibold text-center">Características</h5>
        <ul
          class="list-disc ml-8 flex flex-col items-center content-center mb-5"
        >
          <li class="azul text-gray-700">
            Valor de Ahorro de $10 a $99.99, ganas el 4%
          </li>
          <li class="azul text-gray-700">
            Valor de Ahorro de $100 a $199.99, ganas el 6%
          </li>
          <li class="azul text-gray-700">
            Valor de Ahorro de $200 a más, ganas el 6%
          </li>
        </ul>
        <p class="text-center text-gray-700 text-sm">
          *El tiempo mínimo de permanencia es de 6 meses.
        </p>
      </div>

      <!-- BANNER INFO -->
      <div
        class="banner-full w-full py-10 my-10"
        style="background-color:#e2e2e2;"
      >
        <div
          class="flex justify-around"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="group">
            <img
              src="@/assets/img/products/icono1.png"
              alt="icono web"
              class="h-20 mx-auto -mb-2"
            />
            <h5 class="azul text-3xl text-center font-semibold m-0">
              19096 +
            </h5>
            <p class="azul text-center text-sm text-semibold m-0">
              Créditos aprobados
            </p>
          </div>
          <div class="group">
            <img
              src="@/assets/img/products/icono2.png"
              alt="icono web"
              class="h-20 mx-auto -mb-2"
            />
            <h5 class="azul text-3xl text-center font-semibold m-0">
              12 +
            </h5>
            <p class="azul text-center text-sm text-semibold m-0">
              Años de expereiencia
            </p>
          </div>
          <div class="group">
            <img
              src="@/assets/img/products/icono3.png"
              alt="icono web"
              class="h-20 mx-auto -mb-2"
            />
            <h5 class="azul text-3xl text-center font-semibold m-0">
              14562 +
            </h5>
            <p class="azul text-center text-sm text-semibold m-0">
              Clientes satisfechos
            </p>
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <section>
        <div
          class="container mt-12  px-4 md:px-20"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <!-- REQUERIMENTS -->
          <div
            class="grid grid-cols-2 pb-12"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div class="col-span-2 md:col-span-1 p-10 bg-white">
              <!-- Beneficios -->
              <h5 class="azul text-3xl font-semibold">Beneficios</h5>
              <ul class="list-disc">
                <li
                  class="text-gray-700 mb-2 ml-6"
                  v-for="a in product.characteristics.split(';')"
                  :key="a._id"
                >
                  {{ a }}
                </li>
              </ul>

              <!-- Requisitos -->
              <h5 class="azul text-3xl font-semibold">Requisitos</h5>
              <ul class="list-disc">
                <li
                  class="text-gray-700 mb-2 ml-6"
                  v-for="a in product.requirements.split(';')"
                  :key="a._id"
                >
                  {{ a }}
                </li>
              </ul>
            </div>

            <div class="col-span-2 md:col-span-1">
              <img
                :src="url + product.imgrequisitos"
                alt="beneficios crediya"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import api from "@/api/product";
import url from "@/api/url";
import AhorroPlanFuturoSlider from "../components/products/AhorroPlanFuturoSlider.vue";

export default {
  components: { AhorroPlanFuturoSlider },
  name: "Product",
  data() {
    return {
      url,
      product: {},
      isLoading: true
    };
  },
  watch: {
    $route() {
      this.getProduct();
    }
  },
  created() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      const id = this.$route.params.id;
      api.getProduct(id).then(product => {
        console.log("producto q llega", product);
        this.product = product[0];
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 60%;
}
</style>
