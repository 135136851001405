<template>
  <table>
    <thead>
      <tr class="bg-gray-100 border-b-2  border-gray-400">
        <th>Nº</th>
        <!-- <th>Banner</th> -->
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Beneficios</th>
        <th>Requisitos</th>
        <th>Acción</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(a, i) in list"
        :key="a._id"
        class="border-b border-b-2 border-gray-200 hover:bg-gray-100 hover:bg-orange-100"
      >
        <td>{{ i + 1 }}</td>
        <!-- <td><img :src="url + a.banner" :alt="a.name" class="h-10" /></td> -->
        <td>{{ a.name }}</td>
        <td style="max-width:600px;" class="text-left text-justify">
          {{ a.description }}
        </td>
        <td>
          <ul class="list-disc pl-2">
            <li
              class="text-left"
              v-for="c in a.characteristics.split(';')"
              :key="c"
            >
              {{ c }}
            </li>
          </ul>
        </td>
        <td>
          <ul class="list-disc pl-2">
            <li
              class="text-left"
              v-for="c in a.requirements.split(';')"
              :key="c"
            >
              {{ c }}
            </li>
          </ul>
        </td>

        <td>
          <!-- see product -->
          <router-link :to="'producto/' + a.slug" target="_blank">
            <span
              class="bg-green-400 hover:bg-green-500 p-2 rounded inline-block m-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="" />
            </span>
          </router-link>

          <!-- Edit product -->
          <router-link :to="`editar-producto/${a.slug}`" target="_blank">
            <span
              class="bg-yellow-400 hover:bg-yellow-500 p-2 rounded inline-block m-1"
            >
              <font-awesome-icon :icon="['fas', 'pen']" class="" />
            </span>
          </router-link>

          <!-- delete product -->
          <button @click="deleteProd(a._id)">
            <span
              class="bg-red-400 hover:bg-red-500 p-2 rounded inline-block m-1"
            >
              <font-awesome-icon :icon="['fas', 'trash']" class="" />
            </span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import url from "@/api/url";
import api from "@/api/product";
export default {
  name: "ListProducts",
  data() {
    return {
      url,
      list: []
      // characteristics: []
    };
  },
  watch: {
    $route() {
      this.getProductList();
    }
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      api.listProducts().then(list => {
        console.log("lista de los productos", list);
        this.list = list;
      });
    },

    deleteProd(id) {
      console.log("borrando", id);
      this.$swal({
        title: "¿Estás seguro de borrar?",
        text: "Esto no se puede revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, bórralo!"
      }).then(result => {
        if (result.value) {
          api.deleteProduct(id).then(() => {
            this.$swal(
              "Borrado!",
              "Su archivo ha sido borrado.",
              "success"
            ).then(() => {
              this.getProductList();
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped>
td {
  padding: 20px 0px;
  font-size: 0.6rem;
  text-align: center;
}

th {
  padding: 5px;
  font-size: 0.6rem;
}

@media (min-width: 640px) {
  td,
  th {
    padding: 20px;
    font-size: 1rem;
    border: 1px solid #eee;
  }

  th {
    padding: 12px;
  }
}
</style>
