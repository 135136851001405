<template>
  <div id="div1">
    <table
      id="example"
      class="margin border-separate border border-green-800 ..."
    >
      <thead>
        <tr>
          <th class="center titulotable">N°</th>
          <th class="center titulotable">nombres</th>
          <th class="center titulotable">cedula</th>
          <th class="center titulotable">correo</th>
          <th class="center titulotable">celular</th>
          <th class="center titulotable">provincia</th>
          <th class="center titulotable">ciudad</th>
          <th class="center titulotable">parroquia</th>
          <th class="center titulotable">agenciacercana</th>
          <th class="center titulotable">monto</th>
          <th class="center titulotable">actividadeco</th>
          <th class="center titulotable">f_creacion</th>

          <th class="titulotable">acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(a, i) in creditos" :key="a.id">
          <td>{{ i + 1 }}</td>
          <td>{{ a.nombres }}</td>
          <td>{{ a.cedula }}</td>
          <td>{{ a.correo }}</td>
          <td>{{ a.celular }}</td>
          <td>{{ a.provincia }}</td>
          <td>{{ a.ciudad }}</td>
          <td>{{ a.parroquia }}</td>
          <td>{{ a.agenciacercana }}</td>
          <td>{{ a.monto }}</td>
          <td>{{ a.actividadeconómica }}</td>
          <td>{{ a.f_creacion }}</td>

          <td>
            <button
              type="button"
              id="nuevoahorros"
              class=" margin bg-gray-500 hover:bg-gray-600 text-white btnw"
              @click.prevent="show"
              @keydown.tab.exact.prevent=""
            >
              Nuevo
            </button>

            <button
              type="button"
              id="editarahorros"
              class=" margin bg-gray-500 hover:bg-gray-600 text-white btnw"
              @click="show"
              @keydown.tab.exact.prevent=""
            >
              Editar
            </button>
            <button
              type="button"
              id="borrari"
              class=" margin bg-gray-500 hover:bg-gray-600 text-white btnw"
              @click="borrar(i)"
              @keydown.tab.exact.prevent=""
            >
              Eliminar
            </button>
          </td>
        </tr>
      </tbody>

      <!-- modal1 -->
      <modal
        name="modal-new-ahorros"
        :adaptive="true"
        transition="pop-out"
        :focus-trap="true"
        height="auto"
        :width="450"
        :scrollable="true"
        @opened="opened"
      >
        <div class="px-10 py-6">
          <h3 class="mb-2 text-xl">Nuevo Créditos</h3>
          <form>
            <div class="form-group mb-2">
              <label for="nombre" class="block font-normal text-xs mb-1"
                >Nombres
              </label>
              <input
                type="text"
                v-model="nombres"
                id="idnombres"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                ref="refnombres"
                name="nnombres"
              />
            </div>
            <div class="form-group mb-2">
              <label for="cedula" class="block font-normal text-xs mb-1"
                >Cedula</label
              >
              <input
                type="text"
                v-model="cedula"
                id="cedula"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="cedula"
              />
            </div>
            <div class="form-group mb-2">
              <label for="correo" class="block font-normal text-xs mb-1"
                >Correo Electrónico</label
              >
              <input
                type="correo"
                v-model="correo"
                id="correo"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                ref="correo"
                name="correo"
                @keydown.shift.tab.prevent=""
              />
            </div>
            <div class="form-group mb-2">
              <label for="celular" class="block font-normal text-xs mb-1"
                >Celular</label
              >
              <input
                type="number"
                v-model="celular"
                id="celular"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="celular"
                min="0"
                maxlength="10"
              />
            </div>
            <div class="form-group mb-2">
              <label for="provincia" class="block font-normal text-xs mb-1"
                >Provincia</label
              >
              <input
                type="text"
                v-model="provincia"
                id="provincia"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="provincia"
              />
            </div>
            <div class="form-group mb-2">
              <label for="ciudad" class="block font-normal text-xs mb-1"
                >Ciudad</label
              >
              <input
                type="text"
                v-model="ciudad"
                id="ciudad"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="ciudad"
              />
            </div>
            <div class="form-group mb-2">
              <label for="parroquia" class="block font-normal text-xs mb-1"
                >Parroquia</label
              >
              <input
                type="text"
                v-model="parroquia"
                id="parroquia"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="parroquia"
              />
            </div>
            <div class="form-group mb-2">
              <label for="agencia" class="block font-normal text-xs mb-1"
                >Agencia cercana</label
              >
              <input
                type="text"
                v-model="agencia"
                id="agencia"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="agencia"
              />
            </div>

            <div class="form-group mb-2">
              <label for="monto" class="block font-normal text-xs mb-1"
                >Monto</label
              >
              <input
                type="number"
                v-model="monto"
                id="monto"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="monto"
              />
            </div>

            <div class="form-group mb-2">
              <label for="ae" class="block font-normal text-xs mb-1"
                >Actividad Económica</label
              >
              <input
                type="text"
                v-model="economicActivity"
                id="economicActivity"
                class="border px-4 py-1 w-full rounded bg-gray-200"
                name="economicActivitys"
              />
            </div>

            <div class="form-group mb-2">
              <button
                type="button"
                id="guardar"
                class="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded w-full"
                @click="guardarCreditos()"
                @keydown.tab.exact.prevent=""
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </modal>
    </table>
  </div>
  <!-- fin dl modal -->
</template>

<script>
import api from "@/api/formularios";
export default {
  name: "adminCreditos",
  data() {
    return {
      names: "",
      identification: "",
      email: "",
      phone: "",
      mount: "",
      economicActivity: "",
      province: "",
      city: "",
      parish: "",
      agencyNear: "",

      creditos: [],
      nombres: "",
      cedula: "",
      correo: "",
      celular: "",
      provincia: "",
      ciudad: "",
      parroquia: "",
      agencia: "",
      f_creacion: "",
      monto: "",
      actividadeconómica: ""
    };
  },
  mounted() {
    this.getCreditos();
  },
  methods: {
    show() {
      this.$modal.show("modal-new-ahorros");
    },
    // showeditar() {
    //   this.$modal.show("modal-edit-inversiones");

    // },

    opened() {
      console.log("se abrio");
      this.$refs.nombre.focus();
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    guardarCreditos() {
      console.log("entra al saveahorro de la vue");

      if (
        this.nombres == "" ||
        this.cedula == "" ||
        this.correo == "" ||
        this.celular == "" ||
        this.monto == "" ||
        this.economicActivity == "" ||
        this.provincia == "" ||
        this.ciudad == "" ||
        this.parroquia == "" ||
        this.agencia == ""
      ) {
        console.log("campos vacios");
        this.$swal("Llene todo los campos", "", "error");
      } else {
        console.log("todo lleno a guardar");
        api
          .saveCreditos(
            this.nombres,
            this.cedula,
            this.correo,
            this.celular,
            this.monto,
            this.economicActivity,
            this.provincia,
            this.ciudad,
            this.parroquia,
            this.agencia
          )
          .then(resp => {
            console.log(resp);
            if (resp.status == "ok") {
              this.$swal("créditos Guardado", "", "success").then(() => {
                // this.$router.go(this.$router.currentRoute);
                (this.nombres = ""), (this.cedula = "");
                this.correo = "";
                this.celular = "";
                this.monto = "";
                this.economicActivity = "";
                this.provincia = "";
                this.ciudad = "";
                this.parroquia = "";
                this.agencia = "";
                location.reload();
              });
            } else {
              this.$swal("Error", "", "error");
            }
          });
      }
    },

    getCreditos() {
      console.log("entra a methods creditos");
      api.getCreditos().then(resp => {
        console.log("lista de creditos", resp);
        this.creditos = resp;
      });
    },

    // crear(i){

    // },
    editar(i) {
      api
        .actualziar(
          this.inversiones[i].nombres,
          this.inversiones[i].cedula,
          this.inversiones[i].correo,
          this.inversiones[i].celular,
          this.inversiones[i].provincia,
          this.inversiones[i].ciudadthis.inversiones[i].parroquia,
          this.inversiones[i].agenciacercana
        )
        .then(resp => {
          console.log(resp);
          if (resp.status == "ok") {
            alert("se actualizó ");
          } else {
            console.log("no se actualizó");
          }
        });
    },
    borrar(i) {
      //

      this.$swal
        .fire({
          title: "¿Está Seguro?",
          text: "Esta acción no se puede revertir",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Estoy Seguro!"
        })
        .then(result => {
          if (result.isConfirmed) {
            api.borrarCreditos(this.creditos[i].cedula).then(resp => {
              console.log("swal:resp", resp);
              if (resp.status == "ok")
                this.$swal
                  .fire("Borrado!", "Se borro con éxito", "success")
                  .then(() => {
                    location.reload();
                  });
            });
          }
        });
    }
  }
};
</script>
<style scoped>
.btnw {
  text-align: center;
  width: 100px;
  margin: 2px;
}
.margin {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.center {
  text-align: center;
  color: darkblue;
}
@media screen and (max-width: 640px) {
  #div1 {
    overflow: scroll;
    height: auto;
    width: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
