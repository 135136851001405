<template>
  <carousel
    :perPageCustom="[
      [380, 1],
      [768, 2]
    ]"
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :navigationEnabled="false"
    :autoplay-hover-pause="false"
    :loop="true"
    class="pb-10 px-5"
  >
    <!-- SLIDE 1 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/services/brands/western-union.png"
          alt="services"
          class="h-20 p-3 mx-auto"
        />
      </div>
    </slide>

    <!-- SLIDE 2 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/services/brands/facilito.png"
          alt="productos crediya"
          class="h-20 p-3 mx-auto"
        />
      </div>
    </slide>

    <!-- SLIDE 3 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/services/brands/austrogiros.png"
          alt="productos crediya"
          class="h-20 p-3 mx-auto"
        />
      </div>
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "Brands"
};
</script>

<style>
.VueCarousel-slide {
  width: 90px !important;
}
.VueCarousel-slide > div {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
