<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/payments/banner.png') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Pagos</h2>
        <p class="text-gray-100">Home / Pagos</p>
      </div>
    </div>

    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2">
          <div
            class="col-span-2 md:col-span-1 mb-4 order-2 md:order-1"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p class="text-gray-700 mb-0">Pagos</p>
            <h4 class="text-3xl text-gray-700 mb-4">Pago Ágil</h4>
            <p class="text-gray-700 italic font-semibold mb-4">
              Nunca fue tan fácil realizar todos tus pagos en un mismo lugar.
              Descubre todos los pagos que puedes hacer.
            </p>
            <p class="text-gray-600 mb-2 font-thin">
              Solo acercate a nuestras Agencia y realiza tu pago rápido, fácil y
              seguro.
            </p>
          </div>
          <div
            class="col-span-2 md:col-span-1 order-1 md:order-2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/img/payments/icono.png"
              class="float-center md:float-right m-auto"
              alt="credito crediya"
              width="300"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- CONTENT -->
    <section>
      <div class="container px-4 md:px-20">
        <!-- PAGO 1 -->
        <div
          class="grid grid-cols-2 pb-12 overflow-hidden"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white order-2 md:order-1"
          >
            <h5 class="text-gray-800 text-sm">Pagos</h5>
            <h4 class="text-gray-800 text-2xl ">Servicios Básicos</h4>
            <hr class="hr-per mt-5" />
            <div class="contenido-texto py-4">
              <ul class="list-disc ml-5">
                <div class="flex">
                  <div class="w-8/12">
                    <li>EMAPA</li>
                    <li>EPMAPS (AGUA QUITO)</li>
                    <li>MEER (LUZ QUITO)</li>
                    <li>FACTURA OTECEL (MOVISTAR)</li>
                    <li>COMBOS PREOAGO VIRTUAL MOVI</li>
                    <li>COMBOS PREPAGO VIRTUAL CLARO</li>
                    <li>EASA</li>
                  </div>
                  <div class="w-4/12">
                    <li>CNT</li>
                    <li>TV-CABLE</li>
                    <li>RECARGAS MOVI</li>
                    <li>FACTURA CLARO</li>
                    <li>RECARGAS CLARO</li>
                    <li>RECARGAS CNT</li>
                    <li>RECARGAS TUENTI</li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div class="col-span-2 md:col-span-1 order-1 md:order-2">
            <img src="@/assets/img/payments/imagen1.png" alt="pagos crediya" />
          </div>
        </div>

        <!-- PAGO 2 -->
        <div
          class="grid grid-cols-2 pb-12 overflow-hidden"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="col-span-2 md:col-span-1">
            <img src="@/assets/img/payments/imagen2.png" alt="pagos crediya" />
          </div>
          <div class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white">
            <h5 class="text-gray-800 text-sm">Pagos</h5>
            <h4 class="text-gray-800 text-2xl ">Servicios Estatales</h4>
            <hr class="hr-per mt-5" />
            <div class="contenido-texto py-4">
              <ul class="list-disc ml-5">
                <li>RISE</li>
                <li>SRI</li>
                <li>IESS</li>
                <li>PRESTAMOS IESS</li>
                <li>ANT (AGENCIA DE TRÁNSITO)</li>
                <li>MATRICULA VEHICULAR</li>
                <li>TRANSFERENCIA DOMINIO</li>
                <li>UCE (UNIVERSIDAD CENTRAL ECUADOR)</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- PAGO 3 -->
        <div
          class="grid grid-cols-2 pb-12 overflow-hidden"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white order-2 md:order-1"
          >
            <h5 class="text-gray-800 text-sm">Pagos</h5>
            <h4 class="text-gray-800 text-2xl ">Catálogos</h4>
            <hr class="hr-per mt-5" />
            <div class="contenido-texto py-4">
              <ul class="list-disc ml-5">
                <li>YANBAL</li>
                <li>AVON</li>
                <li>BELCORP</li>
                <li>NETLIFE</li>
                <li>HERBALIFE</li>
                <li>PYCA</li>
              </ul>
            </div>
          </div>
          <div class="col-span-2 md:col-span-1 order-1 md:order-2">
            <img src="@/assets/img/payments/imagen3.png" alt="pagos crediya" />
          </div>
        </div>

        <!-- PAGO 4 -->
        <div
          class="grid grid-cols-2 pb-12 overflow-hidden"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="col-span-2 md:col-span-1">
            <img src="@/assets/img/payments/imagen4.png" alt="pagos crediya" />
          </div>
          <div class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white">
            <h5 class="text-gray-800 text-sm">Pagos</h5>
            <h4 class="text-gray-800 text-2xl ">Depósitos</h4>
            <hr class="hr-per mt-5" />
            <div class="contenido-texto py-4">
              <ul class="list-disc ml-5">
                <li>DEPÓSITOS PRODUBANCO</li>
                <li>DEPÓSITOS BANCO RUMIÑAHUI</li>
                <li>DEPÓSITOS INSOTEC</li>
                <li>DEPÓSITOS BANCO PICHINCHA</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- PAGO 5 -->
        <div
          class="grid grid-cols-2 pb-12 overflow-hidden"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white order-2 md:order-1"
          >
            <h5 class="text-gray-800 text-sm">Pagos</h5>
            <h4 class="text-gray-800 text-2xl ">Tarjetas</h4>
            <hr class="hr-per mt-5" />
            <div class="contenido-texto py-4">
              <ul class="list-disc ml-5">
                <li>
                  PAGO DE TARJETAS BANCO PICHINCHA DINERS/VISA INTERDIN/DISCOVES
                </li>
                <li>PAGO DE TARJETAS PACIFICARD</li>
                <li>PAGO TARJETA D'PRATI</li>
              </ul>
            </div>
          </div>
          <div class="col-span-2 md:col-span-1 order-1 md:order-2">
            <img src="@/assets/img/payments/imagen5.png" alt="pagos crediya" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "WorkWithUs"
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.hr-per {
  border-color: #cd1e29;
  width: 117%;
  margin-left: -40px;
}
.hr-per::after {
  content: "";
  width: 190px;
  border: 2px solid #cd1e29;
  height: 2px;
  margin-left: 40px;
  margin-top: -4px;
  display: block;
}
</style>
