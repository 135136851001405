<template>
  <div style="width:100%;height:420px" class="relative">
    <slider ref="slider" :options="options" @slide="slide" class="relative">
      <!-- SLIDE 1 -->
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes01.jpg') +
          ')'
      }">
        <!-- <div
          v-if="show"
          class="animar container w-full md:w-1/3 overflow-hidden p-5"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5">
            Ahorro Plan
            <br />
            Futuro
          </h2>
          <p class="text-white text-sm whitespace-normal text-justify">
            Te permite cumplir ese sueño tan anhelado.
          </p>
          <router-link
            to="/producto/ahorro-plan-futuro"
            class="block md:inline-block bg-blue-700 text-white px-8 py-0 mt-4 hover:bg-blue-500 text-base cursor-pointer"
            >Más Información</router-link
          >
        </div> -->
      </slideritem>
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes08.png') +
          ')'
      }" />
      <!-- SLIDE 2 -->
      <slideritem class="bg-blue-600 banner-slider " :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes02.jpg') +
          ')'
      }">
        <!-- Este modal esta oculto-->
        <!--div 
          v-if="show2"
          class="animar-10 container w-full md:w-1/3 overflow-hidden p-5"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5"></h2>
          <p class="text-white text-sm text-justify"></p>

          <router-link
            to="/transparencia"
            class="block md:inline-block bg-blue-700 text-white px-8 py-0 mt-4 hover:bg-blue-500 text-base cursor-pointer"
            >Más Información</router-link
          >
        </div> -->
      </slideritem>

      <!-- SLIDE 3 -->
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes03.jpg') +
          ')'
      }">
        <!-- <div
          v-if="show3"
          class="animar-10 container w-full md:w-1/3 overflow-hidden p-5 z-10"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5">
            Wester Union
          </h2>
          <p class="text-white text-sm whitespace-normal text-justify">
            Ahora tus cobros puedes realizarlos en nuestras agencias.
          </p>
          <router-link
            to="/servicios"
            class="block md:inline-block bg-blue-700 text-white px-8 py-0 mt-4 hover:bg-blue-500 text-base cursor-pointer z-20"
            >Más Información</router-link
          >
        </div> -->
      </slideritem>

      <!-- SLIDE 4 -->
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes04.png') +
          ')'
      }">
        <!-- <div
          v-if="show4"
          class="animar container w-full md:w-1/3 overflow-hidden p-5"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5">
            Financiamos tus
            <br />
            Emprendimientos
          </h2>
          <p class="text-white text-sm whitespace-normal text-justify">
            Todos tus proyectos cúmplelos con Cooperativa Credil, siempre
            apoyando tus ideas.
          </p>
          <router-link
            to="/producto/credito-microcredito"
            class="block md:inline-block bg-blue-700 text-white px-8 py-0 mt-4 hover:bg-blue-500 text-base cursor-pointer z-20"
            >Más Información</router-link
          >
        </div> -->
      </slideritem>

      <!-- SLIDE 5 -->
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes05.jpg') +
          ')'
      }">
        <!-- <div
          v-if="show5"
          class="animar container w-full md:w-1/3 overflow-hidden p-5 z-10"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5">
            Invierte con
            <br />
            Nosotros
          </h2>
          <p class="text-white text-sm whitespace-normal text-justify">
            Tus inversiones están seguras y obtienes hasta el 11% de interés.
          </p>
          <router-link
            to="/producto/depositos-a-plazo-fijo"
            class="block md:inline-block bg-blue-700 text-white px-8 py-0 mt-4 hover:bg-blue-500 text-base cursor-pointer z-20"
            >Más Información</router-link
          >
        </div> -->
      </slideritem>

      <!-- SLIDE 6 -->
      <slideritem class="bg-blue-600 banner-slider" :style="{
        'background-image':
          'url(' +
          require('@/assets/img/home/banners/v2/bannersRedes06.jpg') +
          ')'
      }">
        <!--<div
          v-if="show6"
          class="animar container w-full md:w-1/3 overflow-hidden p-5 z-10"
        >
          <h2 class="text-white text-left font-semibold leading-10 mb-5">
            12 Años en el
            <br />
            Sector Financiero
          </h2>
          <p class="text-white text-sm whitespace-normal text-justify">
           Confía en Credil, una cooperativa de gran crecimiento. 
          </p>
        </div>-->
      </slideritem>
    </slider>
    <span class="arrows left" @click="left">
      <font-awesome-icon :icon="['fas', 'arrow-left']" class="text-white" />
    </span>
    <span class="arrows right" @click="right">
      <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-white" />
    </span>
  </div>
</template>
<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  data() {
    return {
      // someList
      someList: [],
      options: {
        currentPage: 0,
        thresholdDistance: 500,
        thresholdTime: 100,
        speed: 300,
        timingFunction: "ease",
        loop: true,
        autoplay: 5000
      },
      show: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false
    };
  },

  components: {
    slider,
    slideritem
  },
  methods: {
    slide(data) {
      // console.log("slide actual", data.currentPage);
      if (data.currentPage == 0) {
        this.show = true;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.show7 = false;
      }
      if (data.currentPage == 1) {
        this.show = false;
        this.show2 = true;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.show7 = false;
      }
      if (data.currentPage == 2) {
        this.show = false;
        this.show2 = false;
        this.show3 = true;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.show7 = false;
      }
      if (data.currentPage == 3) {
        this.show = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = true;
        this.show5 = false;
        this.show6 = false;
        this.show7 = false;
      }
      if (data.currentPage == 4) {
        this.show = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = true;
        this.show6 = false;
        this.show7 = false;
      }
      if (data.currentPage == 5) {
        this.show = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = true;
        this.show7 = false;
      }
      if (data.currentPage == 6) {
        this.show = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.show7 = true;
      }
    },

    left() {
      this.$refs["slider"] && this.$refs["slider"].$emit("slidePre");
    },
    right() {
      this.$refs["slider"] && this.$refs["slider"].$emit("slideNext");
    }
  }
};
</script>

<style>
.banner-slider {
  background-position: center;

  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  background-size: 100% 425px;
}

/* animar */

.animar {
  animation-name: derecha;
  animation-duration: 4s;
  transform: translateX(-60%);
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 690px) {
  .animar {
    transform: translateX(0%);
  }
}

@keyframes derecha {
  0% {
    transform: translateX(-70%);
    opacity: 0;
  }

  100% {
    transform: translateX(-60%);
    opacity: 1;
  }
}

.animar-10 {
  animation-name: derecha-10;
  animation-duration: 4s;
  transform: translateX(0%);
  background: rgba(0, 0, 0, 0.4);
}

@keyframes derecha-10 {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.arrows {
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 400 !important;
}

.arrows.left {
  top: 40%;
  left: 10px;
}

.arrows.right {
  top: 40%;
  right: 100px;
}
</style>
