<template>
  <main style="background-color: #f5f5f5;" class="pb-10">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/services/covenants/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Convenios</h2>
        <p class="text-gray-100">Home / Convenios</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="titular mt-5">
        <h4 class="text-3xl azul mb-2 font-bold text-center">
          Beneficio Principal
        </h4>
        <p class="text-center mx-auto px-4 md:px-12  azul w-full md:w-7/12">
          El compromiso con nuestros Socios, nos alienta a ofrecerles muchos
          beneficios al formar parte de Cooperativa CREDIL, a continuación
          detallamos los convenios que pueden disponer.
        </p>
      </div>

      <!-- CONVENIOS ELEMENTOS -->
      <div class="container mt-12  px-4 md:px-20">
        <!-- CONVENIO 1 -->
        <div class="grid grid-cols-7 relative  bg-blue-700 ml-0 md:ml-32">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-2 rounded-full  bg-white ml-0 md:-ml-32 my-2"
            style="width:320px; height:320px;"
          >
            <img
              src="@/assets/img/services/logos/logo2.png"
              alt="beneficios credil"
              class="p-5 "
            />
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-4 py-16 px-3">
            <h4 class="text-white text-3xl mb-2 font-bold">Comercial Ortiz</h4>
            <p class="text-white">
              Es una empresa que se dedica a la venta de cerámica y grifería en
              marcas nacionales e importadas.
            </p>
            <div class="flex mt-3">
              <span class="font-semibold  text-white">Beneficios:</span>
              <div class="container ml-4">
                <ul class="list-disc px-5">
                  <li class="text-white">
                    Financiamiento de compras 100%.
                  </li>
                  <li class="text-white">
                    Apertura de cuenta de Ahorros con $5 para socios.
                  </li>
                  <li class="text-white">
                    Capacitaciones Financieras a Clientes y Empleados
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 2 -->
        <div class="grid grid-cols-7 relative  bg-blue-700 mr-0 md:mr-32 mt-10">
          <!-- Col 1 -->
          <div class="col-span-7 md:col-span-5 md:col-start-2 py-16 px-3">
            <h4 class="text-white text-3xl mb-2 font-bold">RIOLAC</h4>
            <p class="text-white">
              Asesoramiento y apoyo a pequeños, medianos y grandes productores,
              mediante la comercialización de insumos, equipos y maquinarias
              para la industria agroalimentaria, producción de yogurt, leche,
              mermelada, chocolate, entre otros
            </p>
            <div class="flex mt-3">
              <span class="font-semibold  text-white">Beneficios:</span>
              <div class="container ml-4">
                <ul class="list-disc px-5">
                  <li class="text-white">
                    Financiamiento hasta el 100% de compras de equipos y
                    maquinaria.
                  </li>
                  <li class="text-white">
                    Apertura de cuenta de Ahorros con $5 para socios.
                  </li>
                  <li class="text-white">
                    Capacitaciones Financieras a Clientes y Empleados
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div
            class="col-span-7 md:col-span-1 rounded-full  bg-white  my-2"
            style="width:320px; height:320px;"
          >
            <img
              src="@/assets/img/services/logos/logo1.png"
              alt="beneficios credil"
              class="p-5 "
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Convenios"
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 30%;
}
.gris {
  color: #575757;
}
</style>
