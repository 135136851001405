<template>
  <!-- DROPBOX -->
  <div class="relative" @mouseover="showDrop" @mouseleave="hideDrop">
    <button
      @click="toggleMenu"
      class="block mt-4 lg:inline-block lg:mt-0 text-white  hover:text-gray-400  mr-4"
    >
      Quienes Somos
    </button>
    <div class="absolute w-full">&nbsp;</div>
    <div
      v-if="open"
      class="absolute w-44 bg-white border-b z-10 p-3  rounded shadow ml-0 md:-ml-10 "
    >
      <router-link
        to="/nosotros"
        class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Nosotros</router-link
      >
      <router-link
        to="/gobernanza"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Gobernanza</router-link
      >
      <router-link
        to="/buzon-sugerencias"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Buzón de Sugerencias</router-link
      >
      <router-link
        to="/trabaja-con-nosotros"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Trabaja con Nosotros</router-link
      >
      <router-link
        to="/transparencia"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Transparencia</router-link
      >
      <router-link
        to="/convenios"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Convenios</router-link
      >
      <router-link
        to="/proteccionDatos"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Protección de Datos</router-link
      >
      <router-link
        to="/derechosTitular"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white "
        >Derechos del titular</router-link
      >
    </div>
  </div>
  <!-- DROPBOX END -->
</template>
<script>
export default {
  name: "QuienesSomos",
  data() {
    return {
      open: false
    };
  },
  methods: {
    showDrop() {
      this.open = true;
    },
    hideDrop() {
      this.open = false;
    },
    toggleMenu() {
      this.open = !this.open;
    }
  }
};
</script>
