<template>
  <carousel
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :navigationEnabled="false"
    :autoplay-hover-pause="false"
    :loop="true"
    :perPage="1"
    :perPageCustom="[
      [320, 1],
      [720, 2],
      [1024, 3]
    ]"
    class="pb-10 px-5"
  >
    <!-- SLIDE 1 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/home/cliente.png"
          alt="productos crediya"
          class="h-20 p-3"
        />
        <div class="letras flex flex-col content-center justify-center mb-3">
          <p class="azul font-semibold ">Bayas Guadalupe</p>
          <p class="azul text-sm ">Ambato - Mayorista</p>
        </div>
      </div>
      <hr class="border-blue-700" />
      <img
        src="@/assets/img/home/comillas.png"
        alt="comillas credil"
        class="h-16 -mt-3 float-right mr-4"
      />
      <img
        src="@/assets/img/home/iconoESTRELLAS.png"
        alt="estrellas credil"
        class="h-10 mt-5"
      />
      <p class="azul text-justify m-5">
        Ha sido un gran apoyo para fortalecer mi negocio ya que me dieron las
        facilidades para acceder a un crédito de forma inmediata. Les recomiendo
        trabajar con la cooperativa. CREDIL TU FORTALEZA FINANCIERA
      </p>
    </slide>

    <!-- SLIDE 2 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/home/cliente4.png"
          alt="productos crediya"
          class="h-20 p-3"
        />
        <div class="letras flex flex-col content-center justify-center mb-3">
          <p class="azul font-semibold ">Cunalata Washington</p>
          <p class="azul text-sm ">Riobamba</p>
        </div>
      </div>
      <hr class="border-blue-700" />
      <img
        src="@/assets/img/home/comillas.png"
        alt="comillas credil"
        class="h-16 -mt-3 float-right mr-4"
      />
      <img
        src="@/assets/img/home/iconoESTRELLAS.png"
        alt="estrellas credil"
        class="h-10 mt-5"
      />
      <p class="azul text-justify m-5">
        Gracias al asesoramiento de la cooperativa CREDIL, mantengo mis ahorros
        en la institución los cuales crece confiablemente, para tener un fondo
        de jubilación.
      </p>
    </slide>

    <!-- SLIDE 3 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/home/cliente5.png"
          alt="productos crediya"
          class="h-20 p-3"
        />
        <div class="letras flex flex-col content-center justify-center mb-3">
          <p class="azul font-semibold ">José María Sacon</p>
          <p class="azul text-sm ">Ambato - Izamba</p>
        </div>
      </div>
      <hr class="border-blue-700" />
      <img
        src="@/assets/img/home/comillas.png"
        alt="comillas credil"
        class="h-16 -mt-3 float-right mr-4"
      />
      <img
        src="@/assets/img/home/iconoESTRELLAS.png"
        alt="estrellas credil"
        class="h-10 mt-5"
      />
      <p class="azul text-justify m-5">
        La cooperativa me ha favorecido, hasta la fecha, y sigo trabajando me ha
        ayudado con créditos para sembrar, si no me ayudaran sería difícil,
        estos préstamos me han beneficiado para sembríos, abonos. Gracias por
        impulsar mi negocio.
      </p>
    </slide>

    <!-- SLIDE 4 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center">
        <img
          src="@/assets/img/home/cliente2.png"
          alt="productos crediya"
          class="h-20 p-3"
        />
        <div class="letras flex flex-col content-center justify-center mb-3">
          <p class="azul font-semibold ">Mentor Pico</p>
          <p class="azul text-sm ">Ambato - Centro</p>
        </div>
      </div>
      <hr class="border-blue-700" />
      <img
        src="@/assets/img/home/comillas.png"
        alt="comillas credil"
        class="h-16 -mt-3 float-right mr-4"
      />
      <img
        src="@/assets/img/home/iconoESTRELLAS.png"
        alt="estrellas credil"
        class="h-10 mt-5"
      />
      <p class="azul text-justify m-5">
        Quiero agradecer a la cooperativa porque siempre nos ha estado ayudando
        económicamente en créditos para educación, para las herramientas de
        trabajo, siempre nos ha estado colaborando. De igual manera quiero
        decirle a las personas que se acerquen a la cooperativa para que ellos
        nos ayuden con créditos.
      </p>
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "HomeCarrouselPrincipal"
};
</script>

<style>
/* .VueCarousel-slide {
  width: 90px !important;
} */
.VueCarousel-slide > div {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
