import axios from "axios";
import url from "./url";
import https from "https";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
    requestCert: true
  })
});

// CONSULTAR SIMULATION
function consultSimulation(tipoCredito, cantidad, plazo, amortizacion) {
  return instance
    .post(`${url}/simulador/consulta-simulador`, {
      tipoCredito,
      cantidad,
      plazo,
      amortizacion
    })
    .then(resp => resp.data);
}

// CONSULT INVERSION
function consultInversion(cantidad, plazo, tipoContribuyente) {
  plazo = plazo * 30;
  return instance
    .post(`${url}/simulador/consulta-simulador-fijo`, {
      cantidad,
      plazo,
      tipoContribuyente
    })
    .then(resp => resp.data);
}

export default {
  consultSimulation,
  consultInversion
};
