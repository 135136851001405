<template>
  <div class="bg-azul text-center rounded-b-lg">
    <img :src="image" alt="servicios credil" class="mx-auto h-20 pt-5" />
    <h2 class="text-white font-semibold text-2xl -mt-3 mb-4">{{ title }}</h2>
    <div class="bg-white p-6 rounded-lg ">
      <ul class="list-disc">
        <li
          class="azul ml-1 text-left text-sm"
          v-for="a in text.split(',')"
          :key="a._id"
        >
          {{ a }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "service",
  props: ["title", "img", "text"],
  data() {
    return {
      image: require(`@/assets/img/services/${this.img}`)
    };
  }
};
</script>
