<template>
  <div>
    <a
      href="#"
      class="bg-blue-400 hover:bg-blue-300 px-6 py-2 rounded text-white text-copy-primary"
      @click.prevent="show"
      >Nuevo usuario</a
    >
    <modal
      name="modal-new-user"
      :adaptive="true"
      transition="pop-out"
      :focus-trap="true"
      height="auto"
      :width="450"
      :scrollable="true"
      @opened="opened"
    >
      <div class="px-10 py-6">
        <h3 class="mb-2 text-xl">Crear nuevo Usuario</h3>
        <form>
          <div class="form-group mb-2">
            <label for="nombre" class="block font-normal text-xs mb-1"
              >Nombre
            </label>
            <input
              type="text"
              v-model="nombre"
              id="nombre"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              ref="nombre"
              name="nombre"
            />
          </div>
          <div class="form-group mb-2">
            <label for="apellido" class="block font-normal text-xs mb-1"
              >Apellido</label
            >
            <input
              type="text"
              v-model="apellido"
              id="apellido"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="apellido"
            />
          </div>
          <div class="form-group mb-2">
            <label for="email" class="block font-normal text-xs mb-1"
              >Correo Electrónico</label
            >
            <input
              type="email"
              v-model="email"
              id="email"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              ref="email"
              name="email"
              @keydown.shift.tab.prevent=""
            />
          </div>
          <div class="form-group mb-2">
            <label for="password" class="block font-normal text-xs mb-1"
              >Contraseña</label
            >
            <input
              type="text"
              v-model="password"
              id="password"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="password"
            />
          </div>
          <div class="form-group mb-2">
            <label for="cedula" class="block font-normal text-xs mb-1"
              >Cédula</label
            >
            <input
              type="number"
              v-model="ci"
              id="cedula"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="cedula"
              min="0"
              maxlength="10"
            />
          </div>
          <div class="form-group mb-2">
            <label for="celular" class="block font-normal text-xs mb-1"
              >Celular</label
            >
            <input
              type="number"
              v-model="celular"
              id="celular"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="celular"
              min="0"
              maxlength="10"
            />
          </div>
          <div class="form-group mb-2">
            <label for="ciudad" class="block font-normal text-xs mb-1"
              >Ciudad</label
            >
            <input
              type="text"
              v-model="ciudad"
              id="ciudad"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="ciudad"
            />
          </div>
          <div class="form-group mb-2">
            <label for="agencia" class="block font-normal text-xs mb-1"
              >Agencia</label
            >
            <input
              type="text"
              v-model="agencia"
              id="agencia"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="agencia"
            />
          </div>
          <div class="form-group mb-4">
            <label for="tipo" class="block font-normal text-xs mb-1"
              >Tipo de Usuario</label
            >
            <select
              name="tipo"
              v-model="type"
              id="tipo"
              class="border text-sm px-4 py-2 w-full rounded bg-gray-200"
            >
              <option value="2">Usuario</option>
              <option value="1">Administrador</option>
            </select>
          </div>
          <div class="form-group mb-2">
            <button
              type="button"
              id="guardar"
              class="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded w-full"
              @click="saveUser"
              @keydown.tab.exact.prevent=""
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "@/api/user";
export default {
  name: "MyComponent",
  data() {
    return {
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      ci: "",
      celular: "",
      ciudad: "",
      agencia: "",
      type: ""
    };
  },
  methods: {
    show() {
      this.$modal.show("modal-new-user");
    },
    opened() {
      console.log("se abrio");
      this.$refs.nombre.focus();
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    saveUser() {
      if (
        this.nombre == "" ||
        this.apellido == "" ||
        this.email == "" ||
        this.password == "" ||
        this.ci == "" ||
        this.celular == "" ||
        this.ciudad == "" ||
        this.agencia == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        api
          .saveUser(
            this.nombre,
            this.apellido,
            this.email,
            this.password,
            this.ci,
            this.celular,
            this.ciudad,
            this.agencia,
            this.type
          )
          .then(resp => {
            console.log(resp);
            if (resp.status == "ok") {
              this.$modal.hide("modal-new-user");
              this.$swal("Usuario Guardado", "", "success");
            }
          });
      }
    }
  },
  mount() {
    this.show();
  }
};
</script>
<style>
.v--modal {
  overflow-y: auto !important;
}
</style>
