import Vue from "vue";
import Router from "vue-router";
import store from "./store";

// VISTAS
import Home from "@/views/Home";

// HOW WE ARE
import About from "@/views/howWeAre/About";
import Governance from "@/views/howWeAre/Governance";
import WorkWithUs from "@/views/howWeAre/WorkWithUs";
import AdminWorkWithUs from "@/views/howWeAre/AdminWorkWithUs";

// leninadministrativa
import sheets from "@/views/subida/sheets";
import subida from "@/views/subida/subida";
// import login from "@/views/subida/login";

//lenin1.
import CreditRequest from "@/views/howWeAre/CreditRequest";
import SaveAccountRequest from "@/views/howWeAre/SaveAccountRequest";
import RequestInvestment from "@/views/howWeAre/RequestInvestment";
import Solicitudes from "@/views/howWeAre/Solicitudes";
import Inquire from "@/views/inquiries/inquiries";

import adminCreditos from "@/views/adminsolicitudes/admincreditos";
import adminAhorros from "@/views/adminsolicitudes/adminahorros";
import adminInversiones from "@/views/adminsolicitudes/admininversiones";

//
import Responsability from "@/views/howWeAre/Responsability";
import Transparency from "@/views/howWeAre/Transparency";
import MailBoxSuggestions from "@/views/howWeAre/MailBoxSuggestions";

// PRODUCTS
import Product from "@/views/Product";
import NewProduct from "@/components/products/NewProduct";
import ListProducts from "@/components/products/ListProducts";
import EditProduct from "@/components/products/EditProduct";

// SIMULATORS
import SimulatorCredit from "@/views/simulators/Credit";
import SimulatorInversion from "@/views/simulators/Inversion";

// SERVICES
import Convenios from "@/views/servicios/Convenios";
import Payments from "@/views/servicios/Payments";
import Services from "@/views/servicios/Services";

import Contacts from "@/views/Contacts";
import Login from "@/views/Login";
import Usuario from "@/views/users/User";
import DataProtection from "./views/howWeAre/DataProtection.vue";
import RightsOwner from "./views/howWeAre/RightsOwner.vue";
// import Error from '@/views/Error'

Vue.use(Router);

// export default new Router({
var router = new Router({
  mode: "history",
  routes: [
    {
      path: "/admin/sheets",
      name: "sheets",
      component: sheets,
      meta: {
        libre: true
      }
    },

    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        libre: true
      }
    },

    {
      path: "/admin/subida",
      name: "subida",
      component: subida,
      meta: {
        libre: true
      }
    },
    // {
    //   path: "/admin/login",
    //   name: "login",
    //   component: login,
    //   meta: {
    //     libre: true
    //   }
    // },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        libre: true
      }
    },
    // HOW WE ARE

    {
      path: "/nosotros",
      name: "nosotros",
      component: About,
      meta: {
        libre: true
      }
    },
    {
      path: "/trabaja-con-nosotros",
      name: "trabaja",
      component: WorkWithUs,
      meta: {
        libre: true
      }
    },
    {
      path: "/admin-trabaja-con-nosotros",
      name: "admin-trabaja",
      component: AdminWorkWithUs,
      meta: {
        libre: true
      }
    },
    {
      path: "/formulario-credito",
      name: "formulario-credito",
      component: CreditRequest,
      meta: {
        libre: true
      }
    },
    {
      path: "/formulario-ahorro",
      name: "formulario-ahorro",
      component: SaveAccountRequest,
      meta: {
        libre: true
      }
    },
    {
      path: "/formulario-inversiones",
      name: "formulario-inversiones",
      component: RequestInvestment,
      meta: {
        libre: true
      }
    },
    {
      path: "/solicitudes",
      name: "solicitudes",
      component: Solicitudes,
      meta: {
        libre: true
      }
    },
    {
      path: "/gobernanza",
      name: "gobernanza",
      component: Governance,
      meta: {
        libre: true
      }
    },
    {
      path: "/responsabilidad-social",
      name: "responsabilidad",
      component: Responsability,
      meta: {
        libre: true
      }
    },
    {
      path: "/buzon-sugerencias",
      name: "sugerencias",
      component: MailBoxSuggestions,
      meta: {
        libre: true
      }
    },
    {
      path: "/transparencia",
      name: "transparencia",
      component: Transparency,
      meta: {
        libre: true
      }
    },
    {
      path: "/proteccionDatos",
      name: "proteccionDatos",
      component: DataProtection,
      meta: {
        libre: true
      }
    },
    {
      path: "/derechosTitular",
      name: "derechosTitular",
      component: RightsOwner,
      meta: {
        libre: true
      }
    },

    // SERVICES
    {
      path: "/servicios",
      name: "servicios",
      component: Services,
      meta: {
        libre: true
      }
    },
    {
      path: "/convenios",
      name: "convenios",
      component: Convenios,
      meta: {
        libre: true
      }
    },
    {
      path: "/pagos",
      name: "pagos",
      component: Payments,
      meta: {
        libre: true
      }
    },

    // PRODUCTS
    {
      path: "/producto/:id",
      name: "producto",
      component: Product,
      meta: {
        libre: true
      }
    },
    {
      path: "/nuevo-producto",
      name: "nuevo-producto",
      component: NewProduct,
      meta: {
        admin: true
      }
    },
    {
      path: "/lista-productos",
      name: "lista-productos",
      component: ListProducts,
      meta: {
        libre: true
      }
    },
    {
      path: "/editar-producto/:id",
      name: "editar-productos",
      component: EditProduct,
      meta: {
        libre: true
      }
    },

    // SIMULATORS
    {
      path: "/simulador-credito",
      name: "simulador-credito",
      component: SimulatorCredit,
      meta: {
        libre: true
      }
    },
    {
      path: "/simulador-inversiones",
      name: "simulador-inversiones",
      component: SimulatorInversion,
      meta: {
        libre: true
      }
    },
    {
      path: "/usuario",
      name: "usuario",
      component: Usuario,
      meta: {
        admin: true
      }
    },
    {
      path: "/contactos",
      name: "contactos",
      component: Contacts,
      meta: {
        libre: true
      }
    },
    {
      path: "/inquiries",
      name: "inquiries",
      component: Inquire,
      meta: {
        libre: true
      }
    },
    {
      path: "/admin/ahorros",
      name: "adminahorro",
      component: adminAhorros,
      meta: {
        libre: true
      }
    },
    {
      path: "/admin/creditos",
      name: "adminahorro",
      component: adminCreditos,
      meta: {
        libre: true
      }
    },
    {
      path: "/admin/inversiones",
      name: "admininversiones",
      component: adminInversiones,
      meta: {
        libre: true
      }
    }

    // {
    //   path: '/coin/:id',
    //   name: 'coin-detail',
    //   component: CoinDetail
    // },

    // {
    //   path: '*',
    //   name: 'error',
    //   component: Error
    // }
  ]
});

// ROUTES CONTROL
router.beforeEach((to, from, next) => {
  // console.log("router", to)
  // console.log("store", store.state)
  if (to.matched.some(record => record.meta.libre)) {
    next();
  } else if (store.state.user && store.state.user.type == "1") {
    console.log("contenido store", store);
    if (to.matched.some(record => record.meta.admin)) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});
export default router;
