export const provincias = [
  {
    provincia: "AZUAY",
    cantones: [
      {
        canton: "CUENCA",
        parroquias: [
          { parroquia: "BELLAVISTA" },
          { parroquia: "CAÑARIBAMBA" },
          { parroquia: "EL BATÁN" },
          { parroquia: "EL SAGRARIO" },
          { parroquia: "EL VECINO" },
          { parroquia: "GIL RAMÍREZ DÁVALOS" },
          { parroquia: "HUAYNACÁPAC" },
          { parroquia: "MACHÁNGARA" },
          { parroquia: "MONAY" },
          { parroquia: "SAN BLAS" },
          { parroquia: "SAN SEBASTIÁN" },
          { parroquia: "SUCRE" },
          { parroquia: "TOTORACOCHA" },
          { parroquia: "YANUNCAY" },
          { parroquia: "HERMANO MIGUEL" },
          { parroquia: "CUENCA" },
          { parroquia: "BAÑOS" },
          { parroquia: "CUMBE" },
          { parroquia: "CHAUCHA" },
          { parroquia: "CHECA (JIDCAY)" },
          { parroquia: "CHIQUINTAD" },
          { parroquia: "LLACAO" },
          { parroquia: "MOLLETURO" },
          { parroquia: "NULTI" },
          { parroquia: "OCTAVIO CORDERO PALACIOS (SANTA ROSA)" },
          { parroquia: "PACCHA" },
          { parroquia: "QUINGEO" },
          { parroquia: "RICAURTE" },
          { parroquia: "SAN JOAQUÍN" },
          { parroquia: "SANTA ANA" },
          { parroquia: "SAYAUSÍ" },
          { parroquia: "SIDCAY" },
          { parroquia: "SININCAY" },
          { parroquia: "TARQUI" },
          { parroquia: "TURI" },
          { parroquia: "VALLE" },
          { parroquia: "VICTORIA DEL PORTETE (IRQUIS)" }
        ]
      },
      {
        canton: "GIRÓN",
        parroquias: [
          { parroquia: "GIRÓN" },
          { parroquia: "ASUNCIÓN" },
          { parroquia: "SAN GERARDO" }
        ]
      },
      {
        canton: "GUALACEO",
        parroquias: [
          { parroquia: "GUALACEO" },
          { parroquia: "CHORDELEG" },
          { parroquia: "DANIEL CÓRDOVA TORAL (EL ORIENTE)" },
          { parroquia: "JADÁN" },
          { parroquia: "MARIANO MORENO" },
          { parroquia: "PRINCIPAL" },
          { parroquia: "REMIGIO CRESPO TORAL (GÚLAG)" },
          { parroquia: "SAN JUAN" },
          { parroquia: "ZHIDMAD" },
          { parroquia: "LUIS CORDERO VEGA" },
          { parroquia: "SIMÓN BOLÍVAR (CAB. EN GAÑANZOL)" }
        ]
      },
      {
        canton: "NABÓN",
        parroquias: [
          { parroquia: "NABÓN" },
          { parroquia: "COCHAPATA" },
          { parroquia: "EL PROGRESO (CAB.EN ZHOTA)" },
          { parroquia: "LAS NIEVES (CHAYA)" },
          { parroquia: "OÑA" }
        ]
      },
      {
        canton: "PAUTE",
        parroquias: [
          { parroquia: "PAUTE" },
          { parroquia: "AMALUZA" },
          { parroquia: "BULÁN (JOSÉ VÍCTOR IZQUIERDO)" },
          { parroquia: "CHICÁN (GUILLERMO ORTEGA)" },
          { parroquia: "EL CABO" },
          { parroquia: "GUACHAPALA" },
          { parroquia: "GUARAINAG" },
          { parroquia: "PALMAS" },
          { parroquia: "PAN" },
          { parroquia: "SAN CRISTÓBAL (CARLOS ORDÓÑEZ LAZO)" },
          { parroquia: "SEVILLA DE ORO" },
          { parroquia: "TOMEBAMBA" },
          { parroquia: "DUG DUG" }
        ]
      },
      {
        canton: "PUCARA",
        parroquias: [
          { parroquia: "PUCARÁ" },
          { parroquia: "CAMILO PONCE ENRÍQUEZ (CAB. EN RÍO 7 DE MOLLEPONGO)" },
          { parroquia: "SAN RAFAEL DE SHARUG" }
        ]
      },
      {
        canton: "SAN FERNANDO",
        parroquias: [{ parroquia: "SAN FERNANDO" }, { parroquia: "CHUMBLÍN" }]
      },
      {
        canton: "SANTA ISABEL",
        parroquias: [
          { parroquia: "SANTA ISABEL (CHAGUARURCO)" },
          { parroquia: "ABDÓN CALDERÓN (LA UNIÓN)" },
          { parroquia: "EL CARMEN DE PIJILÍ" },
          { parroquia: "ZHAGLLI (SHAGLLI)" },
          { parroquia: "SAN SALVADOR DE CAÑARIBAMBA" }
        ]
      },
      {
        canton: "SIGSIG",
        parroquias: [
          { parroquia: "SIGSIG" },
          { parroquia: "CUCHIL (CUTCHIL)" },
          { parroquia: "GIMA" },
          { parroquia: "GUEL" },
          { parroquia: "LUDO" },
          { parroquia: "SAN BARTOLOMÉ" },
          { parroquia: "SAN JOSÉ DE RARANGA" }
        ]
      },
      {
        canton: "OÑA",
        parroquias: [
          { parroquia: "SAN FELIPE DE OÑA CABECERA CANTONAL" },
          { parroquia: "SUSUDEL" }
        ]
      },
      {
        canton: "CHORDELEG",
        parroquias: [
          { parroquia: "CHORDELEG" },
          { parroquia: "PRINCIPAL" },
          { parroquia: "LA UNIÓN" },
          { parroquia: "LUIS GALARZA ORELLANA (CAB.EN DELEGSOL)" },
          { parroquia: "SAN MARTÍN DE PUZHIO" }
        ]
      },
      {
        canton: "EL PAN",
        parroquias: [
          { parroquia: "EL PAN" },
          { parroquia: "AMALUZA" },
          { parroquia: "PALMAS" },
          { parroquia: "SAN VICENTE" }
        ]
      },
      {
        canton: "SEVILLA DE ORO",
        parroquias: [
          { parroquia: "SEVILLA DE ORO" },
          { parroquia: "AMALUZA" },
          { parroquia: "PALMAS" }
        ]
      },
      {
        canton: "GUACHAPALA",
        parroquias: [{ parroquia: "GUACHAPALA" }]
      },
      {
        canton: "CAMILO PONCE ENRÍQUEZ",
        parroquias: [
          { parroquia: "CAMILO PONCE ENRÍQUEZ" },
          { parroquia: "EL CARMEN DE PIJILÍ" }
        ]
      }
    ]
  },
  {
    provincia: "BOLIVAR",
    cantones: [
      {
        canton: "GUARANDA",
        parroquias: [
          { parroquia: "ÁNGEL POLIBIO CHÁVES" },
          { parroquia: "GABRIEL IGNACIO VEINTIMILLA" },
          { parroquia: "GUARANDA" },
          { parroquia: "FACUNDO VELA" },
          { parroquia: "GUANUJO" },
          { parroquia: "JULIO E. MORENO (CATANAHUÁN GRANDE)" },
          { parroquia: "LAS NAVES" },
          { parroquia: "SALINAS" },
          { parroquia: "SAN LORENZO" },
          { parroquia: "SAN SIMÓN (YACOTO)" },
          { parroquia: "SANTA FÉ (SANTA FÉ)" },
          { parroquia: "SIMIÁTUG" },
          { parroquia: "SAN LUIS DE PAMBIL" }
        ]
      },
      {
        canton: "CHILLANES",
        parroquias: [
          { parroquia: "CHILLANES" },
          { parroquia: "SAN JOSÉ DEL TAMBO (TAMBOPAMBA)" }
        ]
      },
      {
        canton: "CHIMBO",
        parroquias: [
          { parroquia: "SAN JOSÉ DE CHIMBO" },
          { parroquia: "ASUNCIÓN (ASANCOTO)" },
          { parroquia: "CALUMA" },
          { parroquia: "MAGDALENA (CHAPACOTO)" },
          { parroquia: "SAN SEBASTIÁN" },
          { parroquia: "TELIMBELA" }
        ]
      },
      {
        canton: "ECHEANDÍA",
        parroquias: [{ parroquia: "ECHEANDÍA" }]
      },
      {
        canton: "SAN MIGUEL",
        parroquias: [
          { parroquia: "SAN MIGUEL" },
          { parroquia: "BALSAPAMBA" },
          { parroquia: "BILOVÁN" },
          { parroquia: "RÉGULO DE MORA" },
          { parroquia: "SAN PABLO (SAN PABLO DE ATENAS)" },
          { parroquia: "SANTIAGO" },
          { parroquia: "SAN VICENTE" }
        ]
      },
      {
        canton: "CALUMA",
        parroquias: [
          {
            parroquia: "CALUMA"
          }
        ]
      },
      {
        canton: "LAS NAVES",
        parroquias: [
          { parroquia: "LAS MERCEDES" },
          { parroquia: "LAS NAVES" },
          { parroquia: "LAS NAVES" }
        ]
      }
    ]
  },
  {
    provincia: "CAÑAR",
    cantones: [
      {
        canton: "AZOGUES",
        parroquias: [
          { parroquia: "AURELIO BAYAS MARTÍNEZ" },
          { parroquia: "AZOGUES" },
          { parroquia: "BORRERO" },
          { parroquia: "SAN FRANCISCO" },
          { parroquia: "AZOGUES" },
          { parroquia: "COJITAMBO" },
          { parroquia: "DÉLEG" },
          { parroquia: "GUAPÁN" },
          { parroquia: "JAVIER LOYOLA (CHUQUIPATA)" },
          { parroquia: "LUIS CORDERO" },
          { parroquia: "PINDILIG" },
          { parroquia: "RIVERA" },
          { parroquia: "SAN MIGUEL" },
          { parroquia: "SOLANO" },
          { parroquia: "TADAY" }
        ]
      },
      {
        canton: "BIBLIÁN",
        parroquias: [
          { parroquia: "BIBLIÁN" },
          { parroquia: "NAZÓN (CAB. EN PAMPA DE DOMÍNGUEZ)" },
          { parroquia: "SAN FRANCISCO DE SAGEO" },
          { parroquia: "TURUPAMBA" },
          { parroquia: "JERUSALÉN" }
        ]
      },
      {
        canton: "CAÑAR",
        parroquias: [
          { parroquia: "CAÑAR" },
          { parroquia: "CHONTAMARCA" },
          { parroquia: "CHOROCOPTE" },
          { parroquia: "GENERAL MORALES (SOCARTE)" },
          { parroquia: "GUALLETURO" },
          { parroquia: "HONORATO VÁSQUEZ (TAMBO VIEJO)" },
          { parroquia: "INGAPIRCA" },
          { parroquia: "JUNCAL" },
          { parroquia: "SAN ANTONIO" },
          { parroquia: "SUSCAL" },
          { parroquia: "TAMBO" },
          { parroquia: "ZHUD" },
          { parroquia: "VENTURA" },
          { parroquia: "DUCUR" }
        ]
      },
      {
        canton: "LA TRONCAL",
        parroquias: [
          { parroquia: "LA TRONCAL" },
          { parroquia: "MANUEL J. CALLE" },
          { parroquia: "PANCHO NEGRO" }
        ]
      },
      {
        canton: "EL TAMBO",
        parroquias: [
          {
            parroquia: "EL TAMBO"
          }
        ]
      },
      {
        canton: "DÉLEG",
        parroquias: [{ parroquia: "DÉLEG" }, { parroquia: "SOLANO" }]
      },
      {
        canton: "SUSCAL",
        parroquias: [
          {
            parroquia: "SUSCAL"
          }
        ]
      }
    ]
  },
  {
    provincia: "CARCHI",
    cantones: [
      {
        canton: "TULCÁN",
        parroquias: [
          { parroquia: "GONZÁLEZ SUÁREZ" },
          { parroquia: "TULCÁN" },
          { parroquia: "EL CARMELO (EL PUN)" },
          { parroquia: "HUACA" },
          { parroquia: "JULIO ANDRADE (OREJUELA)" },
          { parroquia: "MALDONADO" },
          { parroquia: "PIOTER" },
          { parroquia: "TOBAR DONOSO (LA BOCANA DE CAMUMBÍ)" },
          { parroquia: "TUFIÑO" },
          { parroquia: "URBINA (TAYA)" },
          { parroquia: "EL CHICAL" },
          { parroquia: "MARISCAL SUCRE" },
          { parroquia: "SANTA MARTHA DE CUBA" }
        ]
      },
      {
        canton: "BOLÍVAR",
        parroquias: [
          { parroquia: "BOLÍVAR" },
          { parroquia: "GARCÍA MORENO" },
          { parroquia: "LOS ANDES" },
          { parroquia: "MONTE OLIVO" },
          { parroquia: "SAN VICENTE DE PUSIR" },
          { parroquia: "SAN RAFAEL" }
        ]
      },
      {
        canton: "ESPEJO",
        parroquias: [
          { parroquia: "EL ÁNGEL" },
          { parroquia: "27 DE SEPTIEMBRE" },
          { parroquia: "EL ANGEL" },
          { parroquia: "EL GOALTAL" },
          { parroquia: "LA LIBERTAD (ALIZO)" },
          { parroquia: "SAN ISIDRO" }
        ]
      },
      {
        canton: "MIRA",
        parroquias: [
          { parroquia: "MIRA (CHONTAHUASI)" },
          { parroquia: "CONCEPCIÓN" },
          { parroquia: "JIJÓN Y CAAMAÑO (CAB. EN RÍO BLANCO)" },
          { parroquia: "JUAN MONTALVO (SAN IGNACIO DE QUIL)" }
        ]
      },
      {
        canton: "MONTÚFAR",
        parroquias: [
          { parroquia: "GONZÁLEZ SUÁREZ" },
          { parroquia: "SAN JOSÉ" },
          { parroquia: "SAN GABRIEL" },
          { parroquia: "CRISTÓBAL COLÓN" },
          { parroquia: "CHITÁN DE NAVARRETE" },
          { parroquia: "FERNÁNDEZ SALVADOR" },
          { parroquia: "LA PAZ" },
          { parroquia: "PIARTAL" }
        ]
      },
      {
        canton: "SAN PEDRO DE HUACA",
        parroquias: [{ parroquia: "HUACA" }, { parroquia: "MARISCAL SUCRE" }]
      }
    ]
  },
  {
    provincia: "COTOPAXI",
    cantones: [
      {
        canton: "LATACUNGA",
        parroquias: [
          { parroquia: "ELOY ALFARO (SAN FELIPE)" },
          { parroquia: "IGNACIO FLORES (PARQUE FLORES)" },
          { parroquia: "JUAN MONTALVO (SAN SEBASTIÁN)" },
          { parroquia: "LA MATRIZ" },
          { parroquia: "SAN BUENAVENTURA" },
          { parroquia: "LATACUNGA" },
          { parroquia: "ALAQUES (ALÁQUEZ)" },
          { parroquia: "BELISARIO QUEVEDO (GUANAILÍN)" },
          { parroquia: "GUAITACAMA (GUAYTACAMA)" },
          { parroquia: "JOSEGUANGO BAJO" },
          { parroquia: "LAS PAMPAS" },
          { parroquia: "MULALÓ" },
          { parroquia: "11 DE NOVIEMBRE (ILINCHISI)" },
          { parroquia: "POALÓ" },
          { parroquia: "SAN JUAN DE PASTOCALLE" },
          { parroquia: "SIGCHOS" },
          { parroquia: "TANICUCHÍ" },
          { parroquia: "TOACASO" },
          { parroquia: "PALO QUEMADO" }
        ]
      },
      {
        canton: "LA MANÁ",
        parroquias: [
          { parroquia: "EL CARMEN" },
          { parroquia: "LA MANÁ" },
          { parroquia: "EL TRIUNFO" },
          { parroquia: "LA MANÁ" },
          { parroquia: "GUASAGANDA (CAB.EN GUASAGANDA" },
          { parroquia: "PUCAYACU" }
        ]
      },
      {
        canton: "PANGUA",
        parroquias: [
          { parroquia: "EL CORAZÓN" },
          { parroquia: "MORASPUNGO" },
          { parroquia: "PINLLOPATA" },
          { parroquia: "RAMÓN CAMPAÑA" }
        ]
      },
      {
        canton: "PUJILI",
        parroquias: [
          { parroquia: "PUJILÍ" },
          { parroquia: "ANGAMARCA" },
          { parroquia: "CHUCCHILÁN (CHUGCHILÁN)" },
          { parroquia: "GUANGAJE" },
          { parroquia: "ISINLIBÍ (ISINLIVÍ)" },
          { parroquia: "LA VICTORIA" },
          { parroquia: "PILALÓ" },
          { parroquia: "TINGO" },
          { parroquia: "ZUMBAHUA" }
        ]
      },
      {
        canton: "SALCEDO",
        parroquias: [
          { parroquia: "SAN MIGUEL" },
          { parroquia: "ANTONIO JOSÉ HOLGUÍN (SANTA LUCÍA)" },
          { parroquia: "CUSUBAMBA" },
          { parroquia: "MULALILLO" },
          { parroquia: "MULLIQUINDIL (SANTA ANA)" },
          { parroquia: "PANSALEO" }
        ]
      },
      {
        canton: "SAQUISILÍ",
        parroquias: [
          { parroquia: "SAQUISILÍ" },
          { parroquia: "CANCHAGUA" },
          { parroquia: "CHANTILÍN" },
          { parroquia: "COCHAPAMBA" }
        ]
      },
      {
        canton: "SIGCHOS",
        parroquias: [
          { parroquia: "SIGCHOS" },
          { parroquia: "CHUGCHILLÁN" },
          { parroquia: "ISINLIVÍ" },
          { parroquia: "LAS PAMPAS" },
          { parroquia: "PALO QUEMADO" }
        ]
      }
    ]
  },
  {
    provincia: "CHIMBORAZO",
    cantones: [
      {
        canton: "RIOBAMBA",
        parroquias: [
          { parroquia: "LIZARZABURU" },
          { parroquia: "MALDONADO" },
          { parroquia: "VELASCO" },
          { parroquia: "VELOZ" },
          { parroquia: "YARUQUÍES" },
          { parroquia: "RIOBAMBA" },
          { parroquia: "CACHA (CAB. EN MACHÁNGARA)" },
          { parroquia: "CALPI" },
          { parroquia: "CUBIJÍES" },
          { parroquia: "FLORES" },
          { parroquia: "LICÁN" },
          { parroquia: "LICTO" },
          { parroquia: "PUNGALÁ" },
          { parroquia: "PUNÍN" },
          { parroquia: "QUIMIAG" },
          { parroquia: "SAN JUAN" },
          { parroquia: "SAN LUIS" }
        ]
      },
      {
        canton: "ALAUSI",
        parroquias: [
          { parroquia: "ALAUSÍ" },
          { parroquia: "ACHUPALLAS" },
          { parroquia: "CUMANDÁ" },
          { parroquia: "GUASUNTOS" },
          { parroquia: "HUIGRA" },
          { parroquia: "MULTITUD" },
          { parroquia: "PISTISHÍ (NARIZ DEL DIABLO)" },
          { parroquia: "PUMALLACTA" },
          { parroquia: "SEVILLA" },
          { parroquia: "SIBAMBE" },
          { parroquia: "TIXÁN" }
        ]
      },
      {
        canton: "COLTA",
        parroquias: [
          { parroquia: "CAJABAMBA" },
          { parroquia: "SICALPA" },
          { parroquia: "VILLA LA UNIÓN (CAJABAMBA)" },
          { parroquia: "CAÑI" },
          { parroquia: "COLUMBE" },
          { parroquia: "JUAN DE VELASCO (PANGOR)" },
          { parroquia: "SANTIAGO DE QUITO (CAB. EN SAN ANTONIO DE QUITO)" }
        ]
      },
      {
        canton: "CHAMBO",
        parroquias: [
          {
            parroquia: "CHAMBO"
          }
        ]
      },
      {
        canton: "CHUNCHI",
        parroquias: [
          { parroquia: "CHUNCHI" },
          { parroquia: "CAPZOL" },
          { parroquia: "COMPUD" },
          { parroquia: "GONZOL" },
          { parroquia: "LLAGOS" }
        ]
      },
      {
        canton: "GUAMOTE",
        parroquias: [
          { parroquia: "GUAMOTE" },
          { parroquia: "CEBADAS" },
          { parroquia: "PALMIRA" }
        ]
      },
      {
        canton: "GUANO",
        parroquias: [
          { parroquia: "EL ROSARIO" },
          { parroquia: "LA MATRIZ" },
          { parroquia: "GUANO" },
          { parroquia: "GUANANDO" },
          { parroquia: "ILAPO" },
          { parroquia: "LA PROVIDENCIA" },
          { parroquia: "SAN ANDRÉS" },
          { parroquia: "SAN GERARDO DE PACAICAGUÁN" },
          { parroquia: "SAN ISIDRO DE PATULÚ" },
          { parroquia: "SAN JOSÉ DEL CHAZO" },
          { parroquia: "SANTA FÉ DE GALÁN" },
          { parroquia: "VALPARAÍSO" }
        ]
      },
      {
        canton: "PALLATANGA",
        parroquias: [
          {
            parroquia: "PALLATANGA"
          }
        ]
      },
      {
        canton: "PENIPE",
        parroquias: [
          { parroquia: "PENIPE" },
          { parroquia: "EL ALTAR" },
          { parroquia: "MATUS" },
          { parroquia: "PUELA" },
          { parroquia: "SAN ANTONIO DE BAYUSHIG" },
          { parroquia: "LA CANDELARIA" },
          { parroquia: "BILBAO (CAB.EN QUILLUYACU)" }
        ]
      },
      {
        canton: "CUMANDÁ",
        parroquias: [
          {
            parroquia: "CUMANDÁ"
          }
        ]
      }
    ]
  },
  {
    provincia: "EL ORO",
    cantones: [
      {
        canton: "MACHALA",
        parroquias: [
          { parroquia: "LA PROVIDENCIA" },
          { parroquia: "MACHALA" },
          { parroquia: "PUERTO BOLÍVAR" },
          { parroquia: "NUEVE DE MAYO" },
          { parroquia: "EL CAMBIO" },
          { parroquia: "EL RETIRO" }
        ]
      },
      {
        canton: "ARENILLAS",
        parroquias: [
          { parroquia: "ARENILLAS" },
          { parroquia: "CHACRAS" },
          { parroquia: "LA LIBERTAD" },
          { parroquia: "LAS LAJAS (CAB. EN LA VICTORIA)" },
          { parroquia: "PALMALES" },
          { parroquia: "CARCABÓN" }
        ]
      },
      {
        canton: "ATAHUALPA",
        parroquias: [
          { parroquia: "PACCHA" },
          { parroquia: "AYAPAMBA" },
          { parroquia: "CORDONCILLO" },
          { parroquia: "MILAGRO" },
          { parroquia: "SAN JOSÉ" },
          { parroquia: "SAN JUAN DE CERRO AZUL" }
        ]
      },
      {
        canton: "BALSAS",
        parroquias: [{ parroquia: "BALSAS" }, { parroquia: "BELLAMARÍA" }]
      },
      {
        canton: "CHILLA",
        parroquias: [
          {
            parroquia: "CHILLA"
          }
        ]
      },
      {
        canton: "EL GUABO",
        parroquias: [
          { parroquia: "EL GUABO" },
          { parroquia: "BARBONES (SUCRE)" },
          { parroquia: "LA IBERIA" },
          { parroquia: "TENDALES (CAB.EN PUERTO TENDALES)" },
          { parroquia: "RÍO BONITO" }
        ]
      },
      {
        canton: "HUAQUILLAS",
        parroquias: [
          { parroquia: "ECUADOR" },
          { parroquia: "EL PARAÍSO" },
          { parroquia: "HUALTACO" },
          { parroquia: "MILTON REYES" },
          { parroquia: "UNIÓN LOJANA" },
          { parroquia: "HUAQUILLAS" }
        ]
      },
      {
        canton: "MARCABELÍ",
        parroquias: [{ parroquia: "MARCABELÍ" }, { parroquia: "EL INGENIO" }]
      },
      {
        canton: "PASAJE",
        parroquias: [
          { parroquia: "BOLÍVAR" },
          { parroquia: "LOMA DE FRANCO" },
          { parroquia: "OCHOA LEÓN (MATRIZ)" },
          { parroquia: "TRES CERRITOS" },
          { parroquia: "PASAJE" },
          { parroquia: "BUENAVISTA" },
          { parroquia: "CASACAY" },
          { parroquia: "LA PEAÑA" },
          { parroquia: "PROGRESO" },
          { parroquia: "UZHCURRUMI" },
          { parroquia: "CAÑAQUEMADA" }
        ]
      },
      {
        canton: "PIÑAS",
        parroquias: [
          { parroquia: "LA MATRIZ" },
          { parroquia: "LA SUSAYA" },
          { parroquia: "PIÑAS GRANDE" },
          { parroquia: "PIÑAS" },
          { parroquia: "CAPIRO (CAB. EN LA CAPILLA DE CAPIRO)" },
          { parroquia: "LA BOCANA" },
          { parroquia: "MOROMORO (CAB. EN EL VADO)" },
          { parroquia: "PIEDRAS" },
          { parroquia: "SAN ROQUE (AMBROSIO MALDONADO)" },
          { parroquia: "SARACAY" }
        ]
      },
      {
        canton: "PORTOVELO",
        parroquias: [
          { parroquia: "PORTOVELO" },
          { parroquia: "CURTINCAPA" },
          { parroquia: "MORALES" },
          { parroquia: "SALATÍ" }
        ]
      },
      {
        canton: "SANTA ROSA",
        parroquias: [
          { parroquia: "SANTA ROSA" },
          { parroquia: "PUERTO JELÍ" },
          { parroquia: "BALNEARIO JAMBELÍ (SATÉLITE)" },
          { parroquia: "JUMÓN (SATÉLITE)" },
          { parroquia: "NUEVO SANTA ROSA" },
          { parroquia: "SANTA ROSA" },
          { parroquia: "BELLAVISTA" },
          { parroquia: "JAMBELÍ" },
          { parroquia: "LA AVANZADA" },
          { parroquia: "SAN ANTONIO" },
          { parroquia: "TORATA" },
          { parroquia: "VICTORIA" },
          { parroquia: "BELLAMARÍA" }
        ]
      },
      {
        canton: "ZARUMA",
        parroquias: [
          { parroquia: "ZARUMA" },
          { parroquia: "ABAÑÍN" },
          { parroquia: "ARCAPAMBA" },
          { parroquia: "GUANAZÁN" },
          { parroquia: "GUIZHAGUIÑA" },
          { parroquia: "HUERTAS" },
          { parroquia: "MALVAS" },
          { parroquia: "MULUNCAY GRANDE" },
          { parroquia: "SINSAO" },
          { parroquia: "SALVIAS" }
        ]
      },
      {
        canton: "LAS LAJAS",
        parroquias: [
          { parroquia: "LA VICTORIA" },
          { parroquia: "PLATANILLOS" },
          { parroquia: "VALLE HERMOSO" },
          { parroquia: "LA VICTORIA" },
          { parroquia: "LA LIBERTAD" },
          { parroquia: "EL PARAÍSO" },
          { parroquia: "SAN ISIDRO" }
        ]
      }
    ]
  },
  {
    provincia: "ESMERALDAS",
    cantones: [
      {
        canton: "ESMERALDAS",
        parroquias: [
          { parroquia: "BARTOLOMÉ RUIZ (CÉSAR FRANCO CARRIÓN)" },
          { parroquia: "5 DE AGOSTO" },
          { parroquia: "ESMERALDAS" },
          { parroquia: "LUIS TELLO (LAS PALMAS)" },
          { parroquia: "SIMÓN PLATA TORRES" },
          { parroquia: "ESMERALDAS" },
          { parroquia: "ATACAMES" },
          { parroquia: "CAMARONES (CAB. EN SAN VICENTE)" },
          { parroquia: "CRNEL. CARLOS CONCHA TORRES (CAB.EN HUELE)" },
          { parroquia: "CHINCA" },
          { parroquia: "CHONTADURO" },
          { parroquia: "CHUMUNDÉ" },
          { parroquia: "LAGARTO" },
          { parroquia: "LA UNIÓN" },
          { parroquia: "MAJUA" },
          { parroquia: "MONTALVO (CAB. EN HORQUETA)" },
          { parroquia: "RÍO VERDE" },
          { parroquia: "ROCAFUERTE" },
          { parroquia: "SAN MATEO" },
          { parroquia: "SÚA (CAB. EN LA BOCANA)" },
          { parroquia: "TABIAZO" },
          { parroquia: "TACHINA" },
          { parroquia: "TONCHIGÜE" },
          { parroquia: "VUELTA LARGA" }
        ]
      },
      {
        canton: "ELOY ALFARO",
        parroquias: [
          { parroquia: "VALDEZ (LIMONES)" },
          { parroquia: "ANCHAYACU" },
          { parroquia: "ATAHUALPA (CAB. EN CAMARONES)" },
          { parroquia: "BORBÓN" },
          { parroquia: "LA TOLA" },
          { parroquia: "LUIS VARGAS TORRES (CAB. EN PLAYA DE ORO)" },
          { parroquia: "MALDONADO" },
          { parroquia: "PAMPANAL DE BOLÍVAR" },
          { parroquia: "SAN FRANCISCO DE ONZOLE" },
          { parroquia: "SANTO DOMINGO DE ONZOLE" },
          { parroquia: "SELVA ALEGRE" },
          { parroquia: "TELEMBÍ" },
          { parroquia: "COLÓN ELOY DEL MARÍA" },
          { parroquia: "SAN JOSÉ DE CAYAPAS" },
          { parroquia: "TIMBIRÉ" }
        ]
      },
      {
        canton: "MUISNE",
        parroquias: [
          { parroquia: "MUISNE" },
          { parroquia: "BOLÍVAR" },
          { parroquia: "DAULE" },
          { parroquia: "GALERA" },
          { parroquia: "QUINGUE (OLMEDO PERDOMO FRANCO)" },
          { parroquia: "SALIMA" },
          { parroquia: "SAN FRANCISCO" },
          { parroquia: "SAN GREGORIO" },
          { parroquia: "SAN JOSÉ DE CHAMANGA (CAB.EN CHAMANGA)" }
        ]
      },
      {
        canton: "QUININDÉ",
        parroquias: [
          { parroquia: "ROSA ZÁRATE (QUININDÉ)" },
          { parroquia: "CUBE" },
          { parroquia: "CHURA (CHANCAMA) (CAB. EN EL YERBERO)" },
          { parroquia: "MALIMPIA" },
          { parroquia: "VICHE" },
          { parroquia: "LA UNIÓN" }
        ]
      },
      {
        canton: "SAN LORENZO",
        parroquias: [
          { parroquia: "SAN LORENZO" },
          { parroquia: "ALTO TAMBO (CAB. EN GUADUAL)" },
          { parroquia: "ANCÓN (PICHANGAL) (CAB. EN PALMA REAL)" },
          { parroquia: "CALDERÓN" },
          { parroquia: "CARONDELET" },
          { parroquia: "5 DE JUNIO (CAB. EN UIMBI)" },
          { parroquia: "CONCEPCIÓN" },
          { parroquia: "MATAJE (CAB. EN SANTANDER)" },
          { parroquia: "SAN JAVIER DE CACHAVÍ (CAB. EN SAN JAVIER)" },
          { parroquia: "SANTA RITA" },
          { parroquia: "TAMBILLO" },
          { parroquia: "TULULBÍ (CAB. EN RICAURTE)" },
          { parroquia: "URBINA" }
        ]
      },
      {
        canton: "ATACAMES",
        parroquias: [
          { parroquia: "ATACAMES" },
          { parroquia: "LA UNIÓN" },
          { parroquia: "SÚA (CAB. EN LA BOCANA)" },
          { parroquia: "TONCHIGÜE" },
          { parroquia: "TONSUPA" }
        ]
      },
      {
        canton: "RIOVERDE",
        parroquias: [
          { parroquia: "RIOVERDE" },
          { parroquia: "CHONTADURO" },
          { parroquia: "CHUMUNDÉ" },
          { parroquia: "LAGARTO" },
          { parroquia: "MONTALVO (CAB. EN HORQUETA)" },
          { parroquia: "ROCAFUERTE" }
        ]
      },
      {
        canton: "LA CONCORDIA",
        parroquias: [
          { parroquia: "LA CONCORDIA" },
          { parroquia: "MONTERREY" },
          { parroquia: "LA VILLEGAS" },
          { parroquia: "PLAN PILOTO" }
        ]
      }
    ]
  },
  {
    provincia: "GUAYAS",
    cantones: [
      {
        canton: "GUAYAQUIL",
        parroquias: [
          { parroquia: "AYACUCHO" },
          { parroquia: "BOLÍVAR (SAGRARIO)" },
          { parroquia: "CARBO (CONCEPCIÓN)" },
          { parroquia: "FEBRES CORDERO" },
          { parroquia: "GARCÍA MORENO" },
          { parroquia: "LETAMENDI" },
          { parroquia: "NUEVE DE OCTUBRE" },
          { parroquia: "OLMEDO (SAN ALEJO)" },
          { parroquia: "ROCA" },
          { parroquia: "ROCAFUERTE" },
          { parroquia: "SUCRE" },
          { parroquia: "TARQUI" },
          { parroquia: "URDANETA" },
          { parroquia: "XIMENA" },
          { parroquia: "GUAYAQUIL" },
          { parroquia: "CHONGÓN" },
          { parroquia: "JUAN GÓMEZ RENDÓN (PROGRESO)" },
          { parroquia: "MORRO" },
          { parroquia: "PASCUALES" },
          { parroquia: "PLAYAS (GRAL. VILLAMIL)" },
          { parroquia: "POSORJA" },
          { parroquia: "PUNÁ" },
          { parroquia: "TENGUEL" }
        ]
      },
      {
        canton: "ALFREDO BAQUERIZO MORENO (JUJÁN)",
        parroquias: [
          {
            parroquia: "ALFREDO BAQUERIZO MORENO (JUJÁN)"
          }
        ]
      },
      {
        canton: "BALAO",
        parroquias: [
          {
            parroquia: "BALAO"
          }
        ]
      },
      {
        canton: "BALZAR",
        parroquias: [
          {
            parroquia: "BALZAR"
          }
        ]
      },
      {
        canton: "COLIMES",
        parroquias: [{ parroquia: "COLIMES" }, { parroquia: "SAN JACINTO" }]
      },
      {
        canton: "DAULE",
        parroquias: [
          { parroquia: "DAULE" },
          { parroquia: "LA AURORA (SATÉLITE)" },
          { parroquia: "BANIFE" },
          { parroquia: "EMILIANO CAICEDO MARCOS" },
          { parroquia: "MAGRO" },
          { parroquia: "PADRE JUAN BAUTISTA AGUIRRE" },
          { parroquia: "SANTA CLARA" },
          { parroquia: "VICENTE PIEDRAHITA" },
          { parroquia: "DAULE" },
          { parroquia: "ISIDRO AYORA (SOLEDAD)" },
          { parroquia: "JUAN BAUTISTA AGUIRRE (LOS TINTOS)" },
          { parroquia: "LAUREL" },
          { parroquia: "LIMONAL" },
          { parroquia: "LOMAS DE SARGENTILLO" },
          { parroquia: "LOS LOJAS (ENRIQUE BAQUERIZO MORENO)" },
          { parroquia: "PIEDRAHITA (NOBOL)" }
        ]
      },
      {
        canton: "DURÁN",
        parroquias: [
          { parroquia: "ELOY ALFARO (DURÁN)" },
          { parroquia: "EL RECREO" },
          { parroquia: "ELOY ALFARO (DURÁN)" }
        ]
      },
      {
        canton: "EL EMPALME",
        parroquias: [
          { parroquia: "VELASCO IBARRA (EL EMPALME)" },
          { parroquia: "GUAYAS (PUEBLO NUEVO)" },
          { parroquia: "EL ROSARIO" }
        ]
      },
      {
        canton: "EL TRIUNFO",
        parroquias: [
          {
            parroquia: "EL TRIUNFO"
          }
        ]
      },
      {
        canton: "MILAGRO",
        parroquias: [
          { parroquia: "MILAGRO" },
          { parroquia: "CHOBO" },
          { parroquia: "GENERAL ELIZALDE (BUCAY)" },
          { parroquia: "MARISCAL SUCRE (HUAQUES)" },
          { parroquia: "ROBERTO ASTUDILLO (CAB. EN CRUCE DE VENECIA)" }
        ]
      },
      {
        canton: "NARANJAL",
        parroquias: [
          { parroquia: "NARANJAL" },
          { parroquia: "JESÚS MARÍA" },
          { parroquia: "SAN CARLOS" },
          { parroquia: "SANTA ROSA DE FLANDES" },
          { parroquia: "TAURA" }
        ]
      },
      {
        canton: "NARANJITO",
        parroquias: [
          {
            parroquia: "NARANJITO"
          }
        ]
      },
      {
        canton: "PALESTINA",
        parroquias: [
          {
            parroquia: "PALESTINA"
          }
        ]
      },
      {
        canton: "PEDRO CARBO",
        parroquias: [
          { parroquia: "PEDRO CARBO" },
          { parroquia: "VALLE DE LA VIRGEN" },
          { parroquia: "SABANILLA" }
        ]
      },
      {
        canton: "SAMBORONDÓN",
        parroquias: [
          { parroquia: "SAMBORONDÓN" },
          { parroquia: "LA PUNTILLA (SATÉLITE)" },
          { parroquia: "SAMBORONDÓN" },
          { parroquia: "TARIFA" }
        ]
      },
      {
        canton: "SANTA LUCÍA",
        parroquias: [
          {
            parroquia: "SANTA LUCÍA"
          }
        ]
      },
      {
        canton: "SALITRE (URBINA JADO)",
        parroquias: [
          { parroquia: "BOCANA" },
          { parroquia: "CANDILEJOS" },
          { parroquia: "CENTRAL" },
          { parroquia: "PARAÍSO" },
          { parroquia: "SAN MATEO" },
          { parroquia: "EL SALITRE (LAS RAMAS)" },
          { parroquia: "GRAL. VERNAZA (DOS ESTEROS)" },
          { parroquia: "LA VICTORIA (ÑAUZA)" },
          { parroquia: "JUNQUILLAL" }
        ]
      },
      {
        canton: "SAN JACINTO DE YAGUACHI",
        parroquias: [
          { parroquia: "SAN JACINTO DE YAGUACHI" },
          { parroquia: "CRNEL. LORENZO DE GARAICOA (PEDREGAL)" },
          { parroquia: "CRNEL. MARCELINO MARIDUEÑA (SAN CARLOS)" },
          { parroquia: "GRAL. PEDRO J. MONTERO (BOLICHE)" },
          { parroquia: "SIMÓN BOLÍVAR" },
          { parroquia: "YAGUACHI VIEJO (CONE)" },
          { parroquia: "VIRGEN DE FÁTIMA" }
        ]
      },
      {
        canton: "PLAYAS",
        parroquias: [
          {
            parroquia: "GENERAL VILLAMIL (PLAYAS)"
          }
        ]
      },
      {
        canton: "SIMÓN BOLÍVAR",
        parroquias: [
          { parroquia: "SIMÓN BOLÍVAR" },
          { parroquia: "CRNEL.LORENZO DE GARAICOA (PEDREGAL)" }
        ]
      },
      {
        canton: "CORONEL MARCELINO MARIDUEÑA",
        parroquias: [
          {
            parroquia: "CORONEL MARCELINO MARIDUEÑA (SAN CARLOS)"
          }
        ]
      },
      {
        canton: "LOMAS DE SARGENTILLO",
        parroquias: [
          { parroquia: "LOMAS DE SARGENTILLO" },
          { parroquia: "ISIDRO AYORA (SOLEDAD)" }
        ]
      },
      {
        canton: "NOBOL",
        parroquias: [
          {
            parroquia: "NARCISA DE JESÚS"
          }
        ]
      },
      {
        canton: "GENERAL ANTONIO ELIZALDE",
        parroquias: [
          {
            parroquia: "GENERAL ANTONIO ELIZALDE (BUCAY)"
          }
        ]
      },
      {
        canton: "ISIDRO AYORA",
        parroquias: [
          {
            parroquia: "ISIDRO AYORA"
          }
        ]
      }
    ]
  },
  {
    provincia: "IMBABURA",
    cantones: [
      {
        canton: "IBARRA",
        parroquias: [
          { parroquia: "CARANQUI" },
          { parroquia: "GUAYAQUIL DE ALPACHACA" },
          { parroquia: "SAGRARIO" },
          { parroquia: "SAN FRANCISCO" },
          { parroquia: "LA DOLOROSA DEL PRIORATO" },
          { parroquia: "SAN MIGUEL DE IBARRA" },
          { parroquia: "AMBUQUÍ" },
          { parroquia: "ANGOCHAGUA" },
          { parroquia: "CAROLINA" },
          { parroquia: "LA ESPERANZA" },
          { parroquia: "LITA" },
          { parroquia: "SALINAS" },
          { parroquia: "SAN ANTONIO" }
        ]
      },
      {
        canton: "ANTONIO ANTE",
        parroquias: [
          { parroquia: "ANDRADE MARÍN (LOURDES)" },
          { parroquia: "ATUNTAQUI" },
          { parroquia: "ATUNTAQUI" },
          { parroquia: "IMBAYA (SAN LUIS DE COBUENDO)" },
          { parroquia: "SAN FRANCISCO DE NATABUELA" },
          { parroquia: "SAN JOSÉ DE CHALTURA" },
          { parroquia: "SAN ROQUE" }
        ]
      },
      {
        canton: "COTACACHI",
        parroquias: [
          { parroquia: "SAGRARIO" },
          { parroquia: "SAN FRANCISCO" },
          { parroquia: "COTACACHI" },
          { parroquia: "APUELA" },
          { parroquia: "GARCÍA MORENO (LLURIMAGUA)" },
          { parroquia: "IMANTAG" },
          { parroquia: "PEÑAHERRERA" },
          { parroquia: "PLAZA GUTIÉRREZ (CALVARIO)" },
          { parroquia: "QUIROGA" },
          { parroquia: "6 DE JULIO DE CUELLAJE (CAB. EN CUELLAJE)" },
          { parroquia: "VACAS GALINDO (EL CHURO) (CAB.EN SAN MIGUEL ALTO" }
        ]
      },
      {
        canton: "OTAVALO",
        parroquias: [
          { parroquia: "JORDÁN" },
          { parroquia: "SAN LUIS" },
          { parroquia: "OTAVALO" },
          { parroquia: "DR. MIGUEL EGAS CABEZAS (PEGUCHE)" },
          { parroquia: "EUGENIO ESPEJO (CALPAQUÍ)" },
          { parroquia: "GONZÁLEZ SUÁREZ" },
          { parroquia: "PATAQUÍ" },
          { parroquia: "SAN JOSÉ DE QUICHINCHE" },
          { parroquia: "SAN JUAN DE ILUMÁN" },
          { parroquia: "SAN PABLO" },
          { parroquia: "SAN RAFAEL" },
          { parroquia: "SELVA ALEGRE (CAB.EN SAN MIGUEL DE PAMPLONA)" }
        ]
      },
      {
        canton: "PIMAMPIRO",
        parroquias: [
          { parroquia: "PIMAMPIRO" },
          { parroquia: "CHUGÁ" },
          { parroquia: "MARIANO ACOSTA" },
          { parroquia: "SAN FRANCISCO DE SIGSIPAMBA" }
        ]
      },
      {
        canton: "SAN MIGUEL DE URCUQUÍ",
        parroquias: [
          { parroquia: "URCUQUÍ CABECERA CANTONAL" },
          { parroquia: "CAHUASQUÍ" },
          { parroquia: "LA MERCED DE BUENOS AIRES" },
          { parroquia: "PABLO ARENAS" },
          { parroquia: "SAN BLAS" },
          { parroquia: "TUMBABIRO" }
        ]
      }
    ]
  },
  {
    provincia: "LOJA",
    cantones: [
      {
        canton: "LOJA",
        parroquias: [
          { parroquia: "EL SAGRARIO" },
          { parroquia: "SAN SEBASTIÁN" },
          { parroquia: "SUCRE" },
          { parroquia: "VALLE" },
          { parroquia: "LOJA" },
          { parroquia: "CHANTACO" },
          { parroquia: "CHUQUIRIBAMBA" },
          { parroquia: "EL CISNE" },
          { parroquia: "GUALEL" },
          { parroquia: "JIMBILLA" },
          { parroquia: "MALACATOS (VALLADOLID)" },
          { parroquia: "SAN LUCAS" },
          { parroquia: "SAN PEDRO DE VILCABAMBA" },
          { parroquia: "SANTIAGO" },
          { parroquia: "TAQUIL (MIGUEL RIOFRÍO)" },
          { parroquia: "VILCABAMBA (VICTORIA)" },
          { parroquia: "YANGANA (ARSENIO CASTILLO)" },
          { parroquia: "QUINARA" }
        ]
      },
      {
        canton: "CALVAS",
        parroquias: [
          { parroquia: "CARIAMANGA" },
          { parroquia: "CHILE" },
          { parroquia: "SAN VICENTE" },
          { parroquia: "CARIAMANGA" },
          { parroquia: "COLAISACA" },
          { parroquia: "EL LUCERO" },
          { parroquia: "UTUANA" },
          { parroquia: "SANGUILLÍN" }
        ]
      },
      {
        canton: "CATAMAYO",
        parroquias: [
          { parroquia: "CATAMAYO" },
          { parroquia: "SAN JOSÉ" },
          { parroquia: "CATAMAYO (LA TOMA)" },
          { parroquia: "EL TAMBO" },
          { parroquia: "GUAYQUICHUMA" },
          { parroquia: "SAN PEDRO DE LA BENDITA" },
          { parroquia: "ZAMBI" }
        ]
      },
      {
        canton: "CELICA",
        parroquias: [
          { parroquia: "CELICA" },
          { parroquia: "CRUZPAMBA (CAB. EN CARLOS BUSTAMANTE)" },
          { parroquia: "CHAQUINAL" },
          { parroquia: "12 DE DICIEMBRE (CAB. EN ACHIOTES)" },
          { parroquia: "PINDAL (FEDERICO PÁEZ)" },
          { parroquia: "POZUL (SAN JUAN DE POZUL)" },
          { parroquia: "SABANILLA" },
          { parroquia: "TNTE. MAXIMILIANO RODRÍGUEZ LOAIZA" }
        ]
      },
      {
        canton: "CHAGUARPAMBA",
        parroquias: [
          { parroquia: "CHAGUARPAMBA" },
          { parroquia: "BUENAVISTA" },
          { parroquia: "EL ROSARIO" },
          { parroquia: "SANTA RUFINA" },
          { parroquia: "AMARILLOS" }
        ]
      },
      {
        canton: "ESPÍNDOLA",
        parroquias: [
          { parroquia: "AMALUZA" },
          { parroquia: "BELLAVISTA" },
          { parroquia: "JIMBURA" },
          { parroquia: "SANTA TERESITA" },
          { parroquia: "27 DE ABRIL (CAB. EN LA NARANJA)" },
          { parroquia: "EL INGENIO" },
          { parroquia: "EL AIRO" }
        ]
      },
      {
        canton: "GONZANAMÁ",
        parroquias: [
          { parroquia: "GONZANAMÁ" },
          { parroquia: "CHANGAIMINA (LA LIBERTAD)" },
          { parroquia: "FUNDOCHAMBA" },
          { parroquia: "NAMBACOLA" },
          { parroquia: "PURUNUMA (EGUIGUREN)" },
          { parroquia: "QUILANGA (LA PAZ)" },
          { parroquia: "SACAPALCA" },
          { parroquia: "SAN ANTONIO DE LAS ARADAS (CAB. EN LAS ARADAS)" }
        ]
      },
      {
        canton: "MACARÁ",
        parroquias: [
          { parroquia: "GENERAL ELOY ALFARO (SAN SEBASTIÁN)" },
          { parroquia: "MACARÁ (MANUEL ENRIQUE RENGEL SUQUILANDA)" },
          { parroquia: "MACARÁ" },
          { parroquia: "LARAMA" },
          { parroquia: "LA VICTORIA" },
          { parroquia: "SABIANGO (LA CAPILLA)" }
        ]
      },
      {
        canton: "PALTAS",
        parroquias: [
          { parroquia: "CATACOCHA" },
          { parroquia: "LOURDES" },
          { parroquia: "CATACOCHA" },
          { parroquia: "CANGONAMÁ" },
          { parroquia: "GUACHANAMÁ" },
          { parroquia: "LA TINGUE" },
          { parroquia: "LAURO GUERRERO" },
          { parroquia: "OLMEDO (SANTA BÁRBARA)" },
          { parroquia: "ORIANGA" },
          { parroquia: "SAN ANTONIO" },
          { parroquia: "CASANGA" },
          { parroquia: "YAMANA" }
        ]
      },
      {
        canton: "PUYANGO",
        parroquias: [
          { parroquia: "ALAMOR" },
          { parroquia: "CIANO" },
          { parroquia: "EL ARENAL" },
          { parroquia: "EL LIMO (MARIANA DE JESÚS)" },
          { parroquia: "MERCADILLO" },
          { parroquia: "VICENTINO" }
        ]
      },
      {
        canton: "SARAGURO",
        parroquias: [
          { parroquia: "SARAGURO" },
          { parroquia: "EL PARAÍSO DE CELÉN" },
          { parroquia: "EL TABLÓN" },
          { parroquia: "LLUZHAPA" },
          { parroquia: "MANÚ" },
          { parroquia: "SAN ANTONIO DE QUMBE (CUMBE)" },
          { parroquia: "SAN PABLO DE TENTA" },
          { parroquia: "SAN SEBASTIÁN DE YÚLUC" },
          { parroquia: "SELVA ALEGRE" },
          { parroquia: "URDANETA (PAQUISHAPA)" },
          { parroquia: "SUMAYPAMBA" }
        ]
      },
      {
        canton: "SOZORANGA",
        parroquias: [
          { parroquia: "SOZORANGA" },
          { parroquia: "NUEVA FÁTIMA" },
          { parroquia: "TACAMOROS" }
        ]
      },
      {
        canton: "ZAPOTILLO",
        parroquias: [
          { parroquia: "ZAPOTILLO" },
          { parroquia: "MANGAHURCO (CAZADEROS)" },
          { parroquia: "GARZAREAL" },
          { parroquia: "LIMONES" },
          { parroquia: "PALETILLAS" },
          { parroquia: "BOLASPAMBA" }
        ]
      },
      {
        canton: "PINDAL",
        parroquias: [
          { parroquia: "PINDAL" },
          { parroquia: "CHAQUINAL" },
          { parroquia: "12 DE DICIEMBRE (CAB.EN ACHIOTES)" },
          { parroquia: "MILAGROS" }
        ]
      },
      {
        canton: "QUILANGA",
        parroquias: [
          { parroquia: "QUILANGA" },
          { parroquia: "FUNDOCHAMBA" },
          { parroquia: "SAN ANTONIO DE LAS ARADAS (CAB. EN LAS ARADAS)" }
        ]
      },
      {
        canton: "OLMEDO",
        parroquias: [{ parroquia: "OLMEDO" }, { parroquia: "LA TINGUE" }]
      }
    ]
  },
  {
    provincia: "LOS RIOS",
    cantones: [
      {
        canton: "BABAHOYO",
        parroquias: [
          { parroquia: "CLEMENTE BAQUERIZO" },
          { parroquia: "DR. CAMILO PONCE" },
          { parroquia: "BARREIRO" },
          { parroquia: "EL SALTO" },
          { parroquia: "BABAHOYO" },
          { parroquia: "BARREIRO (SANTA RITA)" },
          { parroquia: "CARACOL" },
          { parroquia: "FEBRES CORDERO (LAS JUNTAS)" },
          { parroquia: "PIMOCHA" },
          { parroquia: "LA UNIÓN" }
        ]
      },
      {
        canton: "BABA",
        parroquias: [
          { parroquia: "BABA" },
          { parroquia: "GUARE" },
          { parroquia: "ISLA DE BEJUCAL" }
        ]
      },
      {
        canton: "MONTALVO",
        parroquias: [
          {
            parroquia: "MONTALVO"
          }
        ]
      },
      {
        canton: "PUEBLOVIEJO",
        parroquias: [
          { parroquia: "PUEBLOVIEJO" },
          { parroquia: "PUERTO PECHICHE" },
          { parroquia: "SAN JUAN" }
        ]
      },
      {
        canton: "QUEVEDO",
        parroquias: [
          { parroquia: "QUEVEDO" },
          { parroquia: "SAN CAMILO" },
          { parroquia: "SAN JOSÉ" },
          { parroquia: "GUAYACÁN" },
          { parroquia: "NICOLÁS INFANTE DÍAZ" },
          { parroquia: "SAN CRISTÓBAL" },
          { parroquia: "SIETE DE OCTUBRE" },
          { parroquia: "24 DE MAYO" },
          { parroquia: "VENUS DEL RÍO QUEVEDO" },
          { parroquia: "VIVA ALFARO" },
          { parroquia: "QUEVEDO" },
          { parroquia: "BUENA FÉ" },
          { parroquia: "MOCACHE" },
          { parroquia: "SAN CARLOS" },
          { parroquia: "VALENCIA" },
          { parroquia: "LA ESPERANZA" }
        ]
      },
      {
        canton: "URDANETA",
        parroquias: [{ parroquia: "CATARAMA" }, { parroquia: "RICAURTE" }]
      },
      {
        canton: "VENTANAS",
        parroquias: [
          { parroquia: "10 DE NOVIEMBRE" },
          { parroquia: "VENTANAS" },
          { parroquia: "QUINSALOMA" },
          { parroquia: "ZAPOTAL" },
          { parroquia: "CHACARITA" },
          { parroquia: "LOS ÁNGELES" }
        ]
      },
      {
        canton: "VÍNCES",
        parroquias: [
          { parroquia: "VINCES" },
          { parroquia: "ANTONIO SOTOMAYOR (CAB. EN PLAYAS DE VINCES)" },
          { parroquia: "PALENQUE" }
        ]
      },
      {
        canton: "PALENQUE",
        parroquias: [
          {
            parroquia: "PALENQUE"
          }
        ]
      },
      {
        canton: "BUENA FÉ",
        parroquias: [
          { parroquia: "SAN JACINTO DE BUENA FÉ" },
          { parroquia: "7 DE AGOSTO" },
          { parroquia: "11 DE OCTUBRE" },
          { parroquia: "SAN JACINTO DE BUENA FÉ" },
          { parroquia: "PATRICIA PILAR" }
        ]
      },
      {
        canton: "VALENCIA",
        parroquias: [
          {
            parroquia: "VALENCIA"
          }
        ]
      },
      {
        canton: "MOCACHE",
        parroquias: [
          {
            parroquia: "MOCACHE"
          }
        ]
      },
      {
        canton: "QUINSALOMA",
        parroquias: [
          {
            parroquia: "QUINSALOMA"
          }
        ]
      }
    ]
  },
  {
    provincia: "MANABI",
    cantones: [
      {
        canton: "PORTOVIEJO",
        parroquias: [
          { parroquia: "PORTOVIEJO" },
          { parroquia: "12 DE MARZO" },
          { parroquia: "COLÓN" },
          { parroquia: "PICOAZÁ" },
          { parroquia: "SAN PABLO" },
          { parroquia: "ANDRÉS DE VERA" },
          { parroquia: "FRANCISCO PACHECO" },
          { parroquia: "18 DE OCTUBRE" },
          { parroquia: "SIMÓN BOLÍVAR" },
          { parroquia: "ABDÓN CALDERÓN (SAN FRANCISCO)" },
          { parroquia: "ALHAJUELA (BAJO GRANDE)" },
          { parroquia: "CRUCITA" },
          { parroquia: "PUEBLO NUEVO" },
          { parroquia: "RIOCHICO (RÍO CHICO)" },
          { parroquia: "SAN PLÁCIDO" },
          { parroquia: "CHIRIJOS" }
        ]
      },
      {
        canton: "BOLÍVAR",
        parroquias: [
          { parroquia: "CALCETA" },
          { parroquia: "MEMBRILLO" },
          { parroquia: "QUIROGA" }
        ]
      },
      {
        canton: "CHONE",
        parroquias: [
          { parroquia: "CHONE" },
          { parroquia: "SANTA RITA" },
          { parroquia: "CHONE" },
          { parroquia: "BOYACÁ" },
          { parroquia: "CANUTO" },
          { parroquia: "CONVENTO" },
          { parroquia: "CHIBUNGA" },
          { parroquia: "ELOY ALFARO" },
          { parroquia: "RICAURTE" },
          { parroquia: "SAN ANTONIO" }
        ]
      },
      {
        canton: "EL CARMEN",
        parroquias: [
          { parroquia: "EL CARMEN" },
          { parroquia: "4 DE DICIEMBRE" },
          { parroquia: "EL CARMEN" },
          { parroquia: "WILFRIDO LOOR MOREIRA (MAICITO)" },
          { parroquia: "SAN PEDRO DE SUMA" }
        ]
      },
      {
        canton: "FLAVIO ALFARO",
        parroquias: [
          { parroquia: "FLAVIO ALFARO" },
          { parroquia: "SAN FRANCISCO DE NOVILLO (CAB. EN" },
          { parroquia: "ZAPALLO" }
        ]
      },
      {
        canton: "JIPIJAPA",
        parroquias: [
          { parroquia: "DR. MIGUEL MORÁN LUCIO" },
          { parroquia: "MANUEL INOCENCIO PARRALES Y GUALE" },
          { parroquia: "SAN LORENZO DE JIPIJAPA" },
          { parroquia: "JIPIJAPA" },
          { parroquia: "AMÉRICA" },
          { parroquia: "EL ANEGADO (CAB. EN ELOY ALFARO)" },
          { parroquia: "JULCUY" },
          { parroquia: "LA UNIÓN" },
          { parroquia: "MACHALILLA" },
          { parroquia: "MEMBRILLAL" },
          { parroquia: "PEDRO PABLO GÓMEZ" },
          { parroquia: "PUERTO DE CAYO" },
          { parroquia: "PUERTO LÓPEZ" }
        ]
      },
      {
        canton: "JUNÍN",
        parroquias: [
          {
            parroquia: "JUNÍN"
          }
        ]
      },
      {
        canton: "MANTA",
        parroquias: [
          { parroquia: "LOS ESTEROS" },
          { parroquia: "MANTA" },
          { parroquia: "SAN MATEO" },
          { parroquia: "TARQUI" },
          { parroquia: "ELOY ALFARO" },
          { parroquia: "MANTA" },
          { parroquia: "SAN LORENZO" },
          { parroquia: "SANTA MARIANITA (BOCA DE PACOCHE)" }
        ]
      },
      {
        canton: "MONTECRISTI",
        parroquias: [
          { parroquia: "ANIBAL SAN ANDRÉS" },
          { parroquia: "MONTECRISTI" },
          { parroquia: "EL COLORADO" },
          { parroquia: "GENERAL ELOY ALFARO" },
          { parroquia: "LEONIDAS PROAÑO" },
          { parroquia: "MONTECRISTI" },
          { parroquia: "JARAMIJÓ" },
          { parroquia: "LA PILA" }
        ]
      },
      {
        canton: "PAJÁN",
        parroquias: [
          { parroquia: "PAJÁN" },
          { parroquia: "CAMPOZANO (LA PALMA DE PAJÁN)" },
          { parroquia: "CASCOL" },
          { parroquia: "GUALE" },
          { parroquia: "LASCANO" }
        ]
      },
      {
        canton: "PICHINCHA",
        parroquias: [
          { parroquia: "PICHINCHA" },
          { parroquia: "BARRAGANETE" },
          { parroquia: "SAN SEBASTIÁN" }
        ]
      },
      {
        canton: "ROCAFUERTE",
        parroquias: [
          {
            parroquia: "ROCAFUERTE"
          }
        ]
      },
      {
        canton: "SANTA ANA",
        parroquias: [
          { parroquia: "SANTA ANA" },
          { parroquia: "LODANA" },
          { parroquia: "SANTA ANA DE VUELTA LARGA" },
          { parroquia: "AYACUCHO" },
          { parroquia: "HONORATO VÁSQUEZ (CAB. EN VÁSQUEZ)" },
          { parroquia: "LA UNIÓN" },
          { parroquia: "OLMEDO" },
          { parroquia: "SAN PABLO (CAB. EN PUEBLO NUEVO)" }
        ]
      },
      {
        canton: "SUCRE",
        parroquias: [
          { parroquia: "BAHÍA DE CARÁQUEZ" },
          { parroquia: "LEONIDAS PLAZA GUTIÉRREZ" },
          { parroquia: "BAHÍA DE CARÁQUEZ" },
          { parroquia: "CANOA" },
          { parroquia: "COJIMÍES" },
          { parroquia: "CHARAPOTÓ" },
          { parroquia: "10 DE AGOSTO" },
          { parroquia: "JAMA" },
          { parroquia: "PEDERNALES" },
          { parroquia: "SAN ISIDRO" },
          { parroquia: "SAN VICENTE" }
        ]
      },
      {
        canton: "TOSAGUA",
        parroquias: [
          { parroquia: "TOSAGUA" },
          { parroquia: "BACHILLERO" },
          { parroquia: "ANGEL PEDRO GILER (LA ESTANCILLA)" }
        ]
      },
      {
        canton: "24 DE MAYO",
        parroquias: [
          { parroquia: "SUCRE" },
          { parroquia: "BELLAVISTA" },
          { parroquia: "NOBOA" },
          { parroquia: "ARQ. SIXTO DURÁN BALLÉN" }
        ]
      },
      {
        canton: "PEDERNALES",
        parroquias: [
          { parroquia: "PEDERNALES" },
          { parroquia: "COJIMÍES" },
          { parroquia: "10 DE AGOSTO" },
          { parroquia: "ATAHUALPA" }
        ]
      },
      {
        canton: "OLMEDO",
        parroquias: [
          {
            parroquia: "OLMEDO"
          }
        ]
      },
      {
        canton: "PUERTO LÓPEZ",
        parroquias: [
          { parroquia: "PUERTO LÓPEZ" },
          { parroquia: "MACHALILLA" },
          { parroquia: "SALANGO" }
        ]
      },
      {
        canton: "JAMA",
        parroquias: [
          {
            parroquia: "JAMA"
          }
        ]
      },
      {
        canton: "JARAMIJÓ",
        parroquias: [
          {
            parroquia: "JARAMIJÓ"
          }
        ]
      },
      {
        canton: "SAN VICENTE",
        parroquias: [{ parroquia: "SAN VICENTE" }, { parroquia: "CANOA" }]
      }
    ]
  },
  {
    provincia: "MORONA SANTIAGO",
    cantones: [
      {
        canton: "MORONA",
        parroquias: [
          { parroquia: "MACAS" },
          { parroquia: "ALSHI (CAB. EN 9 DE OCTUBRE)" },
          { parroquia: "CHIGUAZA" },
          { parroquia: "GENERAL PROAÑO" },
          { parroquia: "HUASAGA (CAB.EN WAMPUIK)" },
          { parroquia: "MACUMA" },
          { parroquia: "SAN ISIDRO" },
          { parroquia: "SEVILLA DON BOSCO" },
          { parroquia: "SINAÍ" },
          { parroquia: "TAISHA" },
          { parroquia: "ZUÑA (ZÚÑAC)" },
          { parroquia: "TUUTINENTZA" },
          { parroquia: "CUCHAENTZA" },
          { parroquia: "SAN JOSÉ DE MORONA" },
          { parroquia: "RÍO BLANCO" }
        ]
      },
      {
        canton: "GUALAQUIZA",
        parroquias: [
          { parroquia: "GUALAQUIZA" },
          { parroquia: "MERCEDES MOLINA" },
          { parroquia: "GUALAQUIZA" },
          { parroquia: "AMAZONAS (ROSARIO DE CUYES)" },
          { parroquia: "BERMEJOS" },
          { parroquia: "BOMBOIZA" },
          { parroquia: "CHIGÜINDA" },
          { parroquia: "EL ROSARIO" },
          { parroquia: "NUEVA TARQUI" },
          { parroquia: "SAN MIGUEL DE CUYES" },
          { parroquia: "EL IDEAL" }
        ]
      },
      {
        canton: "LIMÓN INDANZA",
        parroquias: [
          { parroquia: "GENERAL LEONIDAS PLAZA GUTIÉRREZ (LIMÓN)" },
          { parroquia: "INDANZA" },
          { parroquia: "PAN DE AZÚCAR" },
          { parroquia: "SAN ANTONIO (CAB. EN SAN ANTONIO CENTRO" },
          { parroquia: "SAN CARLOS DE LIMÓN (SAN CARLOS DEL" },
          { parroquia: "SAN JUAN BOSCO" },
          { parroquia: "SAN MIGUEL DE CONCHAY" },
          { parroquia: "SANTA SUSANA DE CHIVIAZA (CAB. EN CHIVIAZA)" },
          { parroquia: "YUNGANZA (CAB. EN EL ROSARIO)" }
        ]
      },
      {
        canton: "PALORA",
        parroquias: [
          { parroquia: "PALORA (METZERA)" },
          { parroquia: "ARAPICOS" },
          { parroquia: "CUMANDÁ (CAB. EN COLONIA AGRÍCOLA SEVILLA DEL ORO)" },
          { parroquia: "HUAMBOYA" },
          { parroquia: "SANGAY (CAB. EN NAYAMANACA)" }
        ]
      },
      {
        canton: "SANTIAGO",
        parroquias: [
          { parroquia: "SANTIAGO DE MÉNDEZ" },
          { parroquia: "COPAL" },
          { parroquia: "CHUPIANZA" },
          { parroquia: "PATUCA" },
          { parroquia: "SAN LUIS DE EL ACHO (CAB. EN EL ACHO)" },
          { parroquia: "SANTIAGO" },
          { parroquia: "TAYUZA" },
          { parroquia: "SAN FRANCISCO DE CHINIMBIMI" }
        ]
      },
      {
        canton: "SUCÚA",
        parroquias: [
          { parroquia: "SUCÚA" },
          { parroquia: "ASUNCIÓN" },
          { parroquia: "HUAMBI" },
          { parroquia: "LOGROÑO" },
          { parroquia: "YAUPI" },
          { parroquia: "SANTA MARIANITA DE JESÚS" }
        ]
      },
      {
        canton: "HUAMBOYA",
        parroquias: [
          { parroquia: "HUAMBOYA" },
          { parroquia: "CHIGUAZA" },
          { parroquia: "PABLO SEXTO" }
        ]
      },
      {
        canton: "SAN JUAN BOSCO",
        parroquias: [
          { parroquia: "SAN JUAN BOSCO" },
          { parroquia: "PAN DE AZÚCAR" },
          { parroquia: "SAN CARLOS DE LIMÓN" },
          { parroquia: "SAN JACINTO DE WAKAMBEIS" },
          { parroquia: "SANTIAGO DE PANANZA" }
        ]
      },
      {
        canton: "TAISHA",
        parroquias: [
          { parroquia: "TAISHA" },
          { parroquia: "HUASAGA (CAB. EN WAMPUIK)" },
          { parroquia: "MACUMA" },
          { parroquia: "TUUTINENTZA" },
          { parroquia: "PUMPUENTSA" }
        ]
      },
      {
        canton: "LOGROÑO",
        parroquias: [
          { parroquia: "LOGROÑO" },
          { parroquia: "YAUPI" },
          { parroquia: "SHIMPIS" }
        ]
      },
      {
        canton: "PABLO SEXTO",
        parroquias: [
          {
            parroquia: "PABLO SEXTO"
          }
        ]
      },
      {
        canton: "TIWINTZA",
        parroquias: [
          { parroquia: "SANTIAGO" },
          { parroquia: "SAN JOSÉ DE MORONA" }
        ]
      }
    ]
  },
  {
    provincia: "NAPO",
    cantones: [
      {
        canton: "TENA",
        parroquias: [
          { parroquia: "TENA" },
          { parroquia: "AHUANO" },
          { parroquia: "CARLOS JULIO AROSEMENA TOLA (ZATZA-YACU)" },
          { parroquia: "CHONTAPUNTA" },
          { parroquia: "PANO" },
          { parroquia: "PUERTO MISAHUALLI" },
          { parroquia: "PUERTO NAPO" },
          { parroquia: "TÁLAG" },
          { parroquia: "SAN JUAN DE MUYUNA" }
        ]
      },
      {
        canton: "ARCHIDONA",
        parroquias: [
          { parroquia: "ARCHIDONA" },
          { parroquia: "AVILA" },
          { parroquia: "COTUNDO" },
          { parroquia: "LORETO" },
          { parroquia: "SAN PABLO DE USHPAYACU" },
          { parroquia: "PUERTO MURIALDO" }
        ]
      },
      {
        canton: "EL CHACO",
        parroquias: [
          { parroquia: "EL CHACO" },
          { parroquia: "GONZALO DíAZ DE PINEDA (EL BOMBÓN)" },
          { parroquia: "LINARES" },
          { parroquia: "OYACACHI" },
          { parroquia: "SANTA ROSA" },
          { parroquia: "SARDINAS" }
        ]
      },
      {
        canton: "QUIJOS",
        parroquias: [
          { parroquia: "BAEZA" },
          { parroquia: "COSANGA" },
          { parroquia: "CUYUJA" },
          { parroquia: "PAPALLACTA" },
          { parroquia: "SAN FRANCISCO DE BORJA (VIRGILIO DÁVILA)" },
          { parroquia: "SAN JOSÉ DEL PAYAMINO" },
          { parroquia: "SUMACO" }
        ]
      },
      {
        canton: "CARLOS JULIO AROSEMENA TOLA",
        parroquias: [
          {
            parroquia: "CARLOS JULIO AROSEMENA TOLA"
          }
        ]
      }
    ]
  },
  {
    provincia: "PASTAZA",
    cantones: [
      {
        canton: "PASTAZA",
        parroquias: [
          { parroquia: "PUYO" },
          { parroquia: "ARAJUNO" },
          { parroquia: "CANELOS" },
          { parroquia: "CURARAY" },
          { parroquia: "DIEZ DE AGOSTO" },
          { parroquia: "FÁTIMA" },
          { parroquia: "MONTALVO (ANDOAS)" },
          { parroquia: "POMONA" },
          { parroquia: "RÍO CORRIENTES" },
          { parroquia: "RÍO TIGRE" },
          { parroquia: "SANTA CLARA" },
          { parroquia: "SARAYACU" },
          { parroquia: "SIMÓN BOLÍVAR (CAB. EN MUSHULLACTA)" },
          { parroquia: "TARQUI" },
          { parroquia: "TENIENTE HUGO ORTIZ" },
          { parroquia: "VERACRUZ (INDILLAMA) (CAB. EN INDILLAMA)" },
          { parroquia: "EL TRIUNFO" }
        ]
      },
      {
        canton: "MERA",
        parroquias: [
          { parroquia: "MERA" },
          { parroquia: "MADRE TIERRA" },
          { parroquia: "SHELL" }
        ]
      },
      {
        canton: "SANTA CLARA",
        parroquias: [{ parroquia: "SANTA CLARA" }, { parroquia: "SAN JOSÉ" }]
      },
      {
        canton: "ARAJUNO",
        parroquias: [{ parroquia: "ARAJUNO" }, { parroquia: "CURARAY" }]
      }
    ]
  },
  {
    provincia: "PICHINCHA",
    cantones: [
      {
        canton: "QUITO",
        parroquias: [
          { parroquia: "BELISARIO QUEVEDO" },
          { parroquia: "CARCELÉN" },
          { parroquia: "CENTRO HISTÓRICO" },
          { parroquia: "COCHAPAMBA" },
          { parroquia: "COMITÉ DEL PUEBLO" },
          { parroquia: "COTOCOLLAO" },
          { parroquia: "CHILIBULO" },
          { parroquia: "CHILLOGALLO" },
          { parroquia: "CHIMBACALLE" },
          { parroquia: "EL CONDADO" },
          { parroquia: "GUAMANÍ" },
          { parroquia: "IÑAQUITO" },
          { parroquia: "ITCHIMBÍA" },
          { parroquia: "JIPIJAPA" },
          { parroquia: "KENNEDY" },
          { parroquia: "LA ARGELIA" },
          { parroquia: "LA CONCEPCIÓN" },
          { parroquia: "LA ECUATORIANA" },
          { parroquia: "LA FERROVIARIA" },
          { parroquia: "LA LIBERTAD" },
          { parroquia: "LA MAGDALENA" },
          { parroquia: "LA MENA" },
          { parroquia: "MARISCAL SUCRE" },
          { parroquia: "PONCEANO" },
          { parroquia: "PUENGASÍ" },
          { parroquia: "QUITUMBE" },
          { parroquia: "RUMIPAMBA" },
          { parroquia: "SAN BARTOLO" },
          { parroquia: "SAN ISIDRO DEL INCA" },
          { parroquia: "SAN JUAN" },
          { parroquia: "SOLANDA" },
          { parroquia: "TURUBAMBA" },
          { parroquia: "QUITO DISTRITO METROPOLITANO" },
          { parroquia: "ALANGASÍ" },
          { parroquia: "AMAGUAÑA" },
          { parroquia: "ATAHUALPA" },
          { parroquia: "CALACALÍ" },
          { parroquia: "CALDERÓN" },
          { parroquia: "CONOCOTO" },
          { parroquia: "CUMBAYÁ" },
          { parroquia: "CHAVEZPAMBA" },
          { parroquia: "CHECA" },
          { parroquia: "EL QUINCHE" },
          { parroquia: "GUALEA" },
          { parroquia: "GUANGOPOLO" },
          { parroquia: "GUAYLLABAMBA" },
          { parroquia: "LA MERCED" },
          { parroquia: "LLANO CHICO" },
          { parroquia: "LLOA" },
          { parroquia: "MINDO" },
          { parroquia: "NANEGAL" },
          { parroquia: "NANEGALITO" },
          { parroquia: "NAYÓN" },
          { parroquia: "NONO" },
          { parroquia: "PACTO" },
          { parroquia: "PEDRO VICENTE MALDONADO" },
          { parroquia: "PERUCHO" },
          { parroquia: "PIFO" },
          { parroquia: "PÍNTAG" },
          { parroquia: "POMASQUI" },
          { parroquia: "PUÉLLARO" },
          { parroquia: "PUEMBO" },
          { parroquia: "SAN ANTONIO" },
          { parroquia: "SAN JOSÉ DE MINAS" },
          { parroquia: "SAN MIGUEL DE LOS BANCOS" },
          { parroquia: "TABABELA" },
          { parroquia: "TUMBACO" },
          { parroquia: "YARUQUÍ" },
          { parroquia: "ZAMBIZA" },
          { parroquia: "PUERTO QUITO" }
        ]
      },
      {
        canton: "CAYAMBE",
        parroquias: [
          { parroquia: "AYORA" },
          { parroquia: "CAYAMBE" },
          { parroquia: "JUAN MONTALVO" },
          { parroquia: "CAYAMBE" },
          { parroquia: "ASCÁZUBI" },
          { parroquia: "CANGAHUA" },
          { parroquia: "OLMEDO (PESILLO)" },
          { parroquia: "OTÓN" },
          { parroquia: "SANTA ROSA DE CUZUBAMBA" }
        ]
      },
      {
        canton: "MEJIA",
        parroquias: [
          { parroquia: "MACHACHI" },
          { parroquia: "ALÓAG" },
          { parroquia: "ALOASÍ" },
          { parroquia: "CUTUGLAHUA" },
          { parroquia: "EL CHAUPI" },
          { parroquia: "MANUEL CORNEJO ASTORGA (TANDAPI)" },
          { parroquia: "TAMBILLO" },
          { parroquia: "UYUMBICHO" }
        ]
      },
      {
        canton: "PEDRO MONCAYO",
        parroquias: [
          { parroquia: "TABACUNDO" },
          { parroquia: "LA ESPERANZA" },
          { parroquia: "MALCHINGUÍ" },
          { parroquia: "TOCACHI" },
          { parroquia: "TUPIGACHI" }
        ]
      },
      {
        canton: "RUMIÑAHUI",
        parroquias: [
          { parroquia: "SANGOLQUÍ" },
          { parroquia: "SAN PEDRO DE TABOADA" },
          { parroquia: "SAN RAFAEL" },
          { parroquia: "SANGOLQUI" },
          { parroquia: "COTOGCHOA" },
          { parroquia: "RUMIPAMBA" }
        ]
      },
      {
        canton: "SAN MIGUEL DE LOS BANCOS",
        parroquias: [
          { parroquia: "SAN MIGUEL DE LOS BANCOS" },
          { parroquia: "MINDO" },
          { parroquia: "PEDRO VICENTE MALDONADO" },
          { parroquia: "PUERTO QUITO" }
        ]
      },
      {
        canton: "PEDRO VICENTE MALDONADO",
        parroquias: [
          {
            parroquia: "PEDRO VICENTE MALDONADO"
          }
        ]
      },
      {
        canton: "PUERTO QUITO",
        parroquias: [
          {
            parroquia: "PUERTO QUITO"
          }
        ]
      }
    ]
  },
  {
    provincia: "TUNGURAHUA",
    cantones: [
      {
        canton: "AMBATO",
        parroquias: [
          { parroquia: "ATOCHA – FICOA" },
          { parroquia: "CELIANO MONGE" },
          { parroquia: "HUACHI CHICO" },
          { parroquia: "HUACHI LORETO" },
          { parroquia: "LA MERCED" },
          { parroquia: "LA PENÍNSULA" },
          { parroquia: "MATRIZ" },
          { parroquia: "PISHILATA" },
          { parroquia: "SAN FRANCISCO" },
          { parroquia: "AMBATO" },
          { parroquia: "AMBATILLO" },
          { parroquia: "ATAHUALPA (CHISALATA)" },
          { parroquia: "AUGUSTO N. MARTÍNEZ (MUNDUGLEO)" },
          { parroquia: "CONSTANTINO FERNÁNDEZ (CAB. EN CULLITAHUA)" },
          { parroquia: "HUACHI GRANDE" },
          { parroquia: "IZAMBA" },
          { parroquia: "JUAN BENIGNO VELA" },
          { parroquia: "MONTALVO" },
          { parroquia: "PASA" },
          { parroquia: "PICAIGUA" },
          { parroquia: "PILAGÜÍN (PILAHÜÍN)" },
          { parroquia: "QUISAPINCHA (QUIZAPINCHA)" },
          { parroquia: "SAN BARTOLOMÉ DE PINLLOG" },
          { parroquia: "SAN FERNANDO (PASA SAN FERNANDO)" },
          { parroquia: "SANTA ROSA" },
          { parroquia: "TOTORAS" },
          { parroquia: "CUNCHIBAMBA" },
          { parroquia: "UNAMUNCHO" }
        ]
      },
      {
        canton: "BAÑOS DE AGUA SANTA",
        parroquias: [
          { parroquia: "BAÑOS DE AGUA SANTA" },
          { parroquia: "LLIGUA" },
          { parroquia: "RÍO NEGRO" },
          { parroquia: "RÍO VERDE" },
          { parroquia: "ULBA" }
        ]
      },
      {
        canton: "CEVALLOS",
        parroquias: [
          {
            parroquia: "CEVALLOS"
          }
        ]
      },
      {
        canton: "MOCHA",
        parroquias: [{ parroquia: "MOCHA" }, { parroquia: "PINGUILÍ" }]
      },
      {
        canton: "PATATE",
        parroquias: [
          { parroquia: "PATATE" },
          { parroquia: "EL TRIUNFO" },
          { parroquia: "LOS ANDES (CAB. EN POATUG)" },
          { parroquia: "SUCRE (CAB. EN SUCRE-PATATE URCU)" }
        ]
      },
      {
        canton: "QUERO",
        parroquias: [
          { parroquia: "QUERO" },
          { parroquia: "RUMIPAMBA" },
          { parroquia: "YANAYACU - MOCHAPATA (CAB. EN YANAYACU)" }
        ]
      },
      {
        canton: "SAN PEDRO DE PELILEO",
        parroquias: [
          { parroquia: "PELILEO" },
          { parroquia: "PELILEO GRANDE" },
          { parroquia: "PELILEO" },
          { parroquia: "BENÍTEZ (PACHANLICA)" },
          { parroquia: "BOLÍVAR" },
          { parroquia: "COTALÓ" },
          { parroquia: "CHIQUICHA (CAB. EN CHIQUICHA GRANDE)" },
          { parroquia: "EL ROSARIO (RUMICHACA)" },
          { parroquia: "GARCÍA MORENO (CHUMAQUI)" },
          { parroquia: "GUAMBALÓ (HUAMBALÓ)" },
          { parroquia: "SALASACA" }
        ]
      },
      {
        canton: "SANTIAGO DE PÍLLARO",
        parroquias: [
          { parroquia: "CIUDAD NUEVA" },
          { parroquia: "PÍLLARO" },
          { parroquia: "PÍLLARO" },
          { parroquia: "BAQUERIZO MORENO" },
          { parroquia: "EMILIO MARÍA TERÁN (RUMIPAMBA)" },
          { parroquia: "MARCOS ESPINEL (CHACATA)" },
          { parroquia: "PRESIDENTE URBINA (CHAGRAPAMBA -PATZUCUL)" },
          { parroquia: "SAN ANDRÉS" },
          { parroquia: "SAN JOSÉ DE POALÓ" },
          { parroquia: "SAN MIGUELITO" }
        ]
      },
      {
        canton: "TISALEO",
        parroquias: [{ parroquia: "TISALEO" }, { parroquia: "QUINCHICOTO" }]
      }
    ]
  },
  {
    provincia: "ZAMORA CHINCHIPE",
    cantones: [
      {
        canton: "ZAMORA",
        parroquias: [
          { parroquia: "EL LIMÓN" },
          { parroquia: "ZAMORA" },
          { parroquia: "ZAMORA" },
          { parroquia: "CUMBARATZA" },
          { parroquia: "GUADALUPE" },
          { parroquia: "IMBANA (LA VICTORIA DE IMBANA)" },
          { parroquia: "PAQUISHA" },
          { parroquia: "SABANILLA" },
          { parroquia: "TIMBARA" },
          { parroquia: "ZUMBI" },
          { parroquia: "SAN CARLOS DE LAS MINAS" }
        ]
      },
      {
        canton: "CHINCHIPE",
        parroquias: [
          { parroquia: "ZUMBA" },
          { parroquia: "CHITO" },
          { parroquia: "EL CHORRO" },
          { parroquia: "EL PORVENIR DEL CARMEN" },
          { parroquia: "LA CHONTA" },
          { parroquia: "PALANDA" },
          { parroquia: "PUCAPAMBA" },
          { parroquia: "SAN FRANCISCO DEL VERGEL" },
          { parroquia: "VALLADOLID" },
          { parroquia: "SAN ANDRÉS" }
        ]
      },
      {
        canton: "NANGARITZA",
        parroquias: [
          { parroquia: "GUAYZIMI" },
          { parroquia: "ZURMI" },
          { parroquia: "NUEVO PARAÍSO" }
        ]
      },
      {
        canton: "YACUAMBI",
        parroquias: [
          { parroquia: "28 DE MAYO (SAN JOSÉ DE YACUAMBI)" },
          { parroquia: "LA PAZ" },
          { parroquia: "TUTUPALI" }
        ]
      },
      {
        canton: "YANTZAZA (YANZATZA)",
        parroquias: [
          { parroquia: "YANTZAZA (YANZATZA)" },
          { parroquia: "CHICAÑA" },
          { parroquia: "EL PANGUI" },
          { parroquia: "LOS ENCUENTROS" }
        ]
      },
      {
        canton: "EL PANGUI",
        parroquias: [
          { parroquia: "EL PANGUI" },
          { parroquia: "EL GUISME" },
          { parroquia: "PACHICUTZA" },
          { parroquia: "TUNDAYME" }
        ]
      },
      {
        canton: "CENTINELA DEL CÓNDOR",
        parroquias: [
          { parroquia: "ZUMBI" },
          { parroquia: "PAQUISHA" },
          { parroquia: "TRIUNFO-DORADO" },
          { parroquia: "PANGUINTZA" }
        ]
      },
      {
        canton: "PALANDA",
        parroquias: [
          { parroquia: "PALANDA" },
          { parroquia: "EL PORVENIR DEL CARMEN" },
          { parroquia: "SAN FRANCISCO DEL VERGEL" },
          { parroquia: "VALLADOLID" },
          { parroquia: "LA CANELA" }
        ]
      },
      {
        canton: "PAQUISHA",
        parroquias: [
          { parroquia: "PAQUISHA" },
          { parroquia: "BELLAVISTA" },
          { parroquia: "NUEVO QUITO" }
        ]
      }
    ]
  },
  {
    provincia: "GALAPAGOS",
    cantones: [
      {
        canton: "SAN CRISTÓBAL",
        parroquias: [
          { parroquia: "PUERTO BAQUERIZO MORENO" },
          { parroquia: "EL PROGRESO" },
          {
            parroquia:
              "ISLA SANTA MARÍA (FLOREANA) (CAB. EN PTO. VELASCO IBARRA)"
          }
        ]
      },
      {
        canton: "ISABELA",
        parroquias: [
          { parroquia: "PUERTO VILLAMIL" },
          { parroquia: "TOMÁS DE BERLANGA (SANTO TOMÁS)" }
        ]
      },
      {
        canton: "SANTA CRUZ",
        parroquias: [
          { parroquia: "PUERTO AYORA" },
          { parroquia: "BELLAVISTA" },
          { parroquia: "SANTA ROSA (INCLUYE LA ISLA BALTRA)" }
        ]
      }
    ]
  },
  {
    provincia: "SUCUMBIOS",
    cantones: [
      {
        canton: "LAGO AGRIO",
        parroquias: [
          { parroquia: "NUEVA LOJA" },
          { parroquia: "CUYABENO" },
          { parroquia: "DURENO" },
          { parroquia: "GENERAL FARFÁN" },
          { parroquia: "TARAPOA" },
          { parroquia: "EL ENO" },
          { parroquia: "PACAYACU" },
          { parroquia: "JAMBELÍ" },
          { parroquia: "SANTA CECILIA" },
          { parroquia: "AGUAS NEGRAS" }
        ]
      },
      {
        canton: "GONZALO PIZARRO",
        parroquias: [
          { parroquia: "EL DORADO DE CASCALES" },
          { parroquia: "EL REVENTADOR" },
          { parroquia: "GONZALO PIZARRO" },
          { parroquia: "LUMBAQUÍ" },
          { parroquia: "PUERTO LIBRE" },
          { parroquia: "SANTA ROSA DE SUCUMBÍOS" }
        ]
      },
      {
        canton: "PUTUMAYO",
        parroquias: [
          { parroquia: "PUERTO EL CARMEN DEL PUTUMAYO" },
          { parroquia: "PALMA ROJA" },
          { parroquia: "PUERTO BOLÍVAR (PUERTO MONTÚFAR)" },
          { parroquia: "PUERTO RODRÍGUEZ" },
          { parroquia: "SANTA ELENA" }
        ]
      },
      {
        canton: "SHUSHUFINDI",
        parroquias: [
          { parroquia: "SHUSHUFINDI" },
          { parroquia: "LIMONCOCHA" },
          { parroquia: "PAÑACOCHA" },
          { parroquia: "SAN ROQUE (CAB. EN SAN VICENTE)" },
          { parroquia: "SAN PEDRO DE LOS COFANES" },
          { parroquia: "SIETE DE JULIO" }
        ]
      },
      {
        canton: "SUCUMBÍOS",
        parroquias: [
          { parroquia: "LA BONITA" },
          { parroquia: "EL PLAYÓN DE SAN FRANCISCO" },
          { parroquia: "LA SOFÍA" },
          { parroquia: "ROSA FLORIDA" },
          { parroquia: "SANTA BÁRBARA" }
        ]
      },
      {
        canton: "CASCALES",
        parroquias: [
          { parroquia: "EL DORADO DE CASCALES" },
          { parroquia: "SANTA ROSA DE SUCUMBÍOS" },
          { parroquia: "SEVILLA" }
        ]
      },
      {
        canton: "CUYABENO",
        parroquias: [
          { parroquia: "TARAPOA" },
          { parroquia: "CUYABENO" },
          { parroquia: "AGUAS NEGRAS" }
        ]
      }
    ]
  },
  {
    provincia: "ORELLANA",
    cantones: [
      {
        canton: "ORELLANA",
        parroquias: [
          { parroquia: "PUERTO FRANCISCO DE ORELLANA (EL COCA)" },
          { parroquia: "DAYUMA" },
          { parroquia: "TARACOA (NUEVA ESPERANZA: YUCA)" },
          { parroquia: "ALEJANDRO LABAKA" },
          { parroquia: "EL DORADO" },
          { parroquia: "EL EDÉN" },
          { parroquia: "GARCÍA MORENO" },
          { parroquia: "INÉS ARANGO (CAB. EN WESTERN)" },
          { parroquia: "LA BELLEZA" },
          { parroquia: "NUEVO PARAÍSO (CAB. EN UNIÓN" },
          { parroquia: "SAN JOSÉ DE GUAYUSA" },
          { parroquia: "SAN LUIS DE ARMENIA" }
        ]
      },
      {
        canton: "AGUARICO",
        parroquias: [
          { parroquia: "TIPITINI" },
          { parroquia: "NUEVO ROCAFUERTE" },
          { parroquia: "CAPITÁN AUGUSTO RIVADENEYRA" },
          { parroquia: "CONONACO" },
          { parroquia: "SANTA MARÍA DE HUIRIRIMA" },
          { parroquia: "TIPUTINI" },
          { parroquia: "YASUNÍ" }
        ]
      },
      {
        canton: "LA JOYA DE LOS SACHAS",
        parroquias: [
          { parroquia: "LA JOYA DE LOS SACHAS" },
          { parroquia: "ENOKANQUI" },
          { parroquia: "POMPEYA" },
          { parroquia: "SAN CARLOS" },
          { parroquia: "SAN SEBASTIÁN DEL COCA" },
          { parroquia: "LAGO SAN PEDRO" },
          { parroquia: "RUMIPAMBA" },
          { parroquia: "TRES DE NOVIEMBRE" },
          { parroquia: "UNIÓN MILAGREÑA" }
        ]
      },
      {
        canton: "LORETO",
        parroquias: [
          { parroquia: "LORETO" },
          { parroquia: "AVILA (CAB. EN HUIRUNO)" },
          { parroquia: "PUERTO MURIALDO" },
          { parroquia: "SAN JOSÉ DE PAYAMINO" },
          { parroquia: "SAN JOSÉ DE DAHUANO" },
          { parroquia: "SAN VICENTE DE HUATICOCHA" }
        ]
      }
    ]
  },
  {
    provincia: "SANTO DOMINGO DE LOS TSACHILAS",
    cantones: [
      {
        canton: "SANTO DOMINGO",
        parroquias: [
          { parroquia: "ABRAHAM CALAZACÓN" },
          { parroquia: "BOMBOLÍ" },
          { parroquia: "CHIGUILPE" },
          { parroquia: "RÍO TOACHI" },
          { parroquia: "RÍO VERDE" },
          { parroquia: "SANTO DOMINGO DE LOS COLORADOS" },
          { parroquia: "ZARACAY" },
          { parroquia: "SANTO DOMINGO DE LOS COLORADOS" },
          { parroquia: "ALLURIQUÍN" },
          { parroquia: "PUERTO LIMÓN" },
          { parroquia: "LUZ DE AMÉRICA" },
          { parroquia: "SAN JACINTO DEL BÚA" },
          { parroquia: "VALLE HERMOSO" },
          { parroquia: "EL ESFUERZO" },
          { parroquia: "SANTA MARÍA DEL TOACHI" }
        ]
      }
    ]
  },
  {
    provincia: "SANTA ELENA",
    cantones: [
      {
        canton: "SANTA ELENA",
        parroquias: [
          { parroquia: "BALLENITA" },
          { parroquia: "SANTA ELENA" },
          { parroquia: "SANTA ELENA" },
          { parroquia: "ATAHUALPA" },
          { parroquia: "COLONCHE" },
          { parroquia: "CHANDUY" },
          { parroquia: "MANGLARALTO" },
          { parroquia: "SIMÓN BOLÍVAR (JULIO MORENO)" },
          { parroquia: "SAN JOSÉ DE ANCÓN" }
        ]
      },
      {
        canton: "LA LIBERTAD",
        parroquias: [
          {
            parroquia: "LA LIBERTAD"
          }
        ]
      },
      {
        canton: "SALINAS",
        parroquias: [
          { parroquia: "CARLOS ESPINOZA LARREA" },
          { parroquia: "GRAL. ALBERTO ENRÍQUEZ GALLO" },
          { parroquia: "VICENTE ROCAFUERTE" },
          { parroquia: "SANTA ROSA" },
          { parroquia: "SALINAS" },
          { parroquia: "ANCONCITO" },
          { parroquia: "JOSÉ LUIS TAMAYO (MUEY)" }
        ]
      }
    ]
  }
];
