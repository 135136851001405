<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/workwithus/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Trabaja con Nosotros</h2>
        <p class="text-gray-100">Home / Trabaja con nosotros</p>
      </div>
    </div>

    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <div class="container mt-5 md:mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->

        <div class="mx-auto" data-aos="fade-up" data-aos-duration="1000">
          <h4 class="text-3xl azul mb-2 font-bold text-center">
            ¿Quieres trabajar con el equipo CREDIL?
          </h4>

          <p class="azul text-center text-sm w-full md:w-3/4 mx-auto mb-10">
            Del compromiso individual al esfuerzo de un equipo. Eso hace que un
            equipo funcione, que una compañía funcione, que una sociedad y
            civilización funcionen.” Vince Lombardi. Forma parte del Equipo de
            Cooperativa CREDIL
          </p>
        </div>
      </div>

      <!-- FORMULARIO -->
      <div
        class="container mx-auto w-full md:w-1/2  bg-blue-700 rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h2 class="bg-white azul rounded-t text-xl py-1 px-6 font-semibold">
          Ingrese los datos
        </h2>
        <form class="p-4 md:p-6">
          <!-- col 1 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="names" class="text-white font-bold block"
                >Nombre Completo</label
              >
              <input
                type="text"
                id="names"
                v-model="names"
                placeholder="JUAN PEREZ"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>

            <div class="col-span-2 md:col-span-1">
              <label for="price" class="block text-white font-bold"
                >Cédula Identidad</label
              >
              <div class="mt-1 relative rounded w-full md:w-11/12 md:p-1">
                <input
                  v-model="identification"
                  type="text"
                  name="price"
                  id="price"
                  class="focus:outline-none block w-full p-2 sm:text-sm rounded border-r-2 border-gray-600"
                  placeholder="06001512345"
                />
                <div class="absolute inset-y-0 right-0 flex items-center ">
                  <select
                    id="currency"
                    name="currency"
                    v-model="typeIdentification"
                    class=" focus:outline-none h-full mr-2 p-1 border-transparent bg-transparent sm:text-sm rounded"
                  >
                    <option value="cedula" selected>Cédula</option>
                    <option value="cedula-extranjera">Cédula extranjera</option>
                    <option value="pasaporte">Pasaporte, NIF/ NIT</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- col 2 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="born" class="text-white font-bold block"
                >Fecha de Nacimiento</label
              >
              <input
                type="date"
                id="born"
                v-model="born"
                placeholder="1990"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none w-full md:w-11/12 placeholder-gray-400"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Género</label
              >
              <select
                v-model="gender"
                class=" border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="hombre" selected>Hombre</option>
                <option value="mujer">Mujer</option>
              </select>
            </div>
          </div>

          <!-- col 3 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Estado Civil</label
              >
              <select
                v-model="civilState"
                class="border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="soltero" selected>Soltero (a)</option>
                <option value="casado">Casado (a)</option>
                <option value="separado">Separado (a)/ Divorciado (a)</option>
                <option value="viudo">Viudo (a)</option>
                <option value="union-libre">Unión Libre</option>
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-white font-bold block"
                >Teléfono</label
              >
              <input
                type="text"
                id="text"
                v-model="phone"
                placeholder="0991234567"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none  w-full md:w-11/12"
              />
            </div>
          </div>

          <!-- col 4 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-white font-bold block"
                >Correo</label
              >
              <input
                type="email"
                id="email"
                v-model="email"
                placeholder="email@email.com"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-white font-bold block">
                Provincia
              </label>

              <select
                v-model="province"
                @change="onChangeParroquia($event)"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccione Provincia</option>
                <option
                  :value="pro.provincia"
                  v-for="pro in provincias_totales"
                  :key="pro.provincia"
                  >{{ pro.provincia }}</option
                >
              </select>
            </div>
          </div>

          <!-- col 5 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block">
                Canton
              </label>
              <select
                v-model="parish"
                @change="onChangeCiudad($event)"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccione un cantón</option>
                <option
                  :value="cant.canton"
                  v-for="cant in cantones_totales"
                  :key="cant.canton"
                  >{{ cant.canton }}</option
                >
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block">
                Ciudad
              </label>
              <select
                v-model="city"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccionar una Ciudad</option>
                <option
                  :value="ciudad.parroquia"
                  v-for="ciudad in ciudades_totales"
                  :key="ciudad.parroquia"
                  >{{ ciudad.parroquia }}</option
                >
              </select>
            </div>
          </div>
          <!-- col 6 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="address" class="text-white font-bold block"
                >Dirección</label
              >
              <input
                id="address"
                placeholder="Ingrese su dirección Exacta"
                v-model="address"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Tiene Vehículo</label
              >
              <select
                v-model="vehicle"
                class="border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="si" selected>Si</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <!-- col 7 -->
          <div class="mb-5">
            <label for="city" class="text-white font-bold block">
              Tipo de Licencia de conducir
            </label>
            <div class="mt-2 flex flex-wrap">
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white"
                    >Motocicletas, Automóviles / Carros</span
                  >
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white">
                    Vehículos para transporte de personas</span
                  >
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white">Camiones de carga</span>
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white">Vehículos agrícolas</span>
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white">Vehículos industriales</span>
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input type="checkbox" class="form-checkbox" />
                  <span class="mx-2 text-white">No tengo</span>
                </label>
              </div>
            </div>
          </div>

          <!-- col 8 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="title" class="text-white font-bold block"
                >Título Académico</label
              >
              <input
                id="title"
                placeholder="Ing. Economista"
                v-model="title"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Nivel de estudios</label
              >
              <select
                v-model="studyLevel"
                class=" border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="primaria"> Primaria, Secundaria</option>
                <option value="tercer-nivel">Tercer Nivel / Universidad</option>
                <option value="cuarto-nivel">Cuarto Nivel / Maestría</option>
              </select>
            </div>
          </div>

          <!-- col 9 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Situación Actual</label
              >
              <select
                v-model="actualSituation"
                class="border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="no-tengo-empleo">No tengo empleo</option>
                <option value="estoy-buscando"
                  >Estoy buscando trabajo activamente</option
                >
                <option value="estoy-trabajando"
                  >Estoy trabajando actualmente</option
                >
                <option value="no-busco-trabajo"
                  >No buscó trabajo pero estoy dispuesto a escuchar
                  ofertas</option
                >
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="salary" class="text-white font-bold block"
                >Salario mínimo aceptado</label
              >
              <input
                type="text"
                id="salary"
                v-model="salary"
                placeholder="$ 2000"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none w-full md:w-11/12"
              />
            </div>
          </div>

          <!-- col 10 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Provincia preferente de trabajo</label
              >
              <select
                v-model="preferProvince"
                class="border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="cotopaxi">Cotopaxi</option>
                <option value="tungurahua">Tungurahua</option>
                <option value="chimborazo">Chimborazo</option>
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="reason" class="text-white font-bold block"
                >Por qué desea formar parte
              </label>
              <input
                type="text"
                id="reason"
                v-model="reason"
                placeholder="Porque deseas ser parte de la coop"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none w-full md:w-11/12"
              />
            </div>
          </div>

          <!-- col 11 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-white font-bold block"
                >Puesto de trabajo</label
              >
              <select
                v-model="workstation"
                class="border-gray-600 p-2 my-2 rounded focus:outline-none  w-full md:w-11/12"
              >
                <option value="cajera">Cajera</option>
                <option value="atencion-al-cliente">Atención al Cliente</option>
                <option value="asesor-de-negocios">Asesor de Negocios</option>
                <option value="escuela-de-negocios">Escuela de Negocios</option>
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <!-- botontes -->
              <div
                class="overflow-hidden relative w-64 mt-8 mb-4 flex justify-center flex-col"
              >
                <button
                  type="button"
                  class="cursor-pointer bg-gray-700 hover:bg-gray-800 text-white py-1 px-2 rounded w-full mx-2 items-center"
                >
                  <span>Subir Curriculum PDF</span>
                </button>
                <input
                  class=" absolute block w-full h-full opacity-0 pin-r pin-t cursor-pointer rounded"
                  type="file"
                  name="documents[]"
                  accept="pdf/*"
                  @change="uploadPdf($event)"
                />
                <span class="text-white text-sm ml-4">{{ loading }}</span>
              </div>
              <button
                type="button"
                @click="saveFormButton"
                class="bg-gray-700 hover:bg-gray-800 text-white py-1 px-2 rounded w-3/5 mx-2 items-center"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
import api from "@/api/workWithUs";
import { provincias } from "@/api/provincias";
import validar_cedula from "@/api/validar_cedula";
import validar_celular from "@/api/validar_celular";
import validar_email from "@/api/validar_email";

export default {
  name: "WorkWithUs",
  data() {
    return {
      loading: "",
      names: "",
      email: "",
      phone: "",
      address: "",
      vehicle: "",
      identification: "",
      typeIdentification: "",
      civilState: "",
      preferProvince: "",
      gender: "",
      salary: "",
      born: "",
      actualSituation: "",
      reason: "",
      studyLevel: "",
      title: "",
      province: "",
      city: "",
      parish: "",
      agencyNear: "",
      cv: "",
      workstation: "",
      provincias_totales: [],
      cantones_totales: [],
      ciudades_totales: [],
      body: {}
    };
  },

  mounted() {
    this.call_provincias();
  },

  methods: {
    // LLAMA A TODAS LAS PROVINCIAS
    call_provincias() {
      this.provincias_totales = provincias;
    },

    // CAMBIA A LA CANTONES
    onChangeParroquia(event) {
      let provincia_selecciona = event.target.value;
      this.provincias_totales.forEach(prov => {
        if (provincia_selecciona == prov.provincia) {
          this.cantones_totales = prov.cantones;
        }
      });
    },

    // CAMBIA A LA CIUDADES
    onChangeCiudad(event) {
      let canton_seleccionado = event.target.value;
      this.cantones_totales.forEach(cant => {
        if (canton_seleccionado == cant.canton) {
          this.ciudades_totales = cant.parroquias;
        }
      });
    },

    // SAVE FORM
    saveFormButton() {
      if (
        this.names == "" ||
        this.identification == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.province == "" ||
        this.parish == "" ||
        this.city == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        if (validar_cedula(this.identification)) {
          if (validar_celular(this.phone)) {
            if (validar_email(this.email)) {
              this.body = {
                names: this.names,
                email: this.email,
                phone: this.phone,
                workstation: this.workstation,
                address: this.address,
                vehicle: this.vehicle,
                born: this.born,
                identification: this.identification,
                typeIdentification: this.typeIdentification,
                civilState: this.civilState,
                preferProvince: this.preferProvince,
                gender: this.gender,
                salary: this.salary,
                actualSituation: this.actualSituation,
                reason: this.reason,
                studyLevel: this.studyLevel,
                title: this.title,
                province: this.province,
                city: this.city,
                parish: this.parish,
                agencyNear: this.agencyNear,
                cv: this.cv
              };

              console.log("enviando al post ", this.body);
              api.saveForm(this.body).then(resp => {
                if (resp.status == "ok") {
                  this.$swal("Guardado", "", "success").then(() => {
                    this.$router.push("/");
                    // this.names = "";
                    // this.identification = "";
                    // this.email = "";
                    // this.phone = "";
                    // this.mount = "";
                    // this.economicActivity = "";
                    // this.province = "";
                    // this.city = "";
                    // this.parish = "";
                    // this.agencyNear = "";
                  });
                } else {
                  this.$swal("Error al guardar", "", "error");
                }
              });
            } else {
              this.$swal("Email no válido", "", "error");
            }
          } else {
            this.$swal("Celular no válido", "", "error");
          }
        } else {
          this.$swal("Cédula no válida", "", "error");
        }
      }
    },

    // IMAGEN BANNER
    uploadPdf(event) {
      let data = new FormData();
      this.loading = "Subiendo archivo ...";
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      console.log("pdf", data);
      if (data != "")
        api.uploadPdf(data).then(resp => {
          console.log("resp upload imagen : ", resp);
          this.loading = "Archivo subido correctamente";
          this.cv = resp;
        });
    }
  }
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
