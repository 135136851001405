<template>
  <main id="main" :style="{
    'background-image': 'url(' + require('@/assets/img/home/fondo.jpg') + ')'
  }">
    <div class="container mx-auto my-8 lg:px-40 sm:px-10">
      <header class="w-full container mx-auto">
        <div class="flex flex-col items-center pt-12">
          <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
            Política General de Protección de Datos Personales
          </a>
          <p class="text-gray-600 text-center">
            La política de protección de datos personales de la Cooperativa de Ahorro y Crédito Credil Ltda. está
            diseñada para garantizar la privacidad y seguridad de la información de todos los involucrados en nuestras
            actividades, incluyendo clientes, empleados, proveedores y cualquier otra parte relacionada. Esta política
            se rige por la Ley Orgánica de Protección de Datos Personales y las normas internacionales de seguridad de
            la información abarcando todos los procesos y operaciones que involucren el tratamiento de datos personales,
            incluyendo su almacenamiento, uso, circulación y supresión.

            Este documento tiene como objetivo informarle sobre los principios, derechos, obligaciones y mecanismos de
            tutela, que regulan el manejo de sus datos personales, de acuerdo con la legislación ecuatoriana vigente.
          </p>
          <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
        </div>
      </header>
      <section>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark hover:text-red border-b-2 border-gray-900"
            @click="index = 0, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 0 ? 'azul' : 'text-dark'">Alcance</span>
          </div>
          <div v-show="activeIndex === 0" class="accordion-content p-4 text-gray-700">
            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
              Es aplicable a todos incluyendo; clientes, usuarios, colaboradores, proveedores, accionistas y cualquier
              otra persona relacionada con la entidad que manejen</span>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
            @click="index = 1, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 1 ? 'azul' : 'text-dark'">Definiciones</span>
          </div>
          <div v-show="activeIndex === 1" class="accordion-content p-4 text-gray-700">
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Autoridad de Protección de Datos Personales:</b>&nbsp;Es una organización que se encarga de
                  asegurarse de que las leyes sobre la protección de datos personales se cumplan para proteger los
                  derechos de las personas sobre su información.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Anonimización:</b>&nbsp;Es el proceso de hacer que no sea posible identificar a una persona a
                  partir de sus datos, sin hacer un esfuerzo excesivo.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Base de datos o fichero:</b>&nbsp;Es un grupo de datos organizados de cualquier manera, que puede
                  ser en un solo lugar o repartidos en diferentes sitios, y que se guardan y usan de diferentes formas.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Consentimiento:</b>&nbsp;Es cuando una persona acepta de forma clara y consciente que sus datos
                  personales seas usados por alguien más.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Dato biométrico:</b>&nbsp;Es un dato único sobre las características físicas o comportamentales de
                  una persona, como sus huellas dactilares o su rostro, que sirve para identificarla.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Dato genético:</b>&nbsp;Es un dato único sobre la información genética de una persona, que da
                  detalles sobre su salud o características físicas heredadas.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Dato personal:</b>&nbsp;Es cualquier información que puede identificar a una persona, ya sea
                  directamente o de manera indirecta.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Datos personales crediticios:</b>&nbsp;Son los datos sobre el comportamiento financiero de una
                  persona, que se usan para analizar su capacidad económica.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Datos sensibles:</b>&nbsp;Son datos que incluyen información personal delicada como etnia,
                  religión, orientación sexual, salud, etc., y cuyo mal uso puede llevar a discriminación o afectar
                  derechos fundamentales.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Delegado de protección de datos:</b>&nbsp;Es la persona que se encarga de asegurarse de que se
                  cumplan las leyes sobre protección de datos, y que actúa como intermediario entre la organización y la
                  autoridad de protección de datos.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Destinatario:</b>&nbsp;Es la persona o entidad que recibe los datos personales.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Elaboración de perfiles:</b>&nbsp;Es el uso de datos personales para evaluar o predecir aspectos de
                  una persona, como su rendimiento, salud, preferencias, ubicación, etc.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Encargado del tratamiento de datos personales:</b>&nbsp;Es la persona o entidad que maneja los
                  datos personales por cuenta de otra persona o entidad que es responsable de esos datos.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark hover:text-red border-b-2 border-gray-900"
            @click="index = 4, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 4 ? 'azul' : 'text-dark'">¿Durante cuánto tiempo
              se conserva sus datos personales?</span>
          </div>
          <div v-show="activeIndex === 4" class="accordion-content p-4 text-gray-700">
            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
              Los datos personales se almacenarán únicamente durante el tiempo necesario para cumplir con las
              finalidades del tratamiento, de acuerdo con los períodos legales establecidos y el máximo necesario para
              la prescripción de acciones legales. Después de este período, los datos serán eliminados, anonimizados o
              bloqueados según los mecanismos implementados.</span>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
            @click="index = 5, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 5 ? 'azul' : 'text-dark'">¿Qué tipos de
              tratamiento de datos personales usamos?</span>
          </div>
          <div v-show="activeIndex === 5" class="accordion-content p-4 text-gray-700">
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Tratamiento de datos personales en canales digitales:</b>&nbsp;El tratamiento de datos personales
                  en canales digitales es un aspecto importante para la Cooperativa de Ahorro y Crédito Credil Ltda. A
                  continuación, se presentan las políticas y procedimientos relacionados con el tratamiento de datos
                  personales en los canales digitales de la cooperativa:
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Sitios Web Institucionales:</b>&nbsp;Los sitios web de la cooperativa se utilizan exclusivamente
                  para fines institucionales y se protegen los datos proporcionados por los usuarios con altos
                  estándares de seguridad para ofrecer una buena experiencia y acceso a los servicios. Sin embargo, los
                  enlaces a otros sitios externos no están bajo la responsabilidad de la cooperativa, por lo que se
                  recomienda verificar la seguridad de estos sitios externos.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Aplicaciones Móviles:</b>&nbsp;Las aplicaciones móviles de la cooperativa tienen altos niveles de
                  seguridad y solicitan información personal necesaria para su funcionamiento, pero no requieren datos
                  sensibles. Las aplicaciones piden permisos específicos del dispositivo que deben ser aceptados por el
                  usuario.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Cookies en Plataformas Digitales:</b>&nbsp;Las cookies son archivos que se guardan en el
                  dispositivo del usuario al visitar una página web. Estas cookies recopilan información para mejorar la
                  experiencia de navegación y facilitar el uso de la página. Se almacenan en el navegador del
                  dispositivo.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Comunicación en Redes Sociales:</b>&nbsp;Mantenemos perfiles en plataformas sociales como
                  Instagram, Facebook, y otras redes sociales; y al interactuar con nuestras páginas oficiales,
                  recopilaremos la información personal que elija proporcionarnos, incluyendo sus datos de contacto.
                  Esta información se utilizará para mejorar nuestra comunicación y fortalecer la relación con nuestros
                  seguidores, garantizando siempre el respeto a su privacidad y la seguridad de sus datos.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Recolección de datos biométricos:</b>&nbsp;Tiene como finalidad principal fortalecer la seguridad y
                  autenticación de los socios, colaboradores y clientes que utilicen de nuestros servicios. Al utilizar
                  datos como huellas dactilares, reconocimiento facial, patrones de voz y demás datos biométricos,
                  logramos establecer una identidad única y difícil de replicar, lo que minimiza el riesgo de fraudes y
                  accesos no autorizados. La implementación de estas tecnologías permite simplificar y agilizar procesos
                  como la identificación en nuestras sucursales, el acceso a plataformas digitales y la gestión de
                  transacciones financieras. Esto no solo mejora la experiencia del usuario, sino que también contribuye
                  a la eficiencia operativa de nuestra cooperativa.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Tratamiento de datos personales en canales presenciales:</b>&nbsp;La Cooperativa de Ahorro y
                  Crédito Credil Ltda. recopila datos personales de sus socios y clientes en diferentes áreas operativas
                  para brindar productos y servicios financieros. A continuación, se presentan los detalles sobre el
                  tratamiento de datos personales en cada una de estas áreas:
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Cajeros automáticos (ATMs):</b>&nbsp;Los cajeros automáticos de la cooperativa recopilar
                  información personal para verificar datos antes de realizar transacciones como depósitos y retiros.
                  Esta información se utiliza para contabilidad, auditorías y cumplir con normativas para asegurar la
                  seguridad y validez de las transacciones.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Videovigilancia:</b>&nbsp;La cooperativa usa cámaras de videovigilancia en sus instalaciones y
                  cajeros automáticos para proteger a las personas y los bienes. La información recolectada puede ser
                  compartida con autoridades si es necesario. Al ingresar a las instalaciones, se considera que el
                  usuario autoriza el uso de estas imágenes.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Canales Presenciales:</b>&nbsp;En las oficinas de la cooperativa, se recopilar datos personales de
                  los clientes para ofrecer servicios financieros. Estos datos son necesarios para gestionar los
                  productos y servicios que la cooperativa proporciona.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
            @click="index = 6, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 6 ? 'azul' : 'text-dark'">Obligaciones de los
              funcionarios de la cooperativa y terceros con acceso a datos personales</span>
          </div>
          <div v-show="activeIndex === 6" class="accordion-content p-4 text-gray-700">
            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
              Los funcionarios de la Cooperativa y los terceros que tengan acceso legítimo a los datos personales de los
              que la compañía es responsable, deben cumplir con las siguientes obligaciones:</span>
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Firmar acuerdos de confidencialidad para asegurar la protección de la infromación.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Manejar los datos personales según las instrucciones del responsable y solo para el propósito
                  establecido.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  No compartir ni comunicar los datos personales a otras personas.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Los funcionarios o encargados deben tener políticas y medidas de seguridad para proteger los datos
                  personales.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  El Oficial de Seguridad de la Información deberá garantizar la formación, concienciación y
                  responsabilidad a todo el personal de la institución en el cumplimiento de LOPDP.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
            @click="index = 2, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 2 ? 'azul' : 'text-dark'">¿Quién es el
              responsable del tratamiento de datos personales?</span>
          </div>
          <div v-show="activeIndex === 2" class="accordion-content p-4 text-gray-700">
            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
              Datos de Contacto del responsable del tratamiento de datos personales de la Cooperativa de Ahorro y
              Crédito Credil Ltda.</span>
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Razón Social:</b>&nbsp;Cooperativa de Ahorro y Crédito Credil Ltda.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Ruc:</b>&nbsp;1891735002001
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Dirección:</b>&nbsp;Salcedo Av. 24 de mayo y Ana Paredes.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Correo electrónico:</b>&nbsp;credil@coopcredil.com
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Teléfono:</b>&nbsp;032730307
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="border-b">
          <div class="accordion-header p-4 text-dark border-b-2 border-gray-900"
            @click="index = 3, toggleAccordion(index)">
            <span class="text-xl font-bold" :class="isOpen && index == 3 ? 'azul' : 'text-dark'">¿Quién es el delegado
              del tratamiento de datos personales?</span>
          </div>
          <div v-show="activeIndex === 3" class="accordion-content p-4 text-gray-700">
            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
              Datos de Contacto del responsable del tratamiento de datos personales de la Cooperativa de Ahorro y
              Crédito Credil Ltda.</span>
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Nombre y Apellido:</b>&nbsp;Silvia Janeth Paguay Sánchez
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Teléfono:</b>&nbsp;032730307
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Dirección:</b>&nbsp;Salcedo Av. 24 de mayo y Ana Paredes.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Correo electrónico:</b>&nbsp;dpo@coopcredil.com
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>

    <div class="w-full bg-gray-200">
      <div class="container mx-auto my-8 lg:px-40 sm:px-10">
        <header class="w-full container mx-auto">
          <div class="flex flex-col items-center pt-12">
            <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
              ¿Bajo qué principios se realiza el tratamiento de sus datos personales?
            </a>
            <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
          </div>
        </header>
      </div>
      <div class="max-w-screen-xl mx-auto px-5 pb-4">
        <div class="grid sm:grid-cols-2 md:grid-cols-3 mt-8 gap-8">
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-briefcase">
                <symbol id="ai:bx:bxs-briefcase">
                  <path
                    d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v3h20V8c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm5 10h-4v-2H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-8v2z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-briefcase"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Juridicidad</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Los datos personales son tratados con estricto apego y
                cumplimiento a los principios, derechos y obligaciones establecidas en la Constitución, ley orgánica de
                protección de datos personales, en su reglamento y demás normas nacionales e internacionales ISO 27001,
                27002, 27017, 27108, 27005, 27701.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-bot">
                <symbol id="ai:bx:bxs-bot">
                  <path
                    d="M21 10.975V8a2 2 0 0 0-2-2h-6V4.688c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 2 12v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632zM7 12c0-1.104.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2S7 13.104 7 12zm8.998 6c-1.001-.003-7.997 0-7.998 0v-2s7.001-.002 8.002 0l-.004 2zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-bot"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Confidencialidad</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">La recolección y tratamiento de datos personales se hará
                con el mayor nivel de sigilo y confidencialidad. La información no se utilizará para propósitos
                diferentes a los mencionados.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-user">
                <symbol id="ai:bx:bxs-user">
                  <path
                    d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-user"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Seguridad de la Información y Responsabilidad Proactiva</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Nuestra cooperativa se destaca en el cumplimento y la
                protección de datos personales, implementando un sistema de gestión de seguridad para reducir los
                riesgos que puedan comprometer la privacidad de sus datos personales. Las normas que seguimos están
                reconocidas internacionalmente bajo los estándares ISO 27001, 27002, 27005, 27017,27018 y 27701.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-window-alt">
                <symbol id="ai:bx:bxs-window-alt">
                  <path
                    d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-3 3h2v2h-2V6zm-3 0h2v2h-2V6zM4 19v-9h16.001l.001 9H4z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-window-alt"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Lealtad</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Los datos personales serán usados exclusivamente para los
                fines descritos en este documento y no para objetivos ilícitos o desleales.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-data">
                <symbol id="ai:bx:bxs-data">
                  <path
                    d="M20 6c0-2.168-3.663-4-8-4S4 3.832 4 6v2c0 2.168 3.663 4 8 4s8-1.832 8-4V6zm-8 13c-4.337 0-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3c0 2.168-3.663 4-8 4z"
                    fill="currentColor"></path>
                  <path d="M20 10c0 2.168-3.663 4-8 4s-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-data"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Transparencia</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Este documento garantiza claridad al informar sobre los
                derechos que tiene respecto a los datos proporcionados.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-bot">
                <symbol id="ai:bx:bxs-bot">
                  <path
                    d="M21 10.975V8a2 2 0 0 0-2-2h-6V4.688c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 2 12v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632zM7 12c0-1.104.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2S7 13.104 7 12zm8.998 6c-1.001-.003-7.997 0-7.998 0v-2s7.001-.002 8.002 0l-.004 2zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-bot"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Finalidad</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">En conformidad con la ley, nuestra cooperativa ha
                establecido propósitos específicos para el tratamiento de los datos personales, según lo descrito en
                este documento. Los datos no serán utilizados para otros fines.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-file-find">
                <symbol id="ai:bx:bxs-file-find">
                  <path
                    d="M6 22h12c.178 0 .348-.03.512-.074l-3.759-3.759A4.966 4.966 0 0 1 12 19c-2.757 0-5-2.243-5-5s2.243-5 5-5s5 2.243 5 5a4.964 4.964 0 0 1-.833 2.753l3.759 3.759c.044-.164.074-.334.074-.512V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2z"
                    fill="currentColor"></path>
                  <circle cx="12" cy="14" r="3" fill="currentColor"></circle>
                </symbol>
                <use xlink:href="#ai:bx:bxs-file-find"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Pertinencia y Minimización</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Los datos personales recogidos serán relevantes y limitados
                a lo necesario para cumplir con los propósitos establecidos.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-user">
                <symbol id="ai:bx:bxs-user">
                  <path
                    d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                    fill="currentColor"></path>
                </symbol>
                <use xlink:href="#ai:bx:bxs-user"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Proporcionalidad</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">La recolección de datos personales será adecuada y
                necesaria para lograr los objetivos.</p>
            </div>
          </div>
          <div class="flex gap-4 items-start">
            <div class="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0"> <svg width="1em" height="1em"
                viewBox="0 0 24 24" class="text-white" data-icon="bx:bxs-file-find">
                <symbol id="ai:bx:bxs-file-find">
                  <path
                    d="M6 22h12c.178 0 .348-.03.512-.074l-3.759-3.759A4.966 4.966 0 0 1 12 19c-2.757 0-5-2.243-5-5s2.243-5 5-5s5 2.243 5 5a4.964 4.964 0 0 1-.833 2.753l3.759 3.759c.044-.164.074-.334.074-.512V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2z"
                    fill="currentColor"></path>
                  <circle cx="12" cy="14" r="3" fill="currentColor"></circle>
                </symbol>
                <use xlink:href="#ai:bx:bxs-file-find"></use>
              </svg> </div>
            <div>
              <h3 class="font-semibold text-lg">Calidad y Exactitud</h3>
              <p class="text-slate-500 mt-2 leading-relaxed">Los datos personales proporcionados deben ser claros,
                precisos y actualizados. Usted puede ejercer los derechos de acceso, corrección y actualización según
                los métodos del ARCA. Los datos serán proporcionados por el titular o por su representante legal en el
                caso de niños, niñas y adolescentes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto my-8 lg:px-40 sm:px-10">
      <header class="w-full container mx-auto">
        <div class="flex flex-col items-center pt-12">
          <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
            ¿Cuáles son los derechos cuando nos facilita sus datos personales?
          </a>
          <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
        </div>
      </header>
    </div>
    <div
      class="mt-8 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341139.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Derecho de Acceso
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              Puede conocer y obtener acceso a sus datos personales de forma gratuita, sin necesidad de justificar su
              solicitud. Un delegado de protección de datos atenderá las solicitudes en un plazo de quince (15) días.
              Este derecho no puede ejercerse de manera que se constituya en abuso del derecho.
            </p>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341134.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Derecho de Rectificación y Actualización
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              Puede corregir y actualizar datos personales incorrectos o incompletos, presentando la documentación
              necesaria. El delegado de protección de datos atenderá la solicitud en un plazo de quince (15) días y, si
              corresponde, informará a los destinatarios de los datos sobre la corrección.</p>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-gray-50 dark:bg-gray-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div
          class="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white dark:group-hover:bg-gray-800">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341025.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Derecho de Oposición
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              Puede oponerse o negarse al tratamiento de sus datos personales.
            </p>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341139.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->
          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Derecho de Portabilidad
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              Puede solicitar que sus datos personales sean transferidos a otro responsable del tratamiento, siempre que
              sea técnicamente posible.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-8 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-2 lg:divide-y-0 xl:grid-cols-2">
      <div
        class="group relative bg-white dark:bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div class="relative space-y-8 py-12 p-8">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341160.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->

          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Derecho de Eliminación
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              Puede solicitar la eliminación de sus datos personales en los siguientes casos:
            </p>
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si el tratamiento no cumple con los principios mencionados.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si el tratamiento no es necesario para los fines descritos.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si el tratamiento afecta derechos fundamentales o libertades individuales.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si los datos han cumplido el propósito para el cual fueron recolectados.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si ha vencido el período de conservación de los datos.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si se revoca el consentimiento otorgado o se alega no haberlo otorgado para ciertos fines, sin
                  necesidad de justificar.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Si existe una obligación legal.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="group relative bg-gray-50 dark:bg-gray-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div
          class="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white dark:group-hover:bg-gray-800">
          <!-- <img src="https://cdn-icons-png.flaticon.com/512/4341/4341025.png" class="w-12" width="512" height="512"
            alt="burger illustration"> -->
          <div class="space-y-2">
            <h5 class="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary">
              Base Legal
            </h5>
            <p class="text-gray-600 dark:text-gray-300">
              La protección de datos personales en Ecuador se encuentra fundamentada en la Constitución, la cual
              garantiza a los ciudadanos el derecho inalienable de acceso, control y resguardo de su información
              personal. Este derecho se refuerza mediante la Ley Orgánica de Protección de Datos Personales (LOPDP), que
              establece un marco normativo claro que regula los principios y derechos fundamentales, facultando a los
              titulares para ejercer un control efectivo sobre sus datos y salvaguardar su privacidad. Además de la
              LOPDP, existen otras normativas relevantes que contribuyen significativamente a la protección de datos en
              el país
            </p>
            <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Ley Orgánica de Economía Popular y Solidaria:</b>&nbsp;Esta ley protege la información de los
                  miembros de organizaciones del sector solidario, garantizando la confidencialidad en todas las
                  transacciones realizadas.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Normativa del sector financiero popular:</b>&nbsp;Se establecen directrices específicas para el
                  manejo seguro de datos personales en las entidades que operan en este sector, promoviendo prácticas de
                  responsabilidad y transparencia.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Código Orgánico Monetario y Financiero:</b>&nbsp;Proporciona un marco normativo específico que
                  asegura la privacidad de los usuarios financieros y establece estándares rigurosos para el tratamiento
                  de información sensible.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Ley Orgánica de Defensa al Consumidor:</b>&nbsp;Obliga a las empresas a gestionar los datos de los
                  consumidores de manera confidencial, garantizando que estos no se utilicen sin el consentimiento
                  explícito del titular y que los consumidores sean debidamente informados sobre el tratamiento de su
                  información.
                </span>
              </li>
              <li class="flex space-x-3">
                <!-- Icon -->
                <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  <b>Ley Orgánica contra el Lavado de Activos y las regulaciones emitidas por la Unidad de Análisis
                    Financiero (UAF):</b>&nbsp;Estas disposiciones establecen la obligación de proteger los datos
                  personales en el contexto de la prevención del lavado de activos, asegurando un manejo diligente y
                  responsable de la información.
                  Adicionalmente, Ecuador ha adoptado normas internacionales de gestión, como ISO 27001.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-gray-200">
      <div class="container mx-auto my-8 lg:px-40 sm:px-10">
        <header class="w-full container mx-auto">
          <div class="flex flex-col items-center pt-12">
            <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
              ¿Cómo puedo ejercer mis derechos?
            </a>
            <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
          </div>
        </header>
      </div>

      <div
        class="items-center max-w-screen-xl px-4 py-4 mx-auto lg:grid lg:gap-16 xl:gap-24 lg:py-b-2 lg:px-6">
        <!-- <div class="col-span-2 mb-8">
          <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">¿Cómo
            puedo ejercer mis derechos?</h2>
          <img src="@/assets/img/about/manager.jpg" alt="gerente credil" class="mx-auto px-10 md:px-5 mt-24" />
        </div> -->
        <div class="col-span-3 md:grid md:grid-cols-3 md:gap-12 md:space-y-0">
          <div>
            <svg class="w-10 h-10 mb-2 azul md:w-12 md:h-12" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                clip-rule="evenodd"></path>
            </svg>
            <h3 class="mb-2 text-2xl font-bold dark:text-white">Métodos para Ejercer Derechos</h3>
            <p class="font-light text-gray-900 dark:text-gray-400">
              Para ejercer los derechos de acceso, eliminación, rectificación, actualización, oposición, anulación y
              limitación del tratamiento, puede contactar al delegado de protección de datos personales. Las solicitudes
              serán respondidas dentro del plazo establecido por la Ley Orgánica de Protección de Datos Personales.</p>
          </div>
          <div>
            <svg class="w-10 h-10 mb-2 azul md:w-12 md:h-12" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z">
              </path>
            </svg>
            <h3 class="mb-2 text-2xl font-bold dark:text-white">Mecanismos para Derechos de Portabilidad</h3>
            <p class="font-light text-gray-900 dark:text-gray-400">Tiene el derecho a recibir sus datos personales en un
              formato compatible y actualizado, o a solicitar la transferencia a otro responsable del tratamiento,
              siempre que sea técnicamente posible. Tras la transferencia, los datos serán eliminados por el responsable
              que realizó la transferencia, salvo que solicite su conservación.</p>
          </div>
          <div>
            <svg class="w-10 h-10 mb-2 azul md:w-12 md:h-12" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                clip-rule="evenodd"></path>
            </svg>
            <h3 class="mb-2 text-2xl font-bold dark:text-white">Revocación Del Consentimiento</h3>
            <p class="font-light text-gray-900 dark:text-gray-400">Puede revocar su consentimiento en cualquier momento
              sin necesidad de justificar. El tratamiento realizado antes de la revocación será lícito y no tendrá
              efectos retroactivos.</p>
          </div>
          <div>
            <svg class="w-10 h-10 mb-2 azul md:w-12 md:h-12" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z">
              </path>
            </svg>
            <h3 class="mb-2 text-2xl font-bold dark:text-white">Reclamo</h3>
            <p class="font-light text-gray-900 dark:text-gray-400">Para cualquier reclamo sobre la vulneración de
              derechos, puede enviar una solicitud al correo electrónico del delegado de protección de datos personales,
              especificando el derecho vulnerado y adjuntando evidencia relevante. La respuesta a la solicitud se
              realizará dentro del plazo establecido por la Ley Orgánica de Protección de Datos Personales.</p>
          </div>
          <div>
            <svg class="w-10 h-10 mb-2 azul md:w-12 md:h-12" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z">
              </path>
            </svg>
            <h3 class="mb-2 text-2xl font-bold dark:text-white">Valoraciones Automatizadas</h3>
            <p class="font-light text-gray-900 dark:text-gray-400">Las valoraciones para la toma de decisiones sobre los
              datos recopilados no se basan en procesos automatizados. Los principios, derechos descritos en este
              documento permiten a la Cooperativa de Ahorro y Crédito Credil Ltda. cumplir con los requisitos legales y
              obtener el consentimiento adecuado.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto lg:px-40 sm:px-10">
      <header class="w-full container mx-auto">
        <div class="flex flex-col items-center pt-12">
          <a class="font-bold text-gray-800 hover:text-gray-700 lg:text-3xl text-center" href="#">
            ¿Debe facilitar sus datos personales?
          </a>
          <hr class="w-24 h-1 mx-auto my-4 bg-blue-800 border-0 rounded md:my-10 dark:bg-gray-700">
        </div>
      </header>
    </div>
    <div class="grid grid-cols-2 mb-2 gap-4">
      <!-- SERVICE 1 -->
      <div class="col-span-3 md:col-span-1">
        <service title="Beneficios" img="iconoBASICOS.png"
          text=" Proporcionar datos personales es esencial para acceder a servicios personalizados; asesoramiento; productos innovadores y para evaluar y aprobar solicitudes. Garantizamos que la información se maneja de manera segura y conforme a las normativas de protección de datos para minimizar riesgos. " />
      </div>

      <!-- SERVICE 2 -->
      <div class="col-span-3 md:col-span-1">
        <service title="Efectos de Proporcionar Datos Erróneos o Inexactos" img="iconoESTATALES.png"
          text="La entrega de datos incorrectos puede generar riesgos legales; de cumplimiento; financieros y operativos tanto para la institución como para el titular de los datos." />
      </div>
    </div>
  </main>
</template>
<script>
import Service from "../../components/services/Service.vue";

export default {
  name: "DataProtection",
  components: { Service },
  data: function () {
    return {
      activeIndex: null,
      index: 0,
      isOpen: false
    }
  },
  methods: {
    toggleAccordion(index) {
      // Cambiar el índice activo para mostrar u ocultar el acordeón
      if (this.activeIndex === index) {
        this.activeIndex = null; // Si ya está abierto, cerramos el acordeón
        this.isOpen = false;
      } else {
        this.activeIndex = index; // Abrir el acordeón
        this.isOpen = true;
      }
    }
  }
};
</script>

<style scoped>
/* main */
#main {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 1;
}

#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}

.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}

/* ICONS */
.icono {
  width: 180px;
  height: 180px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  border-radius: 50%;
  background-color: white;
}

.icono:hover {
  position: relative;
  top: 0px;
  box-shadow: 0 0 7px #999;
  border-radius: 50%;
  transform: translateY(-0.4px);
}
</style>
